/***
 *
 * Controller class for user.
 * @file Devices.js
 * @description Devices component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 */

import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { breadcrumbActions } from "../../../redux/slices";
import {
  InputGroup,
  Table,
  Spinner,
  Row,
  Col,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
} from "reactstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import store from "../../../redux/store";
import Header from "../_builder/Header";
import REDUX_WORKER from "../../../redux/workers";
import "./Devices.scss";
import { Input } from "reactstrap";
import createRequest, { services } from "../../../services";
import {
  SearchIconAddon,
  ColumnHeader,
  FilterSetter,
  SupportModal,
} from "../../../components";
import { CatchedWebError } from "../../../configs";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BandFilter, ConnectionTypeFilter, FailureReasonFilter, InfraFilter, NetworkFilter } from "../../Filters/filters";
import FilterButton from "../../../components/FilterButton";
import { isoDate } from "../../Dashboard/Graphs/Utils";
import {
  MultiTroubleshootModal,
  Name,
  SimpleTroubleshootModal,
  displayPort,
  download,
} from "../../Devices/DeviceList";
import LightBadge from "../../../components/LightBadge";
import { CLIENT_EVENTS, CONNECT_FAILURES, CONNECT_FAILURE_REASONS, NONE, SCROLL_MORE_TEXT, SWITCH } from "../../../utility/constants";
import { MAC_ADDRESS } from "../Infrastructure";
import { serial_mac } from "../../../helpers/macHelper";
import DateRangeSelector from "../../Dashboard/Graphs/DateRangeSelector";
import RefreshButton from "../../Dashboard/Graphs/RefreshButton";
import ChartError from "../../Dashboard/Graphs/ChartError";
import DevicesChart from "../../Dashboard/Graphs/DevicesChart";
import {
  formatDevicesCardData,
  formatNetworkCardData,
} from "../../Dashboard/NewDashboard/BlazerUtil";
import GroupButton from "../../../components/GroupButton";
import SidePanel from "../../../components/SidePanel";
import ColSelector from "../../../components/ColSelector";
import { make_custom_toast } from "../../../helpers/toasts";
import lodash from "lodash-es";
import { UPTIME } from "../../Infrastructure/Details";
import Blazer from "../../../redux/slices/blazer.slice";
import useTimedCaller from "../../Dashboard/NewDashboard/useTimedCaller";
import blazer from "../../../services/blazer.service";
import { ReactComponent as Wired } from "../../../assets/images/icons/Wired.svg";
import { ReactComponent as Wireless } from "../../../assets/images/icons/Wireless.svg";
import ApexChartsTrendLine from "../../../components/ApexChartsTrendLine";
import { dateTimeFormatter, getTimeZone } from "../../../utility/Localization";
import { timeDiff } from "../../Infrastructure/SwitchOverview";
import { TICKET_CATEGORY } from "../../Tickets/TicketConstants";
import { getColumns, setColumns as setColumnsInStore } from "../../../utility/colSaver";
import { ReactComponent as CsvIcon } from '../../../assets/images/icons/xls_icon.svg';
import { downloadSpreadsheet } from "../../../utility/Utils";
import { EventDetails } from "../../Devices/Overview";
import { Tooltip } from "react-tooltip";
import InfraCell from "../../../components/InfraCell";

let timer;

const debounce = (callback) => {
  clearTimeout(timer);
  timer = setTimeout(callback, 800);
};

const TableHeader = (props) => {
  const { columns, setColumns, onColumnApply, sort, setSort, status, setIsColumnSelectorOpen } = props;

  return (
    <thead>
      <tr>
        {columns?.Name ? (
          <th style={{ width: "150px" }}>
            <ColumnHeader
              header="NAME"
              attribute="Name"
              sort={sort}
              setter={setSort}
            />
          </th>
        ) : null}
        {!!columns && columns["MAC Address"] ? (
          <th style={{ width: "180px" }}>
            <ColumnHeader header="MAC ADDRESS" 
            attribute="Mac"
            setter={setSort}
            sort={sort}/>
          </th>
        ) : null}
        {!!columns && !!columns["IP Address"] ? (
          <th style={{ width: "150px" }}>
            <ColumnHeader header="IP ADDRESS" />
          </th>
        ) : null}
        {columns?.Network ? (
          <th style={{ width: "170px" }}>
            <ColumnHeader header="NETWORK" 
            attribute="Network"
            setter={setSort}
            sort={sort}/>
          </th>
        ) : null}
        {!!columns["Failure Reason"] ? (
          <th style={{ width: "180px" }}>
            <ColumnHeader
              header="FAILURE REASON"
              attribute="Failure Reason"
              setter={setSort}
              sort={sort}
            />
          </th>
        ) : null}
        {columns?.Infrastructure ? (
          <th style={{ width: "170px" }}>
            <ColumnHeader
              header="INFRASTRUCTURE"
              attribute="Infrastructure"
              setter={setSort}
              sort={sort}
            />
          </th>
        ) : null}
        {!!columns.VLAN ? (
          <th className="pr-0" style={{ width: "85px" }}>
            <ColumnHeader header="VLAN" right={true} 
              attribute={
                [
                  'connected',
                  'disconnected',
                  'rejected'
                ].includes(status) ? (
                  "VLAN"
                ) : null}
              setter={setSort}
              sort={sort} 
            />
            <div
            // style={{ marginTop: "0.16rem" }}
            ></div>
          </th>
        ) : null}
        {!!columns.Band ? (
          <th style={{ width: "80px" }}>
            <ColumnHeader
              header="BAND"
              attribute="Band"
              sort={sort}
              setter={setSort}
            />
          </th>
        ) : null}
        {!!columns.Connected ? (
          <th className="pr-0" style={{ width: "150px" }}>
            <ColumnHeader 
            header="Connected" 
            attribute={status !== "banned" && status !== "rejected"?"Connected":null}
            sort={status !== "banned" && status !== "rejected"?sort:null}
            setter={status !== "banned" && status !== "rejected"?setSort:null}
            right={true}/>
          </th>
        ) : null}
        {!!columns["Tried At"] ? (
          <th className="pr-0" style={{ width: "150px" }}>
            <ColumnHeader 
            header="Tried At" 
            attribute={status === "rejected"?"Tried At":null}
            sort={status === 'rejected'?sort:null}
            setter={status === "rejected"?setSort:null}
            right={true}/>
          </th>
        ) : null}
        {!!columns.Channel ? (
          <th className="pr-0" style={{ width: "100px" }}>
            <ColumnHeader header="Channel" right={true}
            attribute="Channel"
            sort={sort}
            setter={setSort}/>
          </th>
        ) : null}
        {!!columns.RSSI ? (
          <th className='pr-0' style={{ width: "80px" }}>
            <ColumnHeader 
              header="RSSI"
              right={true}
              attribute={
                [
                  'connected',
                  'disconnected',
                  'rejected'
                ].includes(status) ? (
                  "RSSI"
                ) : null}
              setter={setSort}
              sort={sort}
            />
          </th>
        ) : null}
        {!!columns.Download ? (
          <th style={{ width: "110px" }}>
            <ColumnHeader header="Download" />
          </th>
        ) : null}
        {!!columns.Upload ? (
          <th style={{ width: "110px" }}>
            <ColumnHeader header="Upload" />
          </th>
        ) : null}
        {columns?.Status ? (
          <th style={{ width: "80px" }}>
            <ColumnHeader header="Status" />
          </th>
        ) : null}
        <th className="text-right" style={{ width: "50px" }}>
          <span
            className="material-symbols-outlined cursor-pointer"
            onClick={() => setIsColumnSelectorOpen((prevState) => !prevState)}
          >
            settings
          </span>
        </th>
      </tr>
    </thead>
  );
};

const filterInitial = {
  networks: [],
  bands: [],
  infra: [],
  failureReason: []
};
// Name, IP Address, Network, Infrastructure, VLAN, Type, Band, Channel, Connected, RSSI, Download, Upload
const initialColumns = {
  Name: true,
  "MAC Address": true,
  "IP Address": true,
  Network: true,
  "Failure Reason": true,
  Infrastructure: true,
  VLAN: true,
  Band: true,
  Connected: true,
  "Tried At": false,
  Channel: true,
  RSSI: false,
  Download: false,
  Upload: false,
  Status: false,
};

const ClientItem = (props) => {
  const {
    columns,
    type,
    status,
    statusProps,
    receivedData,
    item,
    stopEditing,
    setStopEditing,
    setSupportModalIsOpen,
    setSupportModalData,
    deviceList,
    setDeviceList,
    index
  } = props;
  const navigate = useNavigate();
  const activeOrgId = useSelector((state) => state.activeOrg.data.orgId);
  const activeVenue = useSelector((store) => store.activeVenue.data);
  const permissions = useSelector((store) => store?.rbac?.permissions);
  const [bgColor, setbgColor] = useState(
    type === "socketedItem" ? "light-green-bg" : "white-bg"
  );
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!!receivedData) {
      const trafficData = formatNetworkCardData({
        traffic: receivedData.traffic,
      }, true);
      if (type === "telemetryItem" || type === "socketedItem") {
        setData({
          lastReportedTime: receivedData.lastReportedTime,
          connectionType: receivedData.clientType,
          name: receivedData.clientName,
          vendor: receivedData.vendor,
          ipAddress:
            receivedData.ipv4Address && receivedData.ipv4Address.length > 0
              ? receivedData.ipv4Address[0]
              : receivedData.ipv6Address && receivedData.ipv6Address.length > 0
              ? receivedData.ipv6Address[0]
              : "-",
          ipAddressAt: receivedData.ipAddressAt,
          clientMac: receivedData.clientMac,
          networkId: receivedData.networkId,
          networkName:
            receivedData.ssid &&
            receivedData.ssid.toString().toLowerCase() != "unknown"
              ? receivedData.ssid
              : "-",
          failureReason: receivedData.failureReason,
          infraId: receivedData.infraId,
          infraName: receivedData.infraName,
          switches: receivedData.switches,
          venueId: receivedData.venueId,
          venueName: receivedData.venueName,
          connectedTime: receivedData.lastStatusAt,
          triedAt: receivedData.triedAt,
          channel: receivedData.channel,
          band: receivedData.band,
          rssi: receivedData.rssi,
          download: trafficData,
          upload: trafficData,
          status: receivedData.status,
          lastAccess: receivedData.lastAccess,
          vlan: receivedData.vlanId,
          // blockClientId: receivedData.blockClientId,
          infraCategory: receivedData.infraCategory,
          blockClientId: receivedData.blockClientId,
          macAddress: receivedData.macAddress,
          aaaServer: receivedData.aaaServer,
          aaaPort: receivedData.aaaPort,
        });
      }
      // else if (type === "socketedItem") {
      //   setData({
      //     connectionType: receivedData.clientType,
      //     name: receivedData.clientName ?? "",
      //     vendor: receivedData.vendor,
      //     ipAddress:
      //       receivedData.ipv4_address && receivedData.ipv4_address.length > 0
      //         ? receivedData.ipv4_address[0]
      //         : receivedData.ipv6_address &&
      //           receivedData.ipv6_address.length > 0
      //         ? receivedData.ipv6_address[0]
      //         : "-",
      //     clientMac: receivedData.mac,
      //     networkName: receivedData?.connectedTo,
      //     infraId: receivedData.infraId,
      //     infraName: receivedData.infraName,
      //     macAddress: receivedData.macAddress,
      //     venueId: receivedData.venue_id,
      //     venueName: receivedData.venue_display_name,
      //     connectedTime: receivedData.connected_time,
      //     channel: receivedData.channel,
      //     band: receivedData.band,
      //   });
      // }
      // else {
      //   setData({
      //     connectionType: receivedData.clientType,
      //     name: receivedData.clientName,
      //     vendor: receivedData.vendor,
      //     ipAddress:
      //       receivedData.ipv4_address && receivedData.ipv4_address.length > 0
      //         ? receivedData.ipv4_address[0]
      //         : receivedData.ipv6_address &&
      //           receivedData.ipv6_address.length > 0
      //         ? receivedData.ipv6_address[0]
      //         : "-",
      //     clientMac: receivedData.clientMac,
      //     networkName: receivedData?.networkName,
      //     failureReason: receivedData.failureReason,
      //     infraId: receivedData.infraId,
      //     infraName: receivedData.infraName,
      //     venueId: receivedData.venue_id,
      //     venueName: receivedData.venue_display_name,
      //     connectedTime: receivedData.connected_time,
      //     channel: receivedData.channel,
      //     band: receivedData.band,
      //     bannedDate: receivedData.bannedDate,
      //     infraCategory: receivedData.infraCategory
      //   });
      // }
    }
  }, [type, receivedData]);

  useEffect(() => {
    if (type === "socketedItem") {
      setTimeout(() => {
        setbgColor("white-bg");
      }, 1000);
    }
  }, [type]);

  return !!data && !data.hiden ? (
    <tr className={"hoverable " + bgColor} key={serial_mac(data.clientMac??'')??'' + data.macAddress??'' + status??''}>
      {columns?.Name ? (
        <td>
          <Name
            device={{
              client_name: data.name,
              mac: data.clientMac,
              vendor: data.vendor,
              blockClientId: receivedData.blockClientId,
              connectionType: data.connectionType
            }}
            receivedData={receivedData}
            venueInfo={{venueId: activeVenue?.venueId, venueName: activeVenue?.venueName}}
            stopEditing={stopEditing}
            setStopEditing={setStopEditing}
            data={deviceList}
            setData={setDeviceList}
            status={status}
            eventsType={status==='rejected'?'all':null}
              eventType={data.failureReason == CONNECT_FAILURES.KEY_MISMATCH ? 
                CLIENT_EVENTS.KEY_MISMATCH
              : data.failureReason == CONNECT_FAILURES.AAA_REJECTED ?
                CLIENT_EVENTS.AAA_REJECTED_AUTH
              : data.failureReason == CONNECT_FAILURES.NO_RADIUS_RESPONSE ?
                CLIENT_EVENTS.NO_RADIUS_RESPONSE
              : null}
          />
        </td>
      ) : null}
      {!!columns && columns["MAC Address"] ? (
        <td>{MAC_ADDRESS(serial_mac(data.clientMac ?? "-"), true)}</td>
      ) : null}
      {!!columns && columns["IP Address"] ? (
        <td>
          <span
            id={`client-ipaddressat${serial_mac(data.clientMac) + serial_mac(data.macAddress??'')}-${index}`}
            className={!!data.ipAddress && data.ipAddress !== "-" && !!data.ipAddressAt?"cursor-pointer":''}
          >
            {data.ipAddress ?? "-"}
          </span>
          {!!data.ipAddress && data.ipAddress !== "-" && !!data.ipAddressAt?
          <UncontrolledTooltip
            placement="right"
            target={`#client-ipaddressat${serial_mac(data.clientMac) + serial_mac(data.macAddress??'')}-${index}`}
          >
            <div>
              Last Updated: {dateTimeFormatter(
              data.ipAddressAt,
              "long",
              "medium"
              ) +
                " (" +
                getTimeZone(false) +
                ") "}
            </div>
          </UncontrolledTooltip>:null}
        </td>
      ) : null}
      {columns?.Network ? (
        <td
          className={`${
            data.infraCategory != SWITCH && !!data.networkId ? "table-link" : ""
          }`}
          onClick={() => {
            if (data.infraCategory != SWITCH && !!data.networkId) {
              //organization/23/networks/42
              navigate(
                `/organization/${activeOrgId}/networks/${data.networkId}`
              );
            }
          }}
        >
          {data.infraCategory != SWITCH
            ? displayPort(data.networkName) ?? "-"
            : data.switches?.length === 1
            ? displayPort(data.switches[0].port) ?? "-"
            : "-"}
        </td>
      ) : null}
      {!!columns && !!columns["Failure Reason"]? (
        <td>
        {CONNECT_FAILURE_REASONS[data.failureReason] != null ? (
          <div
          id={`failure-reason-${serial_mac(data.clientMac) + serial_mac(data.macAddress ?? '')}-${index}`}
          className="cursor-pointer"
        >
        {CONNECT_FAILURE_REASONS[data.failureReason]}<span className="material-symbols-outlined info-icon">info</span>
        <Tooltip
          anchorSelect={`#failure-reason-${serial_mac(data.clientMac) + serial_mac(data.macAddress ?? '')}-${index}`}
          variant="light"
          clickable
          place="bottom"
          border="solid 2px #EAEAEA"
          opacity={1}
          style={{boxShadow:"2px 2px 15px #EAEAEA", zIndex: 40}}
          delayShow={200}
        >
          <div style={{width:"270px", fontSize:"1rem"}}>
            <EventDetails
              event={{
                event: data.failureReason == CONNECT_FAILURES.KEY_MISMATCH ? (
                    CLIENT_EVENTS.KEY_MISMATCH
                  ) : data.failureReason == CONNECT_FAILURES.AAA_NO_RESPONSE ? (
                    CLIENT_EVENTS.NO_RADIUS_RESPONSE
                  ) : data.failureReason == CONNECT_FAILURES.AAA_REJECTED ? (
                    CLIENT_EVENTS.AAA_REJECTED_AUTH
                  ) : null,
                ssid: data.networkName,
                networkId: data.networkId,
                infraName: data.infraName,
                infraId: data.infraId,
                aaaServer: data.aaaServer,
                aaaPort: data.aaaPort,
                band: data.band,
                channel: data.channel
              }}
            />
          </div>
        </Tooltip>
        </div>
        ) : "-"}
      </td>
      ):null}
      {columns?.Infrastructure ? (
        <td>
          <InfraCell
            infraId={data.infraId}
            infraName={data.infraName}
            switches={data.switches}
            clientMac={data.clientMac}
            infraCategory={data.infraCategory}
            macAddress={data.macAddress}
          />
        </td>
      ) : null}
      {columns?.VLAN ? (
        <td className="text-right">
          <div style={{paddingRight: '1.1rem'}}>
          {data.infraCategory != SWITCH
            ? data.vlan ?? "-"
            : data.switches?.length === 1 && !!data.switches[0].vlan
            ? <div className="longValue w-100" title={data.switches[0].vlan.length>1?data.switches[0].vlan.join(","):null}>
                {data.switches[0].vlan.join(",")}
              </div>
            : "-"}
          </div>
        </td>
      ) : null}
      {columns?.Band ? (
        <td>
          <div className="d-flex justify-content-center">
            {!!data.band && data.band?.toString()?.toLowerCase() != "-" ? (
              <LightBadge color="primary" innerClassName="pill">
                {data.band}
              </LightBadge>
            ) : (
              "-"
            )}
          </div>
        </td>
      ) : null}
      {columns?.Connected ? (
        <td
          className="text-right"
          >
          <span 
            id={`client-uptime-socket${serial_mac(data.clientMac)}-${index}`}
            style={{paddingRight: status==='banned' || status==='rejected'?'':'1.1rem'}}
            className={(!!data.connectedTime)?"cursor-pointer":''}
            >
            {!!data.connectedTime?
            timeDiff(data.connectedTime, true) + (status === 'disconnected'?' ago':'')
          :"-"}
          </span>
          {(!!data.connectedTime) ? (
          <UncontrolledTooltip
            placement="left"
            target={`client-uptime-socket${serial_mac(data.clientMac)}-${index}`}
          >
            {dateTimeFormatter(
              data.connectedTime,
              "long",
              "medium"
            ) +
              " (" +
              getTimeZone(false) +
              ") "}
          </UncontrolledTooltip>
        ) : null}
        </td>
      ) : null}
      {!!columns && columns["Tried At"] ? (
        <td
          className="text-right"
          >
          <span 
            id={`client-triedat-socket${serial_mac(data.clientMac) + serial_mac(data.macAddress??'')}-${index}`}
            style={{paddingRight: status==='rejected'?'1.1rem':''}}
            className={!!data.triedAt?"cursor-pointer":""}
          >
            {!!data.triedAt?
            timeDiff(data.triedAt, true) + ' ago'
          :"-"}
          </span>
          {(!!data.triedAt) ? (
          <UncontrolledTooltip
            placement="left"
            target={`client-triedat-socket${serial_mac(data.clientMac) + serial_mac(data.macAddress??'')}-${index}`}
          >
            {dateTimeFormatter(
              data.triedAt,
              "long",
              "medium"
            ) +
              " (" +
              getTimeZone(false) +
              ") "}
          </UncontrolledTooltip>
        ) : null}
        </td>
      ) : null}
      {columns?.Channel ? (
        <td className="text-right">
          <span style={{paddingRight: '1.1rem'}}>
            {data.channel ?? "-"}
            </span>
            </td>
      ) : null}
      {columns?.RSSI ? (
        <td className="text-right"
          style={{
            paddingRight: [
              'connected',
              'disconnected',
              'rejected'
            ].includes(status) ? '1.95rem' : null
          }}
        >{data.rssi ?? "-"}</td>
      ) : null}
      {columns?.Download ? (
        <td>
          <ApexChartsTrendLine color={["#F5A742"]} seriesTitles={["downlink"]} series={data.download} />
        </td>
      ) : null}
      {columns?.Upload ? (
        <td>
          <ApexChartsTrendLine color={["#B347E6"]} seriesTitles={["uplink"]} series={data.upload} />
        </td>
      ) : null}
      {columns?.Status ? (
        <td>
          {!!data.status ? (
            data.status === "pending" ? (
              <span
                className="material-symbols-outlined text-warning"
                title="Capturing Detailed Traces"
              >
                cycle
              </span>
            ) : data.status === "completed" ? (
              <span
                className="material-symbols-outlined text-primary cursor-pointer"
                title="Download"
                onClick={() => {
                  if (data?.clientMac && data?.macAddress)
                    download(data?.macAddress, data?.clientMac);
                }}
              >
                download
              </span>
            ) : (
              "N/A"
            )
          ) : (
            "N/A"
          )}
        </td>
      ) : null}
      <td className="text-right">
        <UncontrolledButtonDropdown direction="down">
          <DropdownToggle
            color="white"
            className="p-0"
            disabled={!permissions?.manageDevice?.update}
          >
            <span
              className={`material-symbols-outlined ${
                !permissions?.manageDevice?.update
                  ? "text-secondary"
                  : "text-primary"
              }`}
            >
              more_vert
            </span>
          </DropdownToggle>
          <DropdownMenu>
            {/* {!!data.networkName && !!data.venueId ? (
              <DropdownItem
                className="w-100"
                onClick={() =>
                  setBanClient({
                    ...receivedData,
                    ssid: data.networkName,
                    venue_id: data.venueId,
                    clientName: data.name ?? "",
                    clientMac: data.clientMac,
                    last_reported_time: new Date(),
                  })
                }
              >
                Ban Client
              </DropdownItem>
            ) : null} */}
            {permissions?.manageTicket?.create &&
            !isNaN(Number(data.venueId)) ? (
              <DropdownItem
                className="w-100"
                onClick={() => {
                  setSupportModalIsOpen(true);
                  setSupportModalData({
                    ticketDetails: {
                      category: 3,
                      infraName:
                        data.infraCategory == SWITCH &&
                        data.switches?.length > 0 &&
                        !!data.switches[0]?.infra_id &&
                        !!data.switches[0]?.name
                          ? data.switches[0].name
                          : data.infraName,
                      venueName: data.venueName,
                      venueId: Number(data.venueId),
                      infraId:
                        data.infraCategory == SWITCH &&
                        data.switches?.length > 0 &&
                        !!data.switches[0]?.infra_id &&
                        !!data.switches[0]?.name
                          ? data.switches[0].infra_id
                          : data.infraId,
                      macAddress:
                        data.infraCategory == SWITCH &&
                        data.switches?.length > 0 &&
                        !!data.switches[0]?.infra_id &&
                        !!data.switches[0]?.name
                          ? data.switches[0].mac_address
                          : data.macAddress,
                      description: !!data.clientMac
                        ? `Client Mac Address: ${data.clientMac}`
                        : "",
                    },
                    context: {
                      ...(!!data.vendor ? { vendor: data.vendor } : {}),
                      ...(!!data.clientName ? { clientName: data.name } : {}),
                    },
                  });
                }}
              >
                Support Case
              </DropdownItem>
            ) : null}
            {status === "rejected" && data.status !== "pending" ? (
              <DropdownItem
                className="w-100"
                onClick={() => {
                  statusProps?.setSimpleTroubleshootData({
                    deviceMAC: data.clientMac,
                    duration: "",
                    keyword: "",
                    macAddress: data.macAddress,
                  });
                  statusProps?.setIsSimpleTroubleshootModalOpen(true);
                }}
              >
                <div>Capture Detailed</div>
                <div>Traces</div>
              </DropdownItem>
            ) : null}
            {statusProps?.isSearchMac ? (
              <DropdownItem
                className="w-100"
                onClick={() => {
                  statusProps?.setRefiningCapture(true);
                  statusProps?.setMultiModalData({
                    mac: item?.mac,
                    venue: null,
                    infras: [],
                  });
                  statusProps?.setIsMultiOpen(true);
                }}
              >
                Refine Capture
              </DropdownItem>
            ) : null}
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </td>
    </tr>
  ) : null;
};

const multiTroublShootInitialData = { mac: null, venue: null, infras: [] };

const Devices = (props) => {
  const SCREEN_ID = "venue-client";
  const { venuePermissions } = props;
  const initMount = useRef(true);
  const view = useSelector(store => store.identity.meta.view);

  const identity = useSelector((store) => store.identity);
  const activeVenue = useSelector((store) => store.activeVenue.data);
  const activeOrgId = useSelector((store) => store.activeOrg.data.orgId);
  const venuecrumb = useSelector((store) => store.breadcrumb.venuecrumb);
  const permissions = useSelector((store) => store?.rbac?.permissions);
  const devicesRef = useSelector((store) => store.venue_devices);
  const devicesCumRef = useSelector((state) => state.venue_cum_devices);
  const range = useSelector((store) => store.activeOrg.meta.dateRange);
  const [cumulative, setCumulative] = useState(false);
  const devicesSetRef = cumulative ? devicesCumRef : devicesRef;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [status, setStatus] = useState(null);
  const [columnsApplied, setColumnsApplied] = useState(false)
  const [clientChartData, setClientChartData] = useState(formatDevicesCardData(JSON.parse(devicesSetRef?.data ?? "{}")))
  const [downloading, setDownloading] = useState(false)

  const CalculateAllowedColumns = useCallback(
    (columns, columnsCheck=columnsApplied) => {
      if (!!columns) {
        const newColumns = { ...initialColumns, ...getColumns(SCREEN_ID, view), ...columns };

        if(status === "connected" || status === "disconnected") {
          delete newColumns["Tried At"];
          delete newColumns["Last Accessed"];
          delete newColumns["Failure Reason"]
        }
  
        if(status === "rejected") {
          delete newColumns.Connected;
          delete newColumns["Last Accessed"];
        }
        
        if (status === "rejected") {
          newColumns.Status = true;
        }
        else 
          delete newColumns.Status;
        // if (status === "rejected") {
        //   newColumns.Status = ('Status' in columns) ? columns.Status:true
        //   delete newColumns.Connected
        // }
        // else delete newColumns.Status
        if (
          !(venuePermissions?.network == null
            ? permissions?.manageNetwork?.view
            : venuePermissions?.network > NONE)
        )
          delete newColumns.Network;
        if (
          !(venuePermissions?.infra == null
            ? permissions?.manageInfra?.view
            : venuePermissions?.infra > NONE)
        )
          delete newColumns.Infrastructure;

        return newColumns;
      }
      return columns;
    },
    [permissions, venuePermissions, status, columnsApplied]
  );

  const [showFilter, setShowFilter] = useState(false);
  const [filterActive, setFilterActive] = useState(false);
  const [filterData, setFilterData] = useState(filterInitial);
  const [filterSelection, setFilterSelection] = useState(filterInitial);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({
    order: "ASC",
    orderBy: ["banned","rejected"].includes(status)?"Name":"Connected",
  });
  const [isTouched, setIsTouched] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [deviceList, setDeviceList] = useState([]);
  const [deviceListLoading, setDeviceListLoading] = useState(true);
  const [stopEditing, setStopEditing] = useState(false);
  const [banClient, setBanClient] = useState(null);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [connectionType, setConnectionType] = useState(null);
  const [filterCount, setFilterCount] = useState(null);
  const [filterConnectionType, setFilterConnectionType] = useState('all')
  const [columns, setColumns] = useState({...initialColumns,...getColumns(SCREEN_ID, view)??{}});
  const [colSpan, setColSpan] = useState(10);

  const [multiTroubleshootData, setMultiTroubleshootData] = useState(
    multiTroublShootInitialData
  );
  const [isMultiOpen, setIsMultiOpen] = useState(null);
  const [multiSubmitting, setMultiSubmitting] = useState(null);
  const [multiSuccess, setMultiSuccess] = useState(null);
  const [multiError, setMultiError] = useState(null);
  const [active, setActive] = useState("selectInfra");
  const [refiningCapture, setRefiningCapture] = useState(false);
  const [isSearchMac, setIsSearchMac] = useState(false);

  const [isSimpleOpen, setIsSimpleOpen] = useState(false);
  const [isColumnSelectorOpen, setIsColumnSelectorOpen] = useState(false)

  const [simpleTroubleshootData, setSimpleTroubleshootData] = useState(null);
  const [submittingSimpleModal, setSubmittingSimpleModal] = useState(false);
  const [supportModalData, setSupportModalData] = useState(null);
  const [supportModalIsOpen, setSupportModalIsOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    let newVal = Object.keys(columns ?? {})?.filter(
      (column) => columns[column] === true
    )?.length;
    if (!isNaN(newVal)) {
      newVal += 1;
      setColSpan(newVal);
    } else setColSpan(10);
  }, [columns]);

  useEffect(() => {
    if (!searchParams.get("status") || !searchParams.get("connectionType")) {
      let updatedSearchParams = new URLSearchParams(searchParams.toString());
      updatedSearchParams.set("status", "connected");
      updatedSearchParams.set("connectionType", "wireless");
      setSearchParams(updatedSearchParams.toString(), { replace: true });
    } else {
      setStatus(searchParams.get("status"));
      setConnectionType(searchParams.get("connectionType"));
      setFilterConnectionType(searchParams.get("connectionType"))
    }
  }, [searchParams]);

  useEffect(() => {
    setClientChartData(formatDevicesCardData(JSON.parse(devicesRef?.data ?? "{}")))
  }, [devicesRef.data])

  const getCount = useCallback(
    (filter = filterData, columnData = columns, statusValue=status, connectionValue=connectionType) => {
      const { run } = createRequest(
        services.devices.UE_FILTER_COUNT_VENUE_LEVEL,
        [
          activeVenue?.venueId,
          search,
          Object.keys(columnData ?? {}).filter(
            (column) => columnData[column] === true
          ),
          statusValue,
          // connectionTypeValue,
          'all',
          new Date().getTimezoneOffset(),
          isoDate(),
          // filter,
          {}
        ]
      );
      run().then((response) => setFilterCount(response.data));
    },
    [activeVenue?.venueId, columns, status, connectionType, filterData, search]
  );

  useEffect(() => {
    if (!isMultiOpen) {
      !!search
        ? setMultiTroubleshootData({ mac: search, venue: null, infras: [] })
        : setMultiTroubleshootData({ mac: null, venue: null, infras: [] });
      setRefiningCapture(false);
      setActive("selectInfra");
    }
  }, [isMultiOpen]);

  const onColumnApply = (newColumns) => {
    setColumnsApplied(true)
    fetchDevices(filterData, CalculateAllowedColumns(newColumns, true));
  };

  useEffect(() => {
    if(initMount.current) {
      initMount.current = false;
    }
    else {
      onColumnApply({...initialColumns,...getColumns(SCREEN_ID, view)??{}});
      setColumns({...initialColumns,...getColumns(SCREEN_ID, view)??{}});
    }
  },[view])

  const fetchDevices = useCallback(
    (filter = filterData, columnData = columns, statusValue=status, connectionTypeValue=connectionType) => {
      const { run } = createRequest(services.devices.GET_VENUE, [
        activeVenue?.venueId,
        0,
        20,
        encodeURIComponent(search),
        sort,
        filter,
        Object.keys(columnData ?? {}).filter((col) => columnData[col] === true),
        statusValue,
        connectionTypeValue,
        isoDate(),
        new Date().getTimezoneOffset(),
      ]);

      if (!!activeVenue?.venueId) {
        setDeviceListLoading(true)
        run()
          .then((response) => {
            if (response.data.length < 20) setHasMore(false);
            else setHasMore(true);
            setDeviceList(response.data);
          })
          .catch((error) => {
            let processedError = new CatchedWebError(error);
            make_custom_toast("error", "Venue Clients", processedError.message);
            setHasMore(false);
          })
          .finally(() => setDeviceListLoading(false))
        getCount(filter, columnData, statusValue, connectionTypeValue);
      }
    },
    [
      activeVenue?.venueId,
      search,
      sort,
      columns,
      filterData,
      getCount,
      connectionType,
      status
    ]
  );

  useEffect(() => {
    if (!!status && !!connectionType) {
      let shouldFetchDevices = true;
      const columnData = CalculateAllowedColumns(columns);
      if (!lodash.isEqual(columnData, columns)) {
        setColumns(columnData);
        // shouldFetchDevices = false
      }

      if (!!activeVenue.venueId && shouldFetchDevices) {
        setFilterSelection(filterInitial)
        setFilterData(filterInitial)
        if(status === 'disconnected' && filterConnectionType !== 'all')
          setFilterActive(true)
        else
        setFilterActive(false)
        fetchDevices(filterInitial, columnData);
      }
    }

    // return () => {
    //   isTouched && dispatch(deviceActions.reset())
    // };
  }, [activeVenue.venueId, search, sort, status, connectionType]);

  useEffect(() => {
    setMultiTroubleshootData((prevState) => {
      return { ...prevState, venue: activeVenue };
    });
  }, [activeVenue]);

  useEffect(() => {
    if (venuecrumb.venueId !== activeVenue.venueId)
      REDUX_WORKER.getVenueParent(activeVenue.venueId, activeOrgId);

    store.dispatch(
      breadcrumbActions.setData([
        ...venuecrumb.parentPath,
        {
          path: `/organization/${activeOrgId}/venues/${activeVenue?.venueId}`,
          text: activeVenue?.venueName,
          active: false,
        },
        {
          path: `/organization/${activeOrgId}/venues/${activeVenue?.venueId}/clients/`,
          text: "Clients",
          active: true,
        },
      ])
    );
  }, [activeVenue, venuecrumb]);

  useEffect(() => {
    if (status === "rejected") {
      let potentialMac = search;
      const regex =
        /^([0-9A-Fa-f]{2}[\s:-]){5}([0-9A-Fa-f]{2})$|^([a-fA-F0-9]{12})$|^([0-9A-Fa-f]{4}[\s:-]){2}([0-9A-Fa-f]{4})$/;
      let found = potentialMac.match(regex);
      if (!!found) {
        //consider case with and without colons
        setIsSearchMac(true);
        const index = search?.indexOf(":");
        index === -1
          ? setMultiTroubleshootData({
              mac: search.replace(/(..)/g, "$1:").slice(0, -1),
              venue: activeVenue,
              infras: [],
            })
          : setMultiTroubleshootData({
              mac: search,
              venue: activeVenue,
              infras: [],
            });
        return;
      } else {
        setIsSearchMac(false);
        setMultiTroubleshootData({
          ...multiTroublShootInitialData,
          venue: activeVenue,
        });
      }
    } else {
      setIsSearchMac(false);
      setMultiTroubleshootData({
        ...multiTroublShootInitialData,
        venue: activeVenue,
      });
    }
  }, [search, status]);

  useTimedCaller(
    {
      service: blazer.VENUE_DEVICES,
      params: [
        activeVenue.venueId,
        isoDate(range),
        isoDate(),
        cumulative,
        new Date().getTimezoneOffset(),
      ],
      data: {},
    },
    range,
    activeVenue.venueId,
    cumulative
      ? (state) => state.venue_cum_devices
      : (state) => state.venue_devices,
    cumulative
      ? Blazer.venue_cum_devices.actions
      : Blazer.venue_devices.actions,
    forceRefresh,
    setForceRefresh
  );

  const handleDownload = () => {
    const allowedColumns = [
      "Name", "MAC Address", "IP Address",
      "Network", "Infrastructure", "VLAN",
      "Band", "Channel", "Connected", "RSSI"]
    const filteredColumns = Object.keys(columns ?? {}).filter(
      (column) => (columns[column] === true && allowedColumns.includes(column))
    )
    const apiURL = services.devices.DOWNLOAD_VENUE.urlBuilder(...[
      activeVenue?.venueId,
      encodeURIComponent(search),
      sort,
      filterData,
      filteredColumns,
      status,
      connectionType,
      isoDate(),
      new Date().getTimezoneOffset(),
    ]);
    const fileName = `Venue Clients List${activeVenue?.venueId != null ? ('-(' + activeVenue?.venueId + ')') : ''}.xlsx`
    setDownloading(true)
    downloadSpreadsheet(apiURL, fileName, identity.meta.token)
    .catch(err => {
      make_custom_toast('error', 'Export Clients List', (new CatchedWebError(err)).message)
    })
    .finally(() => {
      setDownloading(false)
    })
  }

  return (
    <div
      className="Devices"
      data-testid="Devices"
      onClickCapture={(e) => {
        if (
          !e.target.matches("#nickname-input") &&
          !e.target.matches("#nickname-input-tick")
        )
          setStopEditing(true);
      }}
    >
      <SupportModal
        isOpen={supportModalIsOpen && !!supportModalData}
        setIsOpen={setSupportModalIsOpen}
        // onSuccess={() => {}}
        ticketDetails={{
          category: TICKET_CATEGORY.DEVICES,
          ...supportModalData?.ticketDetails,
        }}
        context={supportModalData?.context}
      />
      <MultiTroubleshootModal
        isOpen={isMultiOpen}
        setIsOpen={setIsMultiOpen}
        modalData={multiTroubleshootData}
        setModalData={setMultiTroubleshootData}
        submitting={multiSubmitting}
        setSubmitting={setMultiSubmitting}
        success={multiSuccess}
        setSuccess={setMultiSuccess}
        error={multiError}
        setError={setMultiError}
        active={active}
        setActive={setActive}
        search={search}
        tableList={deviceList}
        setTableList={setDeviceList}
        refiningCapture={refiningCapture}
        fetchList={() => {}}
        section={"venue"}
      />
      {showFilter && (
        <FilterSetter
          setShowFilter={setShowFilter}
          showFilter={showFilter}
          disabled={!Object.keys(filterData).length}
          elements={() => {
            return [
              ...status === 'disconnected'?
              [<ConnectionTypeFilter
                  connectionType={filterConnectionType}
                  setConnectionType={setFilterConnectionType}
              />]:[],
              ...status === 'rejected'?
              [<FailureReasonFilter
                  filter={filterData}
                  setFilter={setFilterData}
              />]:[],
              <NetworkFilter
                filter={filterData}
                setFilter={setFilterData}
                venueId={activeVenue?.venueId}
              />,
              <InfraFilter
                filter={filterData}
                setFilter={setFilterData}
                venueId={activeVenue?.venueId}
              />,
              <BandFilter filter={filterData} setFilter={setFilterData} />,
            ];
          }}
          handleApplyClick={() => {
            if(status === 'disconnected' && connectionType != filterConnectionType) {
              let updatedSearchParams = new URLSearchParams(searchParams.toString());
              updatedSearchParams.set('status', 'disconnected');
              updatedSearchParams.set('connectionType', filterConnectionType);
              setSearchParams(updatedSearchParams.toString())
            }
            else {
              fetchDevices(filterData);
            }
            setShowFilter(false);
            if (filterConnectionType === 'all' && lodash.isEqual(filterData, filterInitial)) {
              setFilterActive(false);
            }
            else setFilterActive(true);
          }}
          handleClearAll={() => {
            setFilterData(filterInitial);
            if(status === 'disconnected' && connectionType != 'all') {
              // setFilterConnectionType('all')
              let updatedSearchParams = new URLSearchParams(searchParams.toString());
              updatedSearchParams.set('status', 'disconnected');
              updatedSearchParams.set('connectionType', 'all');
              setSearchParams(updatedSearchParams.toString())
            }
            else {
              fetchDevices(filterInitial)
            }
            setShowFilter(false);
            setFilterActive(false);
          }}
        />
      )}
      <SidePanel
        isOpen={isColumnSelectorOpen}
        setIsOpen={setIsColumnSelectorOpen}
      >
        <ColSelector
          cols={columns}
          setCols={setColumns}
          setIsOpen={setIsColumnSelectorOpen}
          onApply={(newColumns) => {
            onColumnApply(newColumns);
            setColumnsInStore(SCREEN_ID, newColumns, view);
          }}
        />
      </SidePanel>
      <SimpleTroubleshootModal
        isOpen={isSimpleOpen}
        setIsOpen={setIsSimpleOpen}
        modalData={simpleTroubleshootData}
        setModalData={setSimpleTroubleshootData}
        submitting={submittingSimpleModal}
        setSubmitting={setSubmittingSimpleModal}
      />
      <Header heading={activeVenue.venueName} />
      {/* <div className="d-flex justify-content-between align-items-end mb-50">
        <span className="row-heading">Historical Trends</span>
        <div className="d-flex">
          <DateRangeSelector />
          <RefreshButton
            clickState={forceRefresh}
            clickHandler={setForceRefresh}
          />
        </div>
      </div> */}
      <Row className="mb-1">
        <Col sm={12} md={6} lg={3} className="pr-0">
          {devicesSetRef?.isError ? (
            <ChartError
              title="Connected Wireless Clients"
              value={devicesSetRef?.isError}
            />
          ) : (
            <DevicesChart
              categories={
                clientChartData?.labels
              }
              colors={["#5279CE"]}
              series={
                clientChartData?.series
              }
              ticks={3}
              height={"125px"}
              noStyle={true}
              isLoading={
                devicesSetRef?.isLoading ||
                (!devicesSetRef?.isError && !devicesSetRef?.data)
              }
            />
          )}
        </Col>
        <Col className="offset-md-6" sm={12} md={6} lg={3}>
          <div className="d-flex justify-content-end">
            <DateRangeSelector />
            <RefreshButton
              clickState={forceRefresh}
              clickHandler={setForceRefresh}
            />
          </div>
        </Col>
      </Row>
      <div className="bg-white rounded p-1">
        <div className="d-flex justify-content-between searchRow"
          data-no-info={status==='rejected' || status==='disconnected'?"false":"true"}
        >
          <div className="d-flex">
            <InputGroup className="input-group-merge" style={{ width: "22rem" }}>
              <Input
                type="text"
                placeholder="Search"
                style={{ height: "100%" }}
                onChange={(e) => {
                  if (e.target.value.length > 1 || e.target.value.length === 0) {
                    // dispatch(deviceActions.setLoading(true));
                    debounce(() => {
                      setSearch(e.target.value);
                    });
                    setIsTouched(true);
                  }
                }}
              />
              <SearchIconAddon />
            </InputGroup>
            <GroupButton className="ml-1 mr-50">
              <div
                  className={
                    "grp-btn-custom " +
                    (connectionType === "wireless" && status === "connected" && !filterActive
                      ? // && !filterActive
                        "active"
                      : "")
                  }
                  onClick={() => {
                    if(deviceListLoading)
                      return
                    if(status === "connected" && connectionType === "wireless" && filterActive) {
                      setFilterActive(false)
                      setFilterData(filterInitial)
                      fetchDevices(filterInitial,columns,'connected','wireless')
                    }
                    setColumnsApplied(false)
                    let updatedSearchParams = new URLSearchParams(searchParams.toString());
                    updatedSearchParams.set('status', 'connected');
                    updatedSearchParams.set('connectionType', 'wireless');
                    setSearchParams(updatedSearchParams.toString())
                    // setFilterConnectionType('all')
                  }}
                >
                  <Wireless className="quickfilter-icon" /> Connected&nbsp;(
                  {filterCount?.wireless ?? 0})
                </div>
              <div
                  className={
                    "grp-btn-custom " +
                    (connectionType === "wired" && status === "connected" && !filterActive
                      ? // && !filterActive
                        "active"
                      : "")
                  }
                  onClick={() => {
                    if(deviceListLoading)
                      return
                    if(status === "connected" && connectionType === "wired" && filterActive) {
                      setFilterActive(false)
                      setFilterData(filterInitial)
                      fetchDevices(filterInitial,columns,'connected','wired')
                    }
                    setColumnsApplied(false)
                    let updatedSearchParams = new URLSearchParams(searchParams.toString());
                    updatedSearchParams.set('status', 'connected');
                    updatedSearchParams.set('connectionType', 'wired');
                    setSearchParams(updatedSearchParams.toString())
                  }}
                >
                  <Wired className="quickfilter-icon" /> Connected&nbsp;(
                  {filterCount?.wired ?? 0})
                </div>
                <div
                  className={
                    "grp-btn-custom " +
                    (status === "disconnected" && !filterActive
                      ? // && !filterActive
                        "active"
                      : "")
                  }
                  onClick={() => {
                    if(deviceListLoading)
                      return
                    if(status === "disconnected" && connectionType === 'all' && filterActive) {
                      setFilterActive(false)
                      setFilterData(filterInitial)
                      fetchDevices(filterInitial,columns,'disconnected','all')
                    }
                    setColumnsApplied(false)
                    let updatedSearchParams = new URLSearchParams(searchParams.toString());
                    updatedSearchParams.set('status', 'disconnected');
                    updatedSearchParams.set('connectionType', 'all');
                    setSearchParams(updatedSearchParams.toString())
                  }}
                >
                  Historical ({filterCount?.historical ?? 0})
                </div>
                <div
                  className={
                    "grp-btn-custom " +
                    (status === "rejected" && !filterActive
                      ? // && !filterActive
                        "active"
                      : "")
                  }
                  onClick={() => {
                    if(deviceListLoading)
                      return
                    if(status === "rejected" && filterActive) {
                      setFilterActive(false)
                      setFilterData(filterInitial)
                      fetchDevices(filterInitial,columns,'rejected','all')
                    }
                    setColumnsApplied(false)
                    let updatedSearchParams = new URLSearchParams(searchParams.toString());
                    updatedSearchParams.set('status', 'rejected');
                    updatedSearchParams.set('connectionType', 'all');
                    setSearchParams(updatedSearchParams.toString())
                  }}
                >
                  Failed ({filterCount?.failed ?? 0})
                </div>
              </GroupButton>
            <FilterButton
              size={24}
              active={filterActive}
              onClick={() => setShowFilter(true)}
              style={{ padding: "6px" }}
            />
          </div>
          <div className="d-flex align-items-center">
            {['disconnected', 'connected'].includes(status) ?
              <CsvIcon height={24} width={24}
                className={`${downloading ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                onClick={() => {
                  if (!downloading)
                    handleDownload()
                }}
                title={`Export List\n(maximum limit: 5000)`}
              /> : null}
          </div>
        </div>
        {status==='disconnected'?
        <div className="mb-50">Clients disconnected in past 24 hours.</div>
        :status==='rejected'?
        <div className="mb-50">Failed clients list for past 24 hours.</div>
        :null
        }
        <InfiniteScroll
          dataLength={deviceList.length}
          next={() => {
            const { run } = createRequest(services.devices.GET_VENUE, [
              activeVenue?.venueId,
              deviceList.length,
              20,
              encodeURIComponent(search),
              sort,
              filterData,
              Object.keys(columns ?? {}).filter((col) => columns[col] === true),
              status,
              connectionType,
              isoDate(),
              new Date().getTimezoneOffset(),
            ]);

            if (activeVenue && activeVenue.venueId)
              run()
                .then((response) => {
                  if (response.data.length < 20) setHasMore(false);
                  setDeviceList((curState) => [...curState, ...response.data]);
                })
                .catch((error) => {
                  let processedError = new CatchedWebError(error);
                  make_custom_toast(
                    "error",
                    "Venue Clients",
                    processedError.message
                  );
                  setHasMore(false);
                });
          }}
          hasMore={hasMore}
          loader={<div>{SCROLL_MORE_TEXT}</div>}
          endMessage={
            <div className="mt-50">Showing {deviceList.length} result(s)</div>
          }
          scrollableTarget="venue-device-list"
          scrollThreshold={0.7}
        >
          <div id="venue-device-list">
            <Table className="table-view fixed-header">
              <TableHeader
                columns={columns}
                setColumns={setColumns}
                onColumnApply={onColumnApply}
                sort={sort}
                setSort={setSort}
                status={status}
                setIsColumnSelectorOpen={setIsColumnSelectorOpen}
              />
              {deviceListLoading ? (
                <tbody>
                  <tr>
                    <td className="text-center p-5" colSpan={colSpan ?? 10}>
                      <Spinner color="primary" />
                    </td>
                  </tr>
                </tbody>
              ) : deviceList.length > 0 ? (
                <tbody>
                  {deviceList.map((deviceItem, i) => {
                    return (
                      <ClientItem
                        type="telemetryItem"
                        receivedData={deviceItem}
                        key={`telemetryItem${i}`}
                        index={i}
                        // setBanClient={setBanClient}
                        stopEditing={stopEditing}
                        setStopEditing={setStopEditing}
                        columns={columns}
                        setSupportModalIsOpen={setSupportModalIsOpen}
                        setSupportModalData={setSupportModalData}
                        status={status}
                        statusProps={{
                          setSimpleTroubleshootData: setSimpleTroubleshootData,
                          setIsSimpleTroubleshootModalOpen: setIsSimpleOpen,
                          isSearchMac: isSearchMac,
                          setRefiningCapture: setRefiningCapture,
                          // setMultiModalData: setMultiTroubleshootData,
                          // setIsMultiOpen: setIsMultiOpen
                        }}
                        deviceList={deviceList}
                        setDeviceList={setDeviceList}
                      />
                    );
                  })}
                </tbody>
              ) : (
                <tbody>
                  <tr style={{ minHeight: "calc(50vh - 150px)" }}>
                    <td
                      className="text-center pt-5"
                      style={{ height: "26vh", backgroundColor: "white" }}
                      colSpan={colSpan ?? 10}
                    >
                      {status === "rejected" && isSearchMac ? (
                        <h4>
                          No failed connection attempt found, to trigger a
                          capture for this MAC address&nbsp;
                          <span
                            className="multi-click cursor-pointer"
                            onClick={() => {
                              setIsMultiOpen(true);
                            }}
                          >
                            click here
                          </span>
                        </h4>
                      ) : (
                        <h4>No Client Found</h4>
                      )}
                    </td>
                  </tr>
                </tbody>
              )}
            </Table>
          </div>
        </InfiniteScroll>
      </div>

      {/* <Modal centered isOpen={!!banClient} toggle={() => setBanClient(null)}>
        <ModalHeader
          className="bg-white"
          toggle={() => setBanClient(null)}
        ></ModalHeader>
        <ModalBody>
          <div className="text-center">
            <div className="material-symbols-outlined display-2 text-warning">
              error
            </div>
            <div>
              Are you sure you want to Ban <br />"
              <span className="font-weight-bolder">{banClient}</span>"?
            </div>
            <div className="mt-2 mb-1">
              <Button.Ripple
                className="mr-1"
                color="primary"
                onClick={() => setBanClient(null)}
              >
                Cancel
              </Button.Ripple>
              <Button.Ripple
                color="danger"
                outline
                onClick={() => setBanClient(null)}
              >
                Yes
              </Button.Ripple>
            </div>
          </div>
        </ModalBody>
      </Modal> */}
    </div>
  );
};

export default Devices;
