/**
 * Text-Input
 * @file TextInput.js
 * @description Formik compatible text-input styled with reactstrap components.
 * @author Utkarsh Gupta
 * @since 29 Jun 2022
 */

import { useField } from "formik";
import React from "react";
import { FormFeedback, Input, Label } from "reactstrap";
import PropTypes from 'prop-types';
import "./TextInput.scss";

const TextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const elementProps = { ...props, isrequired: "sampled" };
  delete elementProps.noLabel
  delete elementProps.labelfontauth
  delete elementProps.overallClasses
  return (
    <div className={"TextInput " + (props.labelPosition == 'left' ? 'd-flex align-items-center' : '') + (props.noBottomMargin ? "" : " mb-2 ") + (!!props.overallClasses ? props.overallClasses : '')
    } data-testid="TextInput" >
      {!props.noLabel &&
        <Label
          className={"labelfont" +
            (props?.labelfontauth === true ? "auth" : '')}
          for={props.id || props.name}>{label}{props.isrequired ?
            <span className="text-danger">*</span> : <></>}</Label>}

      <Input invalid={!!((props.ignoreTouch || meta.touched) && meta.error)} autoComplete="off" {...field} {...elementProps} />
      {
        ((props.ignoreTouch || meta.touched) && meta.error) ? (
          <FormFeedback>{meta.error}</FormFeedback>
        ) : null
      }
    </div >
  );
};

TextInput.propTypes = {
  label: PropTypes.string,
  props: PropTypes.any,
  isrequired: PropTypes.bool,
  labelfontauth: PropTypes.bool
};

TextInput.defaultProps = {};

export default TextInput;
