import PropTypes, { element } from "prop-types";
import { CANVAS_FEATURES, CANVAS_EVENTS, FAKE_WALL_DATA, LAYOUT_TYPES } from "../constants";
import "./Canvas.scss";
import { useEffect, useRef, useState } from "react";
import { Circle, Image, Layer, Line, Stage } from "react-konva";
import useDynamicScaling from "./useDynamicScaling";
import Axes from "./Layers/Axes";
import useZoomProvider from "./useZoomProvider";
import { $g, $r, Point } from "../utils";
import useLayoutsManager from "./useLayoutsManager";
import Layouts from "./Layers/Layouts";
import useDisplayScalingProvider from "./useDisplayScalingProvider";
import ZoomWidget from "../Widgets/ZoomWidget";
import LayoutWidget from "../Widgets/LayoutWidget";
import AccessPoints from "./Layers/AccessPoints";
import AccessPointWidget from "../Widgets/AccessPointWidget";
import useImage from "use-image";
import { useSelector } from "react-redux";
import createRequest from "../../../../services";
import { wre } from "../../../../services/wre.service";
import DrawLines from "../components/DrawnLines";
import DrawLineWidget from "../Widgets/DrawLineWidget";
import useLineDrawingWidget from "./useLineDrawingWidget";
import DropCables from "./Layers/DropCables";
import CableDropWidget from "../Widgets/CableDropWidget";
import Switches from "./Layers/Switches";
import RealAccessPoints from "./Layers/RealAccessPoints";
import RealSwitches from "./Layers/RealSwitches";
import SwitchWidget from "../Widgets/SwitchWidget";

// const DebugWidget = ({ editableLayout, selectedLayout }) => (
//   <div className="btn btn-primary btn-sm p-25 ml-1 d-flex rounded-pill align-items-center">
//     <div>
//       Rotation: {selectedLayout.layoutUuid && editableLayout.layouts[selectedLayout.layoutUuid].transform.rotation.toFixed(3)}&deg; | 
//       Position: {selectedLayout.layoutUuid && `(${editableLayout.layouts[selectedLayout.layoutUuid].transform.position.x.toFixed(3)}, ${editableLayout.layouts[selectedLayout.layoutUuid].transform.position.y.toFixed(3)})`}
//     </div>
//   </div>
// );

/**
 * Rendering Canvas Base
 * @param {{
 *   layoutManager: object
 *   features: Array<number>,
 *   events: Array<number>,
 *   layouts: Array<{
 *     "venueId": number,
 *     "name": string,
 *     "layoutType": 1 | 2 | 3,
 *     "layoutFile": string,
 *     "infraPositions": Array<{
 *       "infra_type_id": number,
 *       "x": number,
 *       "y": number
 *     }>?,
 *     "layoutJson": {
 *       "dimensions": {
 *         "length": number,
 *         "width": number,
 *         "height": number
 *         "area": number
 *       }?,
 *       "walls": Array<{
 *         "id": string,
 *         "loc": Array<number>,
 *         "material": string
 *       }>,
 *       "isActive": boolean,
 *       "id": number,
 *       "createdAt": string,
 *       "updatedAt": string
 *     }  
 *   }>,
 *   onSave: (_: {
 *     layoutId: number?,
 *     data: {
 *        venueId: number,
 *        name: string,
 *        infraPositions: Array<{
 *          infra_type_id: number,
 *          x: number,
 *          y: number
 *        }>
 *        layoutJson: {
 *         "dimensions": {
 *           "length": number,
 *           "width": number,
 *           "height": number
 *           "area": number
 *         }?,
 *         "walls": Array<{
 *           "id": string,
 *           "loc": Array<number>,
 *           "material": string
 *         }>,
 *       } 
 *     }
 *   }) => Promise,
 *   draggedLayoutItem: any
 *   draggedApItem: any
 * }} props 
 */
const Canvas = (props) => {
  const { selectedWall, setSelectedWall, selectedLayout, setSelectedLayout, editableLayout, setEditableLayout, insertLayout, removeLayout, rotateLayout, translateLayout, insertCableDrop, removeCableDrop, selectedCableDrop, setSelectedCableDrop, insertAp, removeAp, selectedAp, setSelectedAp, translateAp, translateCableDrop, updateCableName, currentZoomValue, setCurrentZoomValue, currentOffset, setCurrentOffset, insertSwitch, removeSwitch, selectedSwitch, setSelectedSwitch, translateSwitch, lineStartPoint, setLineStartPoint, lineEndPoint, setLineEndPoint, infraCount, updateInfraPlacement } = props.layoutManager;
  const { zoom, setZoom, zoomPosition, setZoomPosition, handleWheel } = useZoomProvider(currentOffset, currentZoomValue, setCurrentZoomValue, props?.layouts);
  const { dimensions, offset, setOffset, containerReference, stageReference, centerLayoutViewAt } = useDynamicScaling(setCurrentOffset, props?.layouts, currentZoomValue);
  const { scaleFactor } = useDisplayScalingProvider(editableLayout, dimensions, props.blank);
  // const [imageLink, setImageLink] s= useState()
  // const [myImage] = useImage(imageLink)


  const currentInsertedLayout = useSelector(store => store.wre.currentInsertedLayout)
  const orgId = useSelector(store => store.activeOrg.data.orgId)
  const [ratio, setRatio] = useState(1)
  let stageOffsetX = offset.x
  let stageOffsetY = offset.y
  const ImageWidth = FAKE_WALL_DATA.components[0].walls[0].loc[2] * scaleFactor
  const [wheelEvent, setWheelEvent] = useState(null)




  // logic for drawing lines, will be moved to editable layout or layoutmanager
  const [drawLinesList, setDrawLines] = useState([])
  const drawingLine = useRef(false)
  const { tool, setTool, selectedLineIndex, setSelectedLineIndex } = useLineDrawingWidget()


  useEffect(() => {
  }, [drawLinesList])
  // if (props.features.includes(CANVAS_FEATURES.SHOW_LAYOUT_IMAGE)) {
  //   stageOffsetX = offset.x + (scaleFactor / 30)
  //   stageOffsetY = offset.y + (myImage?.height / 3)
  // }

  useEffect(() => {
  }, [props])

  useEffect(() => {
    setCurrentOffset({
      x: zoomPosition.x,
      y: zoomPosition.y
    })
  }, [zoomPosition])

  // useEffect(() => {
  //   // console.log('')
  //   if (myImage) {
  //     const ratio = myImage?.height / myImage?.width
  //     setRatio(ratio)
  //   }
  // }, [myImage])

  return (
    <div className="Canvas">
      <div className="canvas-widgets d-flex">
        {props.features.includes(CANVAS_FEATURES.ZOOM_WIDGETS) && <ZoomWidget wheelEvent={wheelEvent} zoom={zoom} stageRef={stageReference} setZoom={setZoom} setZoomPosition={setZoomPosition} currentZoomValue={currentZoomValue} setCurrentZoomValue={setCurrentZoomValue} />}
        {props.features.includes(CANVAS_FEATURES.AP_VIEW_AND_WIDGETS) && <AccessPointWidget mode={props.mode} selectedAp={selectedAp} selectedSwitch={selectedSwitch} removeAp={removeAp} removeSwitch={removeSwitch} />}
        {props.features.includes(CANVAS_FEATURES.SWITCH) && <SwitchWidget selectedSwitch={selectedSwitch} removeSwitch={removeSwitch} />}
        {props.features.includes(CANVAS_FEATURES.MOVE_CABLE_DROP) && <CableDropWidget selectedCableDrop={selectedCableDrop} removeCableDrop={removeCableDrop} />}
        {props.features.includes(CANVAS_FEATURES.LAYOUT_MANIPULATION_WIDGETS) && <LayoutWidget selectedLayout={selectedLayout} removeLayout={removeLayout} />}
        {props.features.includes(CANVAS_FEATURES.DRAW_LINES) && <DrawLineWidget tool={tool} setTool={setTool} selectedLineIndex={selectedLineIndex} removeLine={() => {
          const tempList = [...drawLinesList]
          tempList.splice(selectedLineIndex, 1)
          setDrawLines(tempList)
          setSelectedLineIndex(null)
        }} />}

        {/* {props.features.includes(CANVAS_FEATURES.LAYOUT_MANIPULATION_WIDGETS) && selectedLayout.layoutUuid && <DebugWidget editableLayout={editableLayout} selectedLayout={selectedLayout} />} */}
      </div>
      <div
        className="canvas-playground "
        ref={containerReference}
        onDrop={props.features.includes(CANVAS_FEATURES.AP_VIEW_AND_WIDGETS) ? (e) => {
          e.preventDefault();
          stageReference.current.setPointersPositions(e);
          const pos = stageReference.current.getRelativePointerPosition();
          insertAp(props.draggedApItem.current, new Point(
            pos.x / scaleFactor,
            pos.y / scaleFactor
          ))
        } : props.features.includes(CANVAS_FEATURES.SWITCH) ? (e) => {
          e.preventDefault();
          stageReference.current.setPointersPositions(e);
          const pos = stageReference.current.getRelativePointerPosition();
          insertSwitch(props.draggedSwitchItem.current, new Point(
            pos.x / scaleFactor,
            pos.y / scaleFactor
          ))
        } : props.features.includes(CANVAS_FEATURES.MOVE_CABLE_DROP) ? (e) => {
          e.preventDefault();
          stageReference.current.setPointersPositions(e);
          const pos = stageReference.current.getRelativePointerPosition();
          insertCableDrop(props.draggedCableDrop.current, new Point(
            pos.x / scaleFactor,
            pos.y / scaleFactor
          ))
        } : props.features.includes(CANVAS_FEATURES.LAYOUT_MANIPULATION_WIDGETS) ? (e) => {
          e.preventDefault();
          async function drag() {
            stageReference.current.setPointersPositions(e);
            const pos = stageReference.current.getRelativePointerPosition();
            let newPoints = new Point(0, 0)

            if (pos && Object.keys(editableLayout.layouts).length > 0) { // check if already a layout is present on Editor
              newPoints = new Point(
                pos.x / scaleFactor,
                pos.y / scaleFactor
              )
            }

            const layout = props.draggedLayoutItem.current


            // ImageFitSize
            if (layout?.layoutType == LAYOUT_TYPES.IMAGE) {
              setCurrentOffset({ x: 0, y: 0 })
              setOffset({ x: 0, y: 0 })
              setCurrentZoomValue(0.8)
              setZoom(0.8)
              setOffset({ x: -(dimensions.width / 3), y: -(dimensions.height / 3) });
              setCurrentOffset({ x: -(dimensions.width / 3), y: -(dimensions.height / 3) });
            }

            const { run, controller } = createRequest(wre.GET_IMAGE, [orgId, layout?.id]);
            const response = await run()
            let insertedLayout = props.draggedLayoutItem.current
            const updatedData = { ...insertedLayout, imageLayoutLink: response.data }
            controller.abort()
            if (layout?.layoutType == LAYOUT_TYPES.IMAGE) {
              insertLayout(updatedData)
            }
            else {
              insertLayout(updatedData, newPoints)
            }
          }
          drag()
        }
          : () => { }}
        onDragOver={e => { e.preventDefault(); }}
      >
        <Stage
          offsetX={stageOffsetX}
          offsetY={stageOffsetY}
          scaleX={zoom}
          scaleY={zoom}
          x={zoomPosition.x}
          y={zoomPosition.y}
          ref={stageReference}
          width={dimensions.width}
          height={dimensions.height}

          onDragEnd={(e) => {
            if (e.target._lastPos && selectedLayout.layoutUuid == null && selectedAp == null) {
              setCurrentOffset(e.target._lastPos)
            }
          }}
          onWheel={props.features.some((feature) => [CANVAS_FEATURES.DRAW_LINES, CANVAS_FEATURES.ZOOM_WIDGETS].includes(feature)) ?
            (e) => {
              setWheelEvent(e)
              handleWheel(e)
            } : () => { }}
          onMouseDown={props.features.includes(CANVAS_FEATURES.DRAW_LINES) ?
            (e) => {
              if (tool == 'draw') {
                drawingLine.current = true
                const pos = stageReference.current.getRelativePointerPosition();
                setDrawLines([...drawLinesList, { points: [pos.x, pos.y] }])
              }
            }
            : (e) => {
              const clickedOnEmpty = e.target === e.target.getStage();
              if (clickedOnEmpty) {
                if (props.features.includes(CANVAS_FEATURES.LAYOUT_MANIPULATION_WIDGETS)) {
                  setSelectedLayout({ layoutUuid: null });
                }
                if (props.features.includes(CANVAS_FEATURES.AP_VIEW_AND_WIDGETS)) {
                  setSelectedAp(null);
                }
                if (props.features.includes(CANVAS_FEATURES.SWITCH)) {
                  setSelectedSwitch(null);
                }
                if (props.features.includes(CANVAS_FEATURES.MOVE_CABLE_DROP)) {
                  setSelectedCableDrop(null)
                }
                if (props.features.includes(CANVAS_FEATURES.WALL_COLORS)) {
                  setSelectedWall({ layoutUuid: null, wallId: null })
                }
              }
            }}

          onMouseMove={(props.features.includes(CANVAS_FEATURES.DRAW_LINES)) ?
            (e) => {
              e.evt.preventDefault()
              if (!drawingLine.current) {
                return
              }
              const stage = e.target.getStage();
              const point = stageReference.current.getRelativePointerPosition();
              let lastLine = drawLinesList[drawLinesList.length - 1];
              // add point

              const initialx = lastLine.points[0]
              const initialy = lastLine.points[1]
              lastLine.points = [initialx, initialy, point.x, point.y];

              // replace last
              drawLinesList.splice(drawLinesList.length - 1, 1, lastLine);
              setDrawLines(drawLinesList.concat());
            }
            : (e) => { }
          }
          onMouseUp={props.features.includes(CANVAS_FEATURES.DRAW_LINES) ?
            (e) => {
              drawingLine.current = false
            }

            : (e) => { }
          }

          draggable={!drawingLine.current}
        >

          {/* {
            currentInsertedLayout != LAYOUT_TYPES.IMAGE &&
            <Axes scaleFactor={scaleFactor} />
          } */}

          {/* // props.features.includes(CANVAS_FEATURES.SHOW_LAYOUT_IMAGE) &&
          <Layer>
            <Image
              x={0}
              y={0}
              // width={ImageWidth}
              // height={ratio * ImageWidth}
              image={myImage}
            />
          </Layer>
          */}


          {
            !props.features.includes(CANVAS_FEATURES.SHOW_LAYOUT_IMAGE) &&
            <Layouts
              features={props.features}
              scaleFactor={scaleFactor}
              editableLayout={editableLayout}
              setSelectedLayout={setSelectedLayout}
              selectedLayout={selectedLayout}
              setSelectedWall={setSelectedWall}
              selectedWall={selectedWall}
              translateLayout={translateLayout}
              rotateLayout={rotateLayout}
              setSelectedAp={setSelectedAp}
              setSelectedSwitch={setSelectedSwitch}
              setSelectedCableDrop={setSelectedCableDrop}
              stageReference={stageReference}
            />
          }

          {/* {props.features.includes(CANVAS_FEATURES.SHOW_LINES) &&
            <DrawLines
              lines={drawLinesList}
              scaleFactor={scaleFactor}
              editableLayout={editableLayout}
              selectedLineIndex={selectedLineIndex}
              setSelectedLineIndex={setSelectedLineIndex}
              translateAp={translateAp}
            />
          } */}

          {
            // props.features.some((feature) => [CANVAS_FEATURES.AP_VIEW_AND_WIDGETS, CANVAS_FEATURES.SHOW_AP].includes(feature)) &&
            <AccessPoints
              scaleFactor={scaleFactor}
              editableLayout={editableLayout}
              selectedAp={selectedAp}
              setSelectedAp={setSelectedAp}
              selectedSwitch={selectedSwitch}
              setSelectedSwitch={setSelectedSwitch}
              translateAp={translateAp}
              // moveAp={[CANVAS_FEATURES.AP_VIEW_AND_WIDGETS].every(feature => props.features.includes(feature))}
              moveAp={props.features.includes(CANVAS_FEATURES.AP_VIEW_AND_WIDGETS)}
              stageReference={stageReference}
              layout={props.layouts[0]}
              setShowModal={props.setShowModal}
              saveFloorplan={props.saveFloorplan}
              updateInfraPlacement={updateInfraPlacement}
            />
          }


          {/* ----------- For Real Infra -------------- */}
          {/* {
            // props.features.some((feature) => [CANVAS_FEATURES.AP_VIEW_AND_WIDGETS, CANVAS_FEATURES.SHOW_AP].includes(feature)) &&
            <RealAccessPoints
              scaleFactor={scaleFactor}
              editableLayout={editableLayout}
              selectedAp={selectedAp}
              setSelectedAp={setSelectedAp}
              selectedSwitch={selectedSwitch}
              setSelectedSwitch={setSelectedSwitch}
              translateAp={translateAp}
              // moveAp={[CANVAS_FEATURES.AP_VIEW_AND_WIDGETS].every(feature => props.features.includes(feature))}
              moveAp={props.features.includes(CANVAS_FEATURES.AP_VIEW_AND_WIDGETS)}
              stageReference={stageReference}
              layout={props.layouts[0]}
            />
          } */}

          {
            // props.features.some((feature) => [CANVAS_FEATURES.AP_VIEW_AND_WIDGETS, CANVAS_FEATURES.SHOW_AP].includes(feature)) &&
            <Switches
              scaleFactor={scaleFactor}
              editableLayout={editableLayout}
              selectedAp={selectedAp}
              setSelectedAp={setSelectedAp}
              selectedSwitch={selectedSwitch}
              setSelectedSwitch={setSelectedSwitch}
              translateSwitch={translateSwitch}
              // moveSwitch={[CANVAS_FEATURES.SWITCH, CANVAS_FEATURES.AP_VIEW_AND_WIDGETS].every(feature => props.features.includes(feature))}
              moveSwitch={props.features.includes(CANVAS_FEATURES.SWITCH)}
              stageReference={stageReference}
              isConverted={false}
              layout={props.layouts[0]}
              saveFloorplan={props.saveFloorplan}
              updateInfraPlacement={updateInfraPlacement}


            />
          }


          {/* ------------ For Real Switches ---------- */}
          {/* {
            // props.features.some((feature) => [CANVAS_FEATURES.AP_VIEW_AND_WIDGETS, CANVAS_FEATURES.SHOW_AP].includes(feature)) &&
            <RealSwitches
              scaleFactor={scaleFactor}
              editableLayout={editableLayout}
              selectedAp={selectedAp}
              setSelectedAp={setSelectedAp}
              selectedSwitch={selectedSwitch}
              setSelectedSwitch={setSelectedSwitch}
              translateSwitch={translateSwitch}
              // moveSwitch={[CANVAS_FEATURES.SWITCH, CANVAS_FEATURES.AP_VIEW_AND_WIDGETS].every(feature => props.features.includes(feature))}
              moveSwitch={props.features.includes(CANVAS_FEATURES.SWITCH)}
              stageReference={stageReference}
              isConverted={true}
            />
          } */}


          {
            // props.features.some((feature) => [CANVAS_FEATURES.MOVE_CABLE_DROP, CANVAS_FEATURES.SHOW_CABLE_DROP].includes(feature)) &&
            <DropCables
              scaleFactor={scaleFactor}
              moveCableDrop={props.features.includes(CANVAS_FEATURES.MOVE_CABLE_DROP)}
              editableLayout={editableLayout}
              selectedCableDrop={selectedCableDrop}
              setSelectedCableDrop={setSelectedCableDrop}
              translateCableDrop={translateCableDrop}
              updateCableName={updateCableName}
              stageReference={stageReference}
              handleWheel={handleWheel}
            />
          }



        </Stage>
      </div>
      {
        // props.features.includes(CANVAS_FEATURES.SHOW_FOOTER) &&
        <div className="canvas-footer d-flex">
          <div className="d-flex w-100 align-items-center justify-content-center p-50">
            {/* <span className="ap-legend mr-1" /> */}
            <img className="mr-1" src={require('../../../../assets/images/drag/ap-online-dot.png')} height="16px" width='16px' />

            <span className="mr-2">
              Online ({infraCount?.online})
            </span>
            <img className="mr-1" src={require('../../../../assets/images/drag/ap-installed-dot.png')} height="16px" width='16px' />

            <span className="mr-2">
              Installed ({infraCount?.installed})
            </span>

            <img id="ap-fly-image" className="mr-1" src={require('../../../../assets/images/drag/ap-fly.png')} height="16px" width='16px' />

            <span className="mr-2">
              Planned ({infraCount?.planned})
            </span>
            {/* <span className="cable-drop-legend mr-1" /> */}
            <img id="cable-fly-image" className="mr-1" src={require('../../../../assets/images/drag/cable-fly.png')} height="16px" width='16px' />
            <span>
              Cable Drop ({infraCount?.cableDrop})
            </span>
          </div>
        </div>
      }
    </div>
  );
};

Canvas.propTypes = {};
Canvas.defaultProps = {};

export default Canvas;