import { Group, Image, Rect, Text } from "react-konva";
import useImage from "use-image";
// import Infra from "./infraIcon.svg";
import Infra from "../../../../../assets/images/icons/APColored.svg";
// import InfraSelected from "./infraIconSelected.svg";
import InfraSelected from "../../../../../assets/images/icons/APColoredSelected.svg";
import { useSelector } from "react-redux";
import { Point } from "../../utils";
import { COLORS } from "../../constants";
import { Html } from "react-konva-utils";
import { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import { getInfraByVenue } from "../../../../../redux/workers/activevenue.worker";
import createRequest, { services } from "../../../../../services";
import { make_custom_toast } from "../../../../../helpers/toasts";
import { CatchedWebError } from "../../../../../configs";
import SingleInfraSelector from "../../../../../components/SingleInfraSelector";
import { usePreventZoom } from "../../../../../utility/Utils";
import APSVG from "../../../../Infrastructure/InfraList/APSVG";

export const PLANNED_DOT = require('../../../../../assets/images/drag/ap-fly.png')
export const INSTALLED_DOT = require('../../../../../assets/images/drag/ap-installed-dot.png')
export const ONLINE_DOT = require('../../../../../assets/images/drag/ap-online-dot.png')
export const PLACE_INFRA_ICON = require('../../../../../assets/images/icons/infra_place_icon.png')
function truncate(val, n) {
  if (val.length > n) {
    return val.slice(0, n);
  }
  return val;
}

/**
 * Access Point
 * @param {{
 *  data: { infra_type_id: number, x: number, y: number },
 *  isSelected: boolean,
 *  scaleFactor: number,
 *  onClick: () => void
 * }} props 
 */
const AccessPoint = (props) => {
  const infraTypes = useSelector(store => store.infraTypes.data);
  const activeVenue = useSelector(store => store.activeVenue.data);

  const floorplanId = props?.layout?.id || 0
  const [imgr] = useImage(Infra);
  const [imgrS] = useImage(InfraSelected);

  const [convertingModal, setConvertingModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [placedAP, setPlacedAP] = useState(props.real)



  usePreventZoom()


  const InfraListModal = () => {
    const [selectedInfra, setSelectedInfra] = useState(null)

    const onPlace = (infra = selectedInfra) => {
      return new Promise((resolve) => {

        const convertPayload = {
          planId: props.data.id,
          venueId: activeVenue.venueId,
          infraItemId: infra?.infraItemId,
          macAddress: infra?.macAddress,
          infraDisplayName: infra?.infraName,
          infraTypeId: infra?.infraTypeId,
          location: props.data.location
        }

        const { run } = createRequest(services.wre.CONVERT_PLANNED_PIN, [floorplanId], convertPayload)
        run()
          .then(res => {
            setShowModal(false);
            make_custom_toast('success', "Floorplan", "Infrastructure placed successfully")
            props?.updateInfraPlacement(infra?.macAddress, true)
            props.saveFloorplan()
          })
          .catch(err => {
            make_custom_toast('error', 'Floorplan', (new CatchedWebError(err)).message)
          })
          .finally(() => {
            setConvertingModal(false);
            resolve();
          })
      })

    }

    return (
      <Modal style={{ maxWidth: '1020px', width: '100%' }} isOpen={showModal} centered toggle={() => setShowModal(false)}>
        <ModalHeader className="p-0 bg-white" toggle={() => setShowModal(false)}></ModalHeader>
        <ModalBody>
          <SingleInfraSelector
            venueId={activeVenue.venueId}
            venueName={activeVenue.venueName}
            defaultDeviceType={props.data?.infraTypeId}
            defaultDeviceCategory={props.data?.infraCategory}
            selectedInfra={selectedInfra}
            setSelectedInfra={setSelectedInfra}
            setShowModal={setShowModal}
            showAP={true}
            showSWITCH={false}
            onPlace={onPlace}
          />
        </ModalBody>
      </Modal>
    )
  }

  const RemoveInstall = () => {
    const { run } = createRequest(services.wre.REMOVE_PLACE_PIN, [floorplanId, props.data.infraItemId]);
    run()
      .then(res => {
        make_custom_toast("success", "Floorplan", "Removed placement.")
        props?.updateInfraPlacement(props?.data?.macAddress, false)

        props.saveFloorplan()
      })
      .catch(err => {
        make_custom_toast("error", "Floorplan", (new CatchedWebError(err)).message)
      })
  }


  return (

    <Group
      x={props.data.location.x * props.scaleFactor}
      y={props.data.location.y * props.scaleFactor}
      draggable={!placedAP && props.moveAp && props.uuid == props.selectedAp}
      onDragEnd={
        (e) => {
          const newPosition = new Point(
            e.target.x() / props.scaleFactor,
            e.target.y() / props.scaleFactor
          );
          if (!placedAP) {
            props.translateAp(props.uuid, newPosition, placedAP);
          }
        }
      }
      onClick={props.onClick}
      onMouseEnter={(e) => {
        const container = e.target.getStage().container();
        if (props.moveAp)
          container.style.cursor = "pointer";
      }}
      onMouseLeave={(e) => {
        const container = e.target.getStage().container();
        container.style.cursor = "default";
      }}

    >

      <Rect
        x={0}
        y={0}
        width={15}
        height={15}
        offsetX={4}
        offsetY={0}
        strokeWidth={1}
        cornerRadius={10}
        opacity={!props.moveAp ? 0.65 : 1}
        stroke={props.isSelected ? COLORS.PRIMARY : placedAP ? COLORS.AP_INSTALLED : COLORS.AP_COLOR}
        fill={props?.data?.online ? COLORS.AP_ONLINE : placedAP ? COLORS.AP_INSTALLED : COLORS.AP_COLOR}

        scaleX={1 / props.stageReference.current.scaleX()}
        scaleY={1 / props.stageReference.current.scaleY()}
      />
      {
        props.isSelected &&
        // <Text
        //   offsetX={-10}
        //   offsetY={5}
        //   fontSize={12}
        //   fill={"#444"}
        //   text={truncate(infraTypes.find(item => item.infraTypeId === props.data.infraTypeId)?.infraType ?? "Unknown", 7)}
        //   scaleX={1 / props.stageReference.current.scaleX()}
        //   scaleY={1 / props.stageReference.current.scaleY()}
        // />
        <Html
          divProps={{ style: {} }}
          transformFunc={(attrs) => {
            const newAttrs = { ...attrs }
            newAttrs.scaleX = 2
            newAttrs.scaleY = 2
            return newAttrs
          }}

        >
          <div


            style={{
              zoom: 'reset',
              width: '140px',
              minHeight: '30px',
              top: '0.8rem',
              position: 'absolute',
              padding: '0.2em 0.5em',
              backgroundColor: 'white',
              border: '1px solid #d4d4d4',
              borderRadius: '3px'
            }}
          >

            <div className="" style={{ fontSize: '0.5em' }}>
              <div style={{ marginBottom: '4px' }}>
                <img style={{ marginRight: '4px' }} src={props?.data?.online ? ONLINE_DOT : placedAP ? INSTALLED_DOT : PLANNED_DOT} height="8px" width="8px" />
                <span className="font-weight-bolder">{placedAP ? "Installed" : "Planned"} Position</span>
              </div>

              <div className="mt-50 d-flex justify-content-between mb-50">
                <div >
                  <div style={{ fontSize: "7px", paddingLeft: "2px" }}>Model</div>
                  <div className="d-flex align-items-center font-weight-bold">
                    <APSVG width="10px" height="10px" />
                    <div style={{ marginLeft: '2px', marginTop: "2px" }} >
                      {truncate(infraTypes.find(item => item.infraTypeId === props.data.infraTypeId)?.infraType ?? "Unknown", 14)}
                    </div>
                  </div>
                </div>

                <div >
                  <div style={{ fontSize: "6px", marginBottom: '2.5px' }}>Type</div>
                  <span className="font-weight-bold">
                    {truncate(infraTypes.find(item => item.infraTypeId === props.data.infraTypeId)?.type ?? "Unknown", 14)}
                  </span>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-end cursor-pointer">
                {
                  props.data.id && !placedAP &&
                  <div className="add-infra-button " onClick={() => { setShowModal(true) }} >
                    <img src={PLACE_INFRA_ICON} style={{ height: '8px', width: '8px', marginRight: '4px' }} />
                    Place Infrastructure
                  </div>
                }
                {
                  props.data.id && placedAP &&
                  <div className="add-infra-button" onClick={() => { RemoveInstall(); }} >Remove Installation</div>
                }
              </div>
            </div>
            <div className="ap-close-button shadow-sm">
              <span class="material-symbols-outlined cursor-pointer" onClick={() => props?.setSelectedAp(null)} style={{ fontSize: '13px' }}>
                close
              </span>
            </div>
          </div>
          <InfraListModal />
          {
            convertingModal &&
            <Modal
              centered
              isOpen={convertingModal} >
              <ModalBody>
                <div className="d-flex align-items-center justify-content-center">
                  <span>
                    Converting...
                  </span>
                  <Spinner color="primary" size={'sm'} />
                </div>
              </ModalBody>
            </Modal>
          }
        </Html>
      }
    </Group >
    // {/* <Rect height={40} width={40} x={0} y={0} strokeWidth={1} dash={[2, 2]} stroke={props.isSelected ? "white" : "#5279CE"} cornerRadius={2} fill={props.isSelected ? "#5279CE" : "white"} /> */ }
    // {/* <Image image={props.isSelected ? imgrS : imgr} height={26} width={26} offsetX={-7} offsetY={0} /> */ }
  );
};

AccessPoint.propTypes = {};
AccessPoint.defaultProps = {};

export default AccessPoint;