import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import { usdCurrencyFormatter } from "../../Marketplace/marketplace.utility";

import Skeleton from "../Graphs/Skeleton";
import ChartCard from "../Graphs/ChartCard";
import { formatDate } from "../../Marketplace/QuoteList";

const QuoteItem = ({ quote }) => {
  const navigate = useNavigate();
  const activeOrgId = useSelector(state => state.activeOrg.data.orgId);
  return (
    <div>
      <p className="d-flex justify-content-between align-items-center quote-ticket-item-group" onClick={() => {
        navigate(`/organization/${activeOrgId}/marketplace/proposals/${quote.quote_id}`)
      }} >
        <div className="d-flex flex-row">
          <div className="support-ticket-item text-truncate" style={{ width: 200 }}>{quote.quote_name}</div>
          <span className="text-truncate " style={{ width: 120 }}>{quote.quotetotal ? usdCurrencyFormatter(quote.quotetotal) : '-'}</span>
          <span className="text-truncate">Valid till: {quote.expires_at ? formatDate(quote.expires_at) : '-'}</span>
        </div>
      </p >
    </div >
  );
};

QuoteItem.defaultProps = { name: "Ticket123", id: 123 };

const TopQuoteChart = (props) => {
  const { topQuotesData, isLoading, hideDateRangeAndZoom } = props
  return (
    <ChartCard title="PROPOSALS" hideDateRangeAndZoom={hideDateRangeAndZoom}>
      <div className="d-flex justify-content-between" style={{ paddingLeft: "5px", paddingRight: "5px" }}>
        <div>
          <span className="support-heading" style={{ marginRight: 8 }}>Proposal(s) about to expire</span>
          <span className="support-number text-primary  mb-0">{isLoading ? <Skeleton /> : topQuotesData.expiring || '-'}</span>
        </div>

      </div>
      <div className="pt-1" style={{ paddingLeft: "5px", paddingRight: "5px" }}>
        <div className="d-flex flex-row align-items-center">
          <span style={{ marginRight: 4 }}>
            List of Top Proposals
          </span>

        </div>
        {isLoading ? <><Skeleton className="mb-1" /><Skeleton className="mb-1" /><Skeleton className="mb-1" /></> :
          topQuotesData?.topQuotes?.map(item => <QuoteItem quote={item} />)}
      </div>
    </ChartCard>
  );
};


TopQuoteChart.defaultProps = { isLoading: true, ticketsData: { open: 0, awaiting: 188, top: [{ name: "Quote180", id: 123 }, { name: "Quote177", id: 123 }, { name: "Quote178", id: 123 }] } };

export default TopQuoteChart;
