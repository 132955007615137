/***
 *
 * Controller class for user.
 * @file DeleteInfraModal.js
 * @description DeleteInfraModal component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React from "react";
// import PropTypes from 'prop-types';
import "./DeleteInfraModal.scss";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import InfraInfo from "../InfraInfo";

const DeleteInfraModal = (props) => {
  const { isOpen, setIsOpen, onDelete, infraIds, disabled, orgId } = props;
  return (
    <Modal
      isOpen={!!isOpen}
      toggle={() => setIsOpen(false)}
      centered
      className="DeleteInfraModal"
      data-testid="DeleteInfraModal"
    >
      <ModalHeader
        toggle={() => setIsOpen(false)}
        className="p-0 bg-white"
      >
      </ModalHeader>
      <ModalBody>
        <div className="heading mb-1">
          Do you want to delete infrastructure?
        </div>
        <InfraInfo 
          infraIds={infraIds}
          type='delete'
          orgId={orgId}
        />
        <div className="d-flex justify-content-end align-items-center mt-1 ButtonsDiv">
          <Button.Ripple
            color='primary'
            onClick={() => {
              setIsOpen(null);
            }}
            disabled={disabled}
          >
            Cancel
          </Button.Ripple>
          <Button.Ripple
            color='outline-danger'
            onClick={onDelete}
            disabled={disabled}
          >
            Delete
          </Button.Ripple>
        </div>
      </ModalBody>
    </Modal>
  );
};

DeleteInfraModal.propTypes = {};

DeleteInfraModal.defaultProps = {};

export default DeleteInfraModal;
