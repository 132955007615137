/***
 *
 * Controller class for user.
 * @file QuoteList.js
 * @description QuoteList component
 * @author Rajinder Singh
 */

import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Alert, Spinner, Table, Col, Row, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, InputGroup, Input, Button } from "reactstrap";
import { CatchedWebError } from "../../../configs";
import { breadcrumbActions } from "../../../redux/slices";
import store from "../../../redux/store";
import createRequest, { services } from "../../../services";
import { MONTH_LIST, PLAN_ID, PLAN_TEXT_MAPPING, QUOTE_LISTING_LIMIT, QUOTE_STATUS, QUOTE_STATUS_MAPPING } from "../marketplace.constants";
import "./QuoteList.scss";
import QuoteStatusGraph from "../components/Graphs/QuoteStatusGraph";
import TopQuoteChart from "../../Dashboard/TopQuoteChart";
import { usdCurrencyFormatter } from "../marketplace.utility";
import LightBadge from "../../../components/LightBadge";
import DeclineQuoteModal from "../components/Modals/DeclineQuoteModal";
import { make_custom_toast } from "../../../helpers/toasts";
import { ColumnHeader, FilterSetter, SearchIconAddon } from "../../../components";
import { QuoteStatusFilter } from "../../Filters/filters";
import FilterButton from "../../../components/FilterButton";
import { SCROLL_MORE_TEXT } from "../../../utility/constants";
import lodash from "lodash-es"
import { TD_LINK, TD_LINK_TEXT } from "../../Tickets/TicketConstants";
import EmailShareModal from "../components/Modals/EmailShareModal/EmailShareModal";
import APSVG from "../../Infrastructure/InfraList/APSVG";
import SwitchSVG from "../../Infrastructure/InfraList/SwitchSVG";
import { ReactComponent as OrgIcon } from '../../../assets/images/icons/Organization-Black.svg';
import { ReactComponent as VenueIcon } from '../../../assets/images/icons/Venue.svg';
import { ReactComponent as TruckIcon } from '../../../assets/images/icons/truck.svg';
import { ReactComponent as NotesIcon } from '../../../assets/images/icons/notes.svg';
import GroupButton from "../../../components/GroupButton";


const filterInitial = {
  statuses: []
}

export const formatDate = (dateTime) => {
  if (dateTime) {
    let localDate = dateTime?.toString()
    let year = localDate?.substring(0, 4)
    let month = localDate?.substring(5, 7)
    let date = localDate?.substring(8, 10)
    return `${date} ${MONTH_LIST[month]} ${year}`
  }
  return ''
}
const QuoteList = (props) => {
  const { venueId = 0, venue } = props
  const navigate = useNavigate()
  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
  const permissions = useSelector(store => store?.rbac?.permissions);
  const identityData = useSelector(state => state.identity.data)
  const identityId = identityData.identityId;
  const [error, setError] = useState(null)
  const [topQuotes, setTopQuotes] = useState({ expiring: 0, topQuotes: [] })
  const [quoteList, setQuoteList] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState("")
  const location = useLocation();
  const [statusGraph, setStatusGraph] = useState({
    loading: false,
    series: []
  })
  const [showDeclineQuoteModal, setShowDeclineQuoteModal] = useState(false)
  const [showEmailModal, setShowEmailModal] = useState(false)
  const [shareQuoteId, setShareQuoteId] = useState(null)
  const [quoteIdToClose, setQuoteIdToClose] = useState(0)
  const [sort, setSort] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [mainFilter, setMainFilter] = useState("all");
  const [filterData, setFilterData] = useState({
    statuses: []
  })
  const [filterActive, setFilterActive] = useState(Object.keys(location?.state?.filter ?? {}).length > 0);



  const breadCrumbs = [
    {
      path: `/organization/${activeOrgId}/marketplace/`,
      text: "Marketplace",
      active: false,
    },
    {
      path: `/organization/${activeOrgId}/marketplace/proposals`,
      text: "Proposals",
      active: true,
    },
  ];

  const getQuoteStatusCount = () => {
    setStatusGraph({
      ...statusGraph,
      loading: true
    })
    const { run } = createRequest(services.marketplace.GET_QUOTE_STATUS_COUNT, [activeOrgId])
    if (permissions?.manageQuotestats?.view)
      run()
        .then(res => {
          const data = res?.data || {}
          let series = [
            data?.open || 0,
            data?.accepted || 0,
            (data?.declined + data?.closed) || 0,

            data?.expiring || 0
          ]
          setStatusGraph({
            series: series,
            loading: false
          })

        })
        .catch(err => {

        })
  }




  const getQuotes = (loading = false, offset = 0, limit = QUOTE_LISTING_LIMIT, filter = filterData) => {
    setLoading(loading)
    let apiRequest;
    if (venueId > 0)
      apiRequest = createRequest(services.marketplace.GET_QUOTES_VENUE, [venueId, offset, limit, sort, filter, search])
    else
      apiRequest = createRequest(services.marketplace.GET_QUOTES, [activeOrgId, offset, limit, sort, filter, search])
    apiRequest.run()
      .then(res => {
        setLoading(false)
        setQuoteList(res.data)
        if (res.data.length < QUOTE_LISTING_LIMIT) {
          setHasMore(false)
        }
        else {
          setHasMore(true)
        }

      })
      .catch(err => {
        setLoading(false)
        setHasMore(false)
        const x = new CatchedWebError(err)
        make_custom_toast('error', 'Marketplace', x.message)
        // setError((new CatchedWebError(err)).message)
      })
  }

  const getTopQuotes = () => {
    const { run } = createRequest(services.marketplace.GET_TOP_QUOTES, [activeOrgId])
    run()
      .then(res => {
        if (res && res.data) {
          setTopQuotes(res.data)
        }
      })
      .catch(err => {

      })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [error])

  useEffect(() => {
    getQuotes()
    const delay = setTimeout(() => {
      if (search && (search.length > 2)) {
        getQuotes()
      }
    }, 2000)
    return () => clearTimeout(delay)
  }, [sort, search])

  useEffect(() => {
    if (venueId == null || venueId <= 0)
      store.dispatch(breadcrumbActions.setData(breadCrumbs))
    // getQuoteStatusCount()
    // getTopQuotes()
    getQuotes(true)
  }, [])



  const duplicateQuote = (quoteId, index) => {

    if (!quoteId)
      return

    let tempList = [...quoteList]
    tempList[index].loading = true
    setQuoteList(tempList)
    const { run } = createRequest(services.marketplace.DUPLICATE_QUOTE, [quoteId])
    run()
      .then(res => {
        const newQuote = res.data
        navigate(`/organization/${activeOrgId}/marketplace/proposals/${newQuote.quoteId}`, {
          state: {
            quote: newQuote
          }
        })
      })
      .catch(err => {
        setQuoteList(tempList)
      })
  }

  const shareQuote = (quoteId, index) => {
    setShowEmailModal(true)
    setShareQuoteId(quoteId)
  }


  // const debounce = (callback) => {
  //   clearTimeout(timer);
  //   timer = setTimeout(callback, 800);
  // }

  const closeQuote = (comment = '') => {
    const payload =
    {
      orgId: activeOrgId,
      identityId: identityId,
      status: QUOTE_STATUS.DECLINED,
      comments: comment
    }
    const { run } = createRequest(services.marketplace.UPDATE_QUOTE_STATUS, [quoteIdToClose], payload)
    run()
      .then(res => {
        getQuotes()
        if (res?.data?.error?.statusCode === 503) {
        }
      })
      .catch(err => {
        // setError()
        make_custom_toast('error', 'Marketplace', (new CatchedWebError(err)).message)
      })
  }

  const askReasonToDecline = () => {
    setShowDeclineQuoteModal(true)
  }


  return (
    <div className={"QuoteList " + (venueId > 0 ? "" : "mt-2")}>

      <FilterSetter
        // disabled={!Object.keys(filterSelection).length}
        showFilter={showFilter}
        elements={() => [
          <QuoteStatusFilter filter={filterData} setFilter={setFilterData} />,
        ]}
        handleApplyClick={() => {
          getQuotes();
          if (lodash.isEqual(filterData, filterInitial))
            setFilterActive(false)
          else
            setFilterActive(true);
          setShowFilter(false)
        }}
        handleClearAll={() => {
          setFilterData({
            statuses: []
          })
          getQuotes(true, 0, QUOTE_LISTING_LIMIT, {
            statuses: []
          });
          setShowFilter(false)
          setFilterActive(false);
        }}
        setShowFilter={setShowFilter} />


      {/* removed as per figma */}
      {/* { permissions?.manageQuotestats?.view &&
        <Row>
          <Col xs={6} className="d-flex">
            <QuoteStatusGraph
              hideDateRangeAndZoom={true}
              series={statusGraph.series}
              isLoading={statusGraph.loading}
            />
          </Col>
          <Col xs={6} className="d-flex">
            <TopQuoteChart
              hideDateRangeAndZoom={true}
              topQuotesData={topQuotes}
              isLoading={false}
            />
          </Col>
        </Row>
      } */}

      <div className={"d-flex align-items-center justify-content-between " + (venueId > 0 ? "" : "mt-1")}>
        <div className="d-flex align-items-center">
          <InputGroup className="input-group-merge mr-1" style={{ width: "310px" }}>
            <Input autoFocus value={search} type="text" placeholder="Search" style={{ height: '2.4rem' }} onChange={(e) => {
              setSearch(e.target.value)
            }} />
            <SearchIconAddon />
          </InputGroup>

          <GroupButton className="mr-1">
            <div
              className={
                "grp-btn-custom " +
                ((mainFilter == 'all' && !filterActive)
                  ? "active"
                  : "")
              }
              onClick={() => {
                setFilterActive(false);
                setMainFilter('all');
                // fetchVenue(0, filter.filter, filterInitial);
                setFilterData(filterInitial);
                getQuotes(true, 0, QUOTE_LISTING_LIMIT, filterInitial);
                // setFilterSelection(filterInitial);
                // setList([]);
              }}
            >
              All ({quoteList.length ?? 0})
            </div>

          </GroupButton>
          {/* <FilterButton size={22} active={filterActive} onClick={() => setShowFilter(true)}
            style={{ padding: '0.36rem' }} /> */}
        </div>
        {

          (venueId > 0 && permissions?.manageQuote?.create) ?
            null
            :
            <Button color="primary"
              onClick={() => {
                navigate(`/organization/${activeOrgId}/marketplace/`)
              }}>
              New
            </Button>
        }
      </div>

      <InfiniteScroll
        className="mt-1"
        dataLength={quoteList.length}
        next={() => {
          const { run } = createRequest(services.marketplace.GET_QUOTES, [activeOrgId, quoteList.length, QUOTE_LISTING_LIMIT, sort, filterData, search])
          run()
            .then(res => {
              setLoading(false)
              setQuoteList([...quoteList, ...res.data])
              if (res.data.length < QUOTE_LISTING_LIMIT) {
                setHasMore(false)
              }
            })
            .catch(err => {
              setLoading(false)
              setHasMore(false)
              // setError((new CatchedWebError(err)).message
              make_custom_toast('error', 'Marketplace', (new CatchedWebError(err)).message)
            })
        }
        }
        hasMore={hasMore}
        loader={<div >{SCROLL_MORE_TEXT}</div>}
        // endMessage={<div className="mb-2">Showing {quoteList.length} result(s)</div>}
        scrollableTarget={venueId > 0 ? "quote-table-id-nested" : "quote-table-id"}
        scrollThreshold={0.6}
      >
        <div id={venueId > 0 ? "quote-table-id-nested" : "quote-table-id"} >
          <Table className="table-view fixed-header" >
            <thead >
              <tr >
                <th style={{ minWidth: '100px' }}><ColumnHeader header="#" attribute={"chargebeeQuoteId"} setter={setSort} sort={sort} /></th>
                <th className="pl-0" style={{ minWidth: '150px' }}><ColumnHeader header="GENERATED BY" attribute={"identityEmail"} setter={setSort} sort={sort} /></th>
                <th className="" style={{ minWidth: '210px' }}><ColumnHeader header="ORGANIZATION" attribute={venueId > 0 ? "venueName" : "orgName"} setter={setSort} sort={sort} /></th>
                <th className="pl-0" style={{ minWidth: '120px' }}><ColumnHeader header="CUSTOMER" attribute={"endCustomer"} setter={setSort} sort={sort} /></th>
                <th className="pl-0" style={{ minWidth: '120px' }}><ColumnHeader header="PROJECT" attribute={"projectName"} setter={setSort} sort={sort} /></th>
                <th className="pl-0" style={{ minWidth: '180px' }}><ColumnHeader header="Name" attribute={"quoteName"} setter={setSort} sort={sort} /></th>
                <th className="pl-0" style={{ minWidth: '120px' }}><ColumnHeader header="PAYMENT" attribute={"planId"} setter={setSort} sort={sort} /></th>
                {/* <th className="pl-0" style={{ minWidth: '200px' }}><ColumnHeader header="CREATED ON" attribute={"createdAt"} setter={setSort} sort={sort} /></th> */}
                {/* <th className="pl-0" style={{ minWidth: '180px' }}><ColumnHeader header="VALID TILL" attribute={"expiresAt"} setter={setSort} sort={sort} /></th> */}
                <th className="pl-0" style={{ minWidth: '180px' }}><ColumnHeader right header="INFRASTRUCTURE" attribute={"deviceCountSort"} setter={setSort} sort={sort} /></th>
                <th className="pl-1" style={{ minWidth: '120px' }}><ColumnHeader right header="AMOUNT" attribute={"quoteTotal"} setter={setSort} sort={sort} /></th>
                <th></th>

              </tr>
            </thead>
            {
              loading ? <tbody>
                <tr >
                  <td style={{ height: '180px' }} colSpan={10} className="bg-white p-2 text-center ">
                    <Spinner color='primary' />
                  </td>
                </tr>
              </tbody>
                : quoteList.length > 0 ?
                  quoteList.map((quote, index) => (
                    <tbody>
                      <tr className="" key={index}>
                        <td
                          className={`table-column cursor-pointer`}
                          onClick={() => {
                            navigate(`/organization/${activeOrgId}/marketplace/proposals/${quote.quoteId}`, {
                              state: {
                                quote: quote
                              }
                            })
                          }}
                        >
                          <div className="d-flex align-items-center">
                            <span className=" w-25 table-link mr-1" style={{ fontSize: 12 }}>
                              #{quote.chargebeeQuoteId}
                            </span>

                            {/* removed as per figma */}
                            {/* <span className="w-75">
                              <LightBadge
                                color={quote.status === QUOTE_STATUS.OPEN ? 'warning' :
                                  (quote.status === QUOTE_STATUS.ACCEPTED) ? 'success' :
                                    (quote.status === QUOTE_STATUS.ORDERED) ? 'info' :
                                      (quote.status === QUOTE_STATUS.DECLINED || quote.status === QUOTE_STATUS.EXPIRED) ? 'danger' :
                                        'light-warning'}
                              > {QUOTE_STATUS_MAPPING[quote.status]}
                              </LightBadge>
                            </span> */}
                          </div>
                        </td>
                        <td className={`pl-0`}>{quote.identityEmail || '-'}</td>
                        <td className={``}>
                          <span>
                            <OrgIcon style={{ marginRight: '4px' }} />
                            {quote.orgName}
                          </span>
                        </td>
                        <td className={`pl-0`}><div className="d-flex"><div className="text-nowrap ellipsed-text flex-grow-1" style={{ width: "120px" }}>{quote.endCustomer || '-'}</div></div></td>
                        <td className={`pl-0`}><div className="d-flex"><div className="text-nowrap ellipsed-text flex-grow-1" style={{ width: "120px" }}>{quote.projectName || '-'}</div></div></td>
                        <td className={`pl-0`}>{quote.quoteName || '-'}</td>
                        {/* <td className={`pl-0`}>{formatDate(quote.createdAt) || '-'}</td> */}
                        {/* <td className={`pl-0`}>{formatDate(quote.expiresAt) || '-'}</td> */}
                        <td className={`pl-0`}>{PLAN_TEXT_MAPPING[quote?.planId ?? 0]}</td>

                        <td className="pl-0">
                          <div className="d-flex align-items-center justify-content-end" style={{ paddingRight: "24px" }} >

                            <span className="mr-2">
                              <APSVG className="mr-50" />
                              {quote?.deviceCount?.ap_count}
                            </span>


                            <span>
                              <SwitchSVG className="mr-50" />
                              {quote?.deviceCount?.switch_count}
                            </span>


                          </div>

                        </td>
                        <td className={`pl-0`}><div className="d-flex justify-content-end mr-2">
                          {quote.quoteTotal ? usdCurrencyFormatter(quote.quoteTotal) : '-'}</div>
                        </td>
                        <td>
                          <UncontrolledDropdown direction="left">
                            <DropdownToggle color='white' className="w-0 p-0">
                              <span className="material-symbols-outlined text-primary cursor-pointer" title="Actions">more_vert</span>
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem className="w-100 text-secondary" onClick={() => {
                                shareQuote(quote.quoteId, index)
                              }}>
                                Share Proposal
                              </DropdownItem>
                              <DropdownItem className="w-100 text-secondary" onClick={() => {
                                duplicateQuote(quote.quoteId, index)
                              }}>
                                Duplicate Proposal
                              </DropdownItem>

                              {
                                quote.status == QUOTE_STATUS.OPEN &&
                                < DropdownItem className="w-100 "
                                  onClick={() => {
                                    setQuoteIdToClose(quote.quoteId)
                                    askReasonToDecline()
                                  }}>
                                  Close Proposal
                                </DropdownItem>
                              }

                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                      <tr className="" key={index + 'secondrow'}>
                        <td className="border-0" />
                        <td className="border-0" />
                        <td className={`second-row-color`} >
                          <div className="d-flex">
                            <VenueIcon style={{ marginRight: '4px' }} />
                            <div className={`ellipsed-text text-nowrap flex-grow-1 ${(quote?.venueOrgId && quote?.venueId) ? "text-primary cursor-pointer" : ""}`} onClick={() => {
                              if (quote?.venueOrgId && quote?.venueId) {
                                navigate(`/organization/${quote?.venueOrgId}/venues/${quote?.venueId}`)
                              }
                            }} style={{ width: "120px" }}>{quote.venueName || '-'}</div>
                          </div>  </td>
                        <td colSpan={2} className={`pl-0 second-row-color`}>
                          <div className="text-nowrap d-flex">
                            <TruckIcon style={{ marginRight: '4px' }} />
                            <div className="ellipsed-text text-nowrap flex-grow-1" style={{ width: "240px" }}>
                              {quote.shippingAddress.addressLine1 || '-'}
                            </div>
                          </div>
                        </td>
                        <td colSpan={3} className={`pl-0 second-row-color`}>
                          <div>
                            {
                              quote?.notes && <div className="d-flex">
                                <NotesIcon style={{ marginRight: '4px' }} />
                                <div className="ellipsed-text text-nowrap flex-grow-1" style={{ width: "420px" }}>{quote?.notes || ''}</div>
                              </div>
                            }
                          </div>
                        </td>



                      </tr>
                    </tbody>
                  ))
                  :
                  <tbody>
                    <tr >
                      <td colSpan={10} className="bg-white p-2 text-center ">
                        No Proposal found.
                      </td>
                    </tr>
                  </tbody>


            }
          </Table></div>
      </InfiniteScroll >
      <div>
        {/* {TD_LINK_TEXT} <span className="text-primary cursor-pointer" onClick={() => { window.open(TD_LINK) }}>LINK</span>. */}
        Shasta Cloud’s Distribution Partner is TDSynnex for order fulfillment and credit. If you don’t already have an account with TD Synnex you can  <span className="text-primary cursor-pointer" onClick={() => { window.open(TD_LINK) }}>sign-up</span> here.
      </div>



      {
        showDeclineQuoteModal &&
        <DeclineQuoteModal
          visible={showDeclineQuoteModal}
          setVisible={setShowDeclineQuoteModal}
          declineQuote={(reason) => closeQuote(reason)}
        />
      }

      {
        showEmailModal &&
        <EmailShareModal
          visible={showEmailModal}
          setVisible={setShowEmailModal}
          quoteId={shareQuoteId}
        />
      }

    </div >
  );
};

QuoteList.propTypes = {};

QuoteList.defaultProps = {};

export default QuoteList;
