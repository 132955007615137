/***
 *
 * Controller class for user.
 * @file Header.js
 * @description Header component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 */

import React from "react";
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';
import "./Header.scss";
import Tabs from "../../../../components/Tabs";
import { Button } from "reactstrap";
import { useContext } from "react";
import { VenueContext } from "../../SingleVenue";
import { NONE, UPDATE, isAdmin } from "../../../../utility/constants";

const Header = (props) => {
  const { venueInfo } = useContext(VenueContext);
  const activeVenueId = useSelector(store => store.activeVenue.data.venueId);
  const identity = useSelector(store => store.identity.data)
  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
  const permissions = useSelector(store => store.rbac.permissions);
  return (
    <div className="Header" data-testid="Header">
      <div>
        <div className="d-flex align-items-center">
          <span style={{ paddingLeft: "0.4em" }}>
            {props.setGraphStatus &&
              <Button.Ripple className="btn-icon rounded-circle" color="flat-primary" id="refreshButton" onClick={() => { props.setGraphStatus(-2) }}>
                <span className="material-symbols-outlined">sync</span>
              </Button.Ripple>
            }
          </span>
        </div>
      </div>
      <Tabs className="d-flex-inline" data={[
        ...permissions?.manageVenuestats?.view ?
          [{
            path: `/organization/${activeOrgId}/venues/${activeVenueId}/overview/`,
            text: "Overview"
          }] : [],
        ...(venueInfo?.permissions?.scan == null ? permissions?.manageScan?.view : venueInfo?.permissions?.scan > NONE) ?
          [{
            path: `/organization/${activeOrgId}/venues/${activeVenueId}/design/`,
            text: "Design"
          }] : [],
        ...(venueInfo?.permissions?.infra == null ? permissions?.manageInfra?.view : venueInfo?.permissions?.infra > NONE) ?
          [{
            path: `/organization/${activeOrgId}/venues/${activeVenueId}/infrastructure/`,
            text: "Infrastructure",
            isPermitted: permissions?.manageInfra?.view
          }] : [],
        ...permissions?.manageVenuestats?.view ?
          [{
            path: `/organization/${activeOrgId}/venues/${activeVenueId}/timeline/`,
            text: "Timeline"
          }] : [],
        ...(venueInfo?.permissions?.device == null ? permissions?.manageDevice?.view : venueInfo?.permissions?.device > NONE) ?
          [{
            path: `/organization/${activeOrgId}/venues/${activeVenueId}/clients/`,
            text: "Clients"
          }] : [],
        ...(venueInfo?.permissions?.network == null ? permissions?.manageNetwork?.view : venueInfo?.permissions?.network > NONE) ?
          [{
            path: `/organization/${activeOrgId}/venues/${activeVenueId}/network/`,
            text: "Network"
          }] : [],
        ...(venueInfo?.permissions?.quote == null ? permissions?.manageQuote?.view : venueInfo?.permissions?.quote > NONE) ?
          [{
            path: `/organization/${activeOrgId}/venues/${activeVenueId}/proposal/`,
            text: "Proposal"
          }] : [],
        // ...(venueInfo?.permissions?.scan == null ? permissions?.manageScan?.view : venueInfo?.permissions?.scan > NONE) ?
        //   [{
        //     path: `/organization/${activeOrgId}/venues/${activeVenueId}/floorPlan/`,
        //     text: "Layouts"
        //   }] : [],


        // ...(venueInfo?.permissions?.venue == null ? permissions?.manageVenue?.view : venueInfo?.permissions?.venue > NONE) ?
        //   [{
        //     path: `/organization/${activeOrgId}/venues/${activeVenueId}/settings/`,
        //     text: "Settings"
        //   }] : [],
        // ...(isAdmin(identity.roleIds[0])) ?
        //   [{
        //     path: `/organization/${activeOrgId}/venues/${activeVenueId}/permissions/`,
        //     text: "Permissions"
        //   }] : [],
        ...(venueInfo?.permissions?.venue == null ? permissions?.manageVenue?.update : venueInfo?.permissions?.venue >= UPDATE) ? [{
          path: `/organization/${activeOrgId}/venues/${activeVenueId}/admin`,
          text: 'Admin'
        }] : [],
        ...permissions?.manageFirmware?.create ?
          [{
            path: `/organization/${activeOrgId}/venues/${activeVenueId}/padmin/`,
            text: "P-Admin"
          }] : [],
      ]} />
    </div>
  );
};

Header.propTypes = {
  heading: PropTypes.string
};

Header.defaultProps = {
  heading: "Venues"
};

export default Header;
