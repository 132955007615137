import PropTypes from "prop-types";
import { Layer } from "react-konva";
import { EditableLayout, Point } from "../../utils";
import Layout from "./Layout";

/**
 * Draw and Handle events for all layouts on the screen
 * @param {{
 *  features: Array<number>,
 *  scaleFactor: number,
 *  editableLayout: EditableLayout,
 *  setSelectedLayout: Function,
 *  selectedWall: { layoutUuid: string?, wallId: string? },
 *  setSelectedWall: (_: { layoutUuid: string?, wallId: string? }) => void,
 *  translateLayout: (layoutUuid: string, toPoint: Point) => void,
 *  rotateLayout: (layoutUuid: string, toAngle: number) => void,
 *  selectedLayout: { layoutUuid: string? }
 * }} props 
 * @returns 
 */
const Layouts = (props) => {
  return (
    <Layer>
      {Object.keys(props.editableLayout.layouts).map(uuid => {
        return (
          <Layout
            translateLayout={props.translateLayout}
            rotateLayout={props.rotateLayout}
            setSelectedLayout={props.setSelectedLayout}
            selectedLayout={props.selectedLayout}
            selectedWall={props.selectedWall}
            setSelectedWall={props.setSelectedWall}
            features={props.features}
            scaleFactor={props.scaleFactor}
            layout={props.editableLayout.layouts[uuid]}
            uuid={uuid}
            setSelectedAp={props.setSelectedAp}
            setSelectedSwitch={props.setSelectedSwitch}
            setSelectedCableDrop={props.setSelectedCableDrop}
            stageReference={props.stageReference}
          />
        );
      })}
    </Layer>
  );
};

Layouts.propTypes = {
  setSelectedLayout: PropTypes.func,
  features: PropTypes.array,
  scaleFactor: PropTypes.number,
  editableLayout: PropTypes.instanceOf(EditableLayout)
};
Layouts.defaultProps = {};

export default Layouts;