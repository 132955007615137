import PropTypes from "prop-types";
import { LayoutData, Point } from "../../utils";
import { Circle, Group, Image, Line, Transformer } from "react-konva";
import LayoutFiller from "./LayoutFiller";
import { CANVAS_FEATURES, FAKE_WALL_DATA, LAYOUT_TYPES, WALL_COLORS } from "../../constants";
import { useEffect, useMemo, useRef } from "react";
import useImage from "use-image";

/**
 * 
 * @param {{
 *   selectedLayout: { layoutUuid: string? }
 *   setSelectedLayout: Function,
 *   features: Array<number>
 *   scaleFactor: number,
 *   layout: LayoutData,
 *   uuid: string,
 *   translateLayout: (layoutUuid: string, toPoint: Point) => void,
 *   rotateLayout: (layoutUuid: string, toAngle: number) => void,
 *   selectedWall: { layoutUuid: string?, wallId: string? },
 *   setSelectedWall: (_: { layoutUuid: string?, wallId: string? }) => void,
 * }} props 
 * @returns 
 */
const Layout = (props) => {
  const shapeReference = useRef();
  const transformReference = useRef();
  const { selectedLayout, uuid, features } = props;
  const [image] = useImage(props?.layout?.imageLink);
  const ImageWidth = FAKE_WALL_DATA.components[0].walls[0].loc[2] * props.scaleFactor


  useEffect(() => {
    if (selectedLayout.layoutUuid === uuid && features.includes(CANVAS_FEATURES.LAYOUT_MANIPULATION_WIDGETS)) {
      transformReference.current.nodes([shapeReference.current]);
      transformReference.current.getLayer().batchDraw();
    }
  }, [selectedLayout, uuid, features]);



  return (
    <>
      <Group
        ref={shapeReference}
        rotation={props.layout.transform.rotation}
        x={props.layout.transform.position.x * props.scaleFactor}
        y={props.layout.transform.position.y * props.scaleFactor}
        draggable={props.features.includes(CANVAS_FEATURES.LAYOUT_MANIPULATION_WIDGETS) && props.selectedLayout.layoutUuid === props.uuid}
        onClick={props.features.includes(CANVAS_FEATURES.LAYOUT_MANIPULATION_WIDGETS) ?
          (e) => {
            if (props.layout.layoutType !== LAYOUT_TYPES.IMAGE)
              props.setSelectedLayout({ layoutUuid: props.uuid })
          } : () => {
            props.setSelectedAp(null)
            props.setSelectedSwitch(null)
            props.setSelectedCableDrop(null)
          }
        }
        onDragEnd={props.features.includes(CANVAS_FEATURES.LAYOUT_MANIPULATION_WIDGETS) && props.selectedLayout.layoutUuid === props.uuid ?
          (e) => {
            const newPosition = new Point(
              e.target.x() / props.scaleFactor,
              e.target.y() / props.scaleFactor
            );
            props.translateLayout(props.uuid, newPosition);
          } : () => { }
        }
        onTransformEnd={props.features.includes(CANVAS_FEATURES.LAYOUT_MANIPULATION_WIDGETS) ?
          (e) => {
            props.translateLayout(props.uuid, new Point(
              shapeReference.current.position().x / props.scaleFactor,
              shapeReference.current.position().y / props.scaleFactor
            ))

            props.rotateLayout(props.uuid, shapeReference.current.rotation())
          } : () => { }
        }
      >
        {props.features.includes(CANVAS_FEATURES.LAYOUT_MANIPULATION_WIDGETS) &&
          <LayoutFiller scaleFactor={props.scaleFactor} layout={props.layout} />
        }

        {/* Center Circle for layout */}
        {/* {props.features.includes(CANVAS_FEATURES.LAYOUT_MANIPULATION_WIDGETS) && props.layout.layoutType !== LAYOUT_TYPES.IMAGE &&
          <Circle radius={3} stroke="black" x={props.layout.pivot.x * props.scaleFactor} y={props.layout.pivot.y * props.scaleFactor} />
        } */}

        {
          props.layout.layoutType !== LAYOUT_TYPES.IMAGE &&
          props.layout.walls.map(wall => {
            return (
              <Line
                key={`${props.uuid}-${wall.id}`}
                strokeWidth={props.features.includes(CANVAS_FEATURES.WALL_COLORS) ? 12 : 6}
                lineCap={props.features.includes(CANVAS_FEATURES.WALL_COLORS) ? "square" : "round"}
                lineJoin="round"
                onMouseEnter={CANVAS_FEATURES.WALL_COLORS ? e => {
                  // style stage container:
                  const container = e.target.getStage().container();
                  container.style.cursor = "pointer";
                } : () => { }}
                onMouseLeave={CANVAS_FEATURES.WALL_COLORS ? e => {
                  const container = e.target.getStage().container();
                  container.style.cursor = "default";
                } : () => { }}
                stroke={
                  (props.selectedLayout.layoutUuid === uuid && props.features.includes(CANVAS_FEATURES.LAYOUT_MANIPULATION_WIDGETS)) ?
                    "#5279CE" :
                    (props.selectedWall.layoutUuid === uuid && props.selectedWall.wallId === wall.id ?
                      "#5279CE" :
                      WALL_COLORS[wall.material]
                    )

                }
                onClick={
                  props.features.includes(CANVAS_FEATURES.WALL_COLORS) ?
                    (e) => { props.setSelectedWall({ layoutUuid: props.uuid, wallId: wall.id }) } :
                    () => { }
                }
                strokeScaleEnabled={false}
                points={[wall.from.x, wall.from.y, wall.to.x, wall.to.y].map(x => x * props.scaleFactor)}
              />
            );
          })}
        {
          props?.layout?.layoutType === LAYOUT_TYPES.IMAGE &&
          <Image
            x={0}
            y={0}

            image={image}
            width={ImageWidth}
            height={(image?.height / image?.width) * ImageWidth}
          />
        }
      </Group>
      {props.features.includes(CANVAS_FEATURES.LAYOUT_MANIPULATION_WIDGETS) && props.selectedLayout.layoutUuid === props.uuid &&
        <Transformer
          onMouseEnter={e => {
            // style stage container:
            const container = e.target.getStage().container();
            container.style.cursor = "grab";
          }}
          onMouseDown={e => {
            // style stage container:
            const container = e.target.getStage().container();
            container.style.cursor = "grabbing";
          }}
          onMouseUp={e => {
            // style stage container:
            const container = e.target.getStage().container();
            container.style.cursor = "grab";
          }}
          onMouseLeave={e => {
            const container = e.target.getStage().container();
            container.style.cursor = "default";
          }}
          resizeEnabled={false}
          rotationSnaps={[0, 90, 180, 270]}
          borderStrokeWidth={3}
          ignoreStroke={false}
          anchorStrokeWidth={3}
          anchorSize={20}
          anchorCornerRadius={2}
          borderDash={[10, 3]}
          ref={transformReference}
          shouldOverdrawWholeArea={true}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      }
    </>
  );
};

Layout.defaultProps = {
  setSelectedLayout: PropTypes.func,
  features: PropTypes.arrayOf(PropTypes.number),
  scaleFactor: PropTypes.number,
  layout: PropTypes.instanceOf(LayoutData),
  uuid: PropTypes.string
};
Layout.propTypes = {};

export default Layout;