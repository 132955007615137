import store from "../store";
import { identityActions, criticalActions, deviceActions, oneInfraActions, notificationAction, devicesDebugActions, rbacActions, permissionsActions, orgActions, activeOrgActions, orgtypesActions, infraTypesActions, rebootingAction } from "../slices";
// import { getToken, refreshToken } from "../../authCustom";
import axios from "axios";
import { B, make_toast } from "../../helpers";
import { CatchedWebError } from "../../configs";
import traceRouteAction from "../slices/traceRouteData.slice";
import wreAction from "../slices/wre.slice";
import { getTokenSilently, logout } from "../../auth0";

/**
 * Function to get data before createRequest is available
 * @param {string} url - URL Endpoint
 * @param {AbortController} controller - Abort Controller to stop the request
 * @param {string} token - Auth Token
 * @param {number?} session_id - Optional Session Id
 * @returns Axios Promise of the request
 */
async function GET_DATA(url, controller, token, session_id = null) {
  let headers = {
    Authorization: `Bearer ${token}`
  };
  if (session_id) {
    headers["session-id"] = `${session_id}`;
  }
  return new Promise((resolve, reject) => {
    axios.get(url, {
      signal: controller.signal,
      headers: headers
    }).then((response) => {
      resolve(response);
    }).catch(async e => {
      if (e?.response?.status === 401) {
        // await refreshToken();
        logout({logoutParams:{returnTo: `${window.location.origin}/exit.html`}});
      }
      else
        reject();
    })
  })
}

export let socket = null;

export async function initSocket(token) {
  socket?.disconnect()
  token = token ?? store.getState().identity.meta.token
  if(!!token) {
    token = await getTokenSilently();
    store.dispatch(identityActions.setToken(token));
  }
  function connectsocket() {
    return window.io(process.env.REACT_APP_SOCKET, {
      auth: {
        Authorization: `Bearer ${token}`
      },
      transports: ["websocket"],
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: Infinity
    });
  }
  // Create a socket connection
  socket = connectsocket();
  
  // Upon connection 
  socket.on("connect", function () {
    if (store.getState().activeOrg.data.orgId) {
      try {
        socket.emit("switch", JSON.stringify({ orgId: store.getState().activeOrg.data.orgId }));
      } catch (e) {
      }
    }
  });

  // Reconnect if disconnected
  socket.on("disconnect", function () {
    socket = connectsocket();
  })

  socket.on("connect_error", async (err) => {
    if(err.message === 'Invalid token received') {
      let newToken = await getTokenSilently()
      store.dispatch(identityActions.setToken(newToken));
      initSocket(newToken)
    }
  });

  socket.on("message", (message) => {

    const bulkSocketParser = (UUID, noti, socketData, setterFn) => {
      if(socketData?.bulkActionId === UUID) {
        const latestSocketData = {...socket_msg.data};
        if(!!noti) {
          if(noti?.bulkActionStatus === 'Completed') {
            latestSocketData.bulkActionStatus = noti?.bulkActionStatus;
          }
          latestSocketData.completedSofar = Math.max(noti.completedSofar??0, latestSocketData.completedSofar??0)
          latestSocketData.failedSofar = Math.max(noti.failedSofar??0, latestSocketData.failedSofar??0)
          latestSocketData.total = Math.max(noti.total??0, latestSocketData.total??0)
        }
        setterFn(latestSocketData);
      }
    }
    const socket_msg = JSON.parse(message);
    if (socket_msg?.category == "BULK_IMPORT_VENUE") {
      const UUID = store.getState().identity.meta.UUID;
      const importVenueNotification = store.getState().identity.meta.importVenueNotification;
      bulkSocketParser(
        UUID, 
        importVenueNotification, 
        socket_msg.data, 
        (latestSocketData) => store.dispatch(
          identityActions.setImportVenueNotification(
            latestSocketData
          )
        )
      )
    }
    else if (socket_msg?.category == "BULK_IMPORT") {
      const UUID = store.getState().identity.meta.UUID;
      const importNotification = store.getState().identity.meta.importNotification;
      bulkSocketParser(
        UUID, 
        importNotification, 
        socket_msg.data, 
        (latestSocketData) => store.dispatch(
          identityActions.setImportNotification(
            latestSocketData
          )
        )
      )
    }
    else if (socket_msg?.category == "BULK_MOVE") {
      const UUID = store.getState().identity.meta.moveUUID;
      const moveNotification = store.getState().identity.meta.moveNotification
      bulkSocketParser(
        UUID, 
        moveNotification, 
        socket_msg.data, 
        (latestSocketData) => store.dispatch(
          identityActions.setMoveNotification(
            latestSocketData
          )
        )
      )
    }
    else if (socket_msg?.category == "BULK_DELETE") {
      const UUID = store.getState().identity.meta.deleteUUID;
      const deleteNotification = store.getState().identity.meta.deleteNotification
      bulkSocketParser(
        UUID, 
        deleteNotification, 
        socket_msg.data, 
        (latestSocketData) => store.dispatch(
          identityActions.setDeleteNotification(
            latestSocketData
          )
        )
      )
    }
    else if (socket_msg?.category == "FW_UPGRADE_ALL") {
      store.dispatch(identityActions.setUpdateDashboardMessage(socket_msg.data));
    }
    else if (socket_msg?.category == "AP_TRACEROUTE") {
      store.dispatch(traceRouteAction.setTraceRouteData(socket_msg?.data))
    }
    else if (socket_msg?.category == "DEVICE_CONNECT") {
      const oid = store.getState().activeOrg.data.orgId;
      if (oid && oid == socket_msg?.orgId) {
        store.dispatch(deviceActions.handleSocketData({ ...socket_msg.data, socketType: 'connected' }));
        // store.dispatch(deviceActions.addSocketData(socket_msg.data));
        // store.dispatch(disconnectedActions.removeSocketData2(socket_msg?.data.clientMac));
      }
    }
    else if (socket_msg?.category == "DEVICE_DISCONNECT") {
      const oid = store.getState().activeOrg.data.orgId;
      if (oid && oid == socket_msg?.orgId) {
        store.dispatch(deviceActions.handleSocketData({ ...socket_msg.data, socketType: 'disconnected' }))
        // store.dispatch(deviceActions.removeSocketData(socket_msg?.data.clientMac));
        // store.dispatch(disconnectedActions.addSocketData2(socket_msg?.data))
      }
    }
    else if (socket_msg?.category == "DEVICE_DISCONNECT_LIST") {
      const oid = store.getState().activeOrg.data.orgId;
      if (oid && oid == socket_msg?.orgId) {
        const removedDevices = socket_msg?.data ?? [];
        for (const device of removedDevices) {
          store.dispatch(deviceActions.handleSocketData({ ...device, socketType: 'disconnected' }))
          // store.dispatch(deviceActions.removeSocketData(device.clientMac));
          // store.dispatch(disconnectedActions.addSocketData2(device))
        }
      }
    }
    else if (socket_msg?.category == "DEVICE_CONNECT_LIST") {
      const oid = store.getState().activeOrg.data.orgId;
      if (oid && oid == socket_msg?.orgId) {
        const devices = socket_msg?.data ?? [];
        for (const device of devices) {
          store.dispatch(deviceActions.handleSocketData({
            ...device, socketType: 'connected',
            // disableCountUpdate: true, 
            // appendDataList: true
          }))
        }
        // store.dispatch(deviceActions.setCountFetch(true));
      }
    }
    else if (socket_msg?.category == "DEVICE_BOOT_UP") {
      store.dispatch(rebootingAction.setRebooting({
        infraId: socket_msg?.data.infraItemId,
        start: Date.now(),
        end: Date.now() + (1.5 * 60 * 1000)
      }))
    }
    else if (socket_msg?.category == "AP_STATUS") {
      store.dispatch(rebootingAction.setStatus({ infraId: socket_msg?.data.infraItemId, status: (socket_msg?.data.status.toLowerCase() == "online" ? "connected" : "disconnected") }));
      if (store.getState().oneInfra.data.infraItemId == socket_msg?.data.infraItemId) {
        store.dispatch(oneInfraActions.setStatus(socket_msg?.data.status == "online"));
      }
      if (socket_msg?.data.status == "online") {
        store.dispatch(rebootingAction.clearRebooting({
          infraId: socket_msg?.data.infraItemId,
        }))
      }
    }
    else if (socket_msg?.category == "SUPPORT_REPLY") {
      store.dispatch(notificationAction.setTicketNotification(socket_msg?.data?.count));
    }
    else if (socket_msg?.category == "ALARM_NOTIFICATION") {
      const activeOrgId = store.getState().activeOrg.data.orgId;
      // check if activeOrgId is maching with the orgId in socket
      if (activeOrgId === socket_msg?.data?.orgId) {
        store.dispatch(notificationAction.setInfraOfflineAlarmCount(socket_msg?.data?.count));
      }
    }
    else if (socket_msg?.category == "UE_DEBUG") {
      const oid = store.getState().activeOrg.data.orgId;
      if (socket_msg?.data?.macAddress && oid && oid == socket_msg?.data?.orgId) {
        if (socket_msg?.data?.status === "error") {
          socket_msg?.data?.message && make_toast("error", socket_msg?.data?.message);
          store.dispatch(devicesDebugActions.setDevice({ status: "N/A", ueId: (socket_msg?.data?.mac + socket_msg?.data?.macAddress) }));
        }
        else {
          store.dispatch(devicesDebugActions.setTroubleshootSuccess(('for ' + socket_msg?.data?.mac)));
          store.dispatch(devicesDebugActions.setDevice({ status: socket_msg?.data?.status, ueId: (socket_msg?.data?.mac + socket_msg?.data?.macAddress) }));
        }
      }
    }
    else if (socket_msg?.category === "RF_SCORE") {
      store.dispatch(wreAction.setAnalysisScore(socket_msg.data))
      // make_custom_toast('success', 'Floorplan', 'Score calculated', true, 'See Results', () => {

      // })
    }
    else {
      store.dispatch(identityActions.setSocketData(socket_msg));
    }
  });
}

async function criticalSetup3() {
  store.dispatch(criticalActions.setLoading(true));
  store.dispatch(identityActions.setLoading(true));
  store.dispatch(permissionsActions.setLoading(true));
  store.dispatch(orgActions.setLoading(true));
  store.dispatch(activeOrgActions.setLoading(true));
  store.dispatch(orgtypesActions.setLoading(true));
  store.dispatch(infraTypesActions.setLoading(true));

  let afterPromise = null;

  try {
    const controller = new AbortController();

    // TOKEN
    const token = await getTokenSilently();
    store.dispatch(identityActions.setToken(token));

    // SOCKET
    await initSocket(token);

    if (store.getState().identity.data.identityId == null) {
      let identity = await GET_DATA(B("identity"), controller, token);

      store.dispatch(rbacActions.set(identity.data));

      if (identity.data.blocked) {
        store.dispatch(criticalActions.setStatus("BLOCKED"));
        store.dispatch(criticalActions.setLoading(false));
        return { controller, afterPromise };
      }

      // Extract redux compatible Identity
      const compat_identity = Compat_IdentityData(identity.data);
      store.dispatch(identityActions.setIdentity(compat_identity));

      // Extract redux compatible Organization Types
      const compat_orgtype = Compat_OrgTypes(identity.data);
      store.dispatch(orgtypesActions.setOrgTypes(compat_orgtype));

      // Extract redux compatible organization data
      const compat_organization = Compat_Organization(identity.data);
      store.dispatch(orgActions.setOrg(compat_organization));

      // Extract redux compatible organization roles data
      const compat_orgroles = Compat_OrgRoles(identity.data);
      store.dispatch(orgActions.setRoles(compat_orgroles));
    }

    // Fetch and extract redux compatible infra types: the new way
    //const infraTypes = await GET_DATA(B(`infrastructure/infraType?orgId=${identity.data.orgId}`), controller, token, identity.data.orgId);
    // The old way
    //const infraTypes = await GET_DATA(B(`/infrastructure/infraType?orgId=${identity.data.orgId}`), controller, token);
    //store.dispatch(infraTypesActions.setInfraTypes(infraTypes.data));

    store.dispatch(criticalActions.setStatus("OK"));
    return { controller, afterPromise };
  } catch (error) {
    const x = new CatchedWebError(error);



    store.dispatch(infraTypesActions.setError(x.message));
    store.dispatch(activeOrgActions.setError(x.message));
    store.dispatch(orgtypesActions.setError(x.message));
    store.dispatch(identityActions.setError(x.message));
    store.dispatch(rbacActions.setError(x.message));
    store.dispatch(criticalActions.setError(x.message));
    store.dispatch(criticalActions.setStatus("ERROR"));
  } finally {
    store.dispatch(infraTypesActions.setLoading(false));
    store.dispatch(activeOrgActions.setLoading(false));
    store.dispatch(orgtypesActions.setLoading(false));
    store.dispatch(identityActions.setLoading(false));
    store.dispatch(rbacActions.setLoading(false));
    store.dispatch(criticalActions.setLoading(false));
  }
}

export function Compat_IdentityData(rbac) {
  return {
    identityId: rbac.identityId,
    userName: rbac.userName,
    email: rbac.email,
    phone: rbac.phone,
    blocked: rbac.blocked,
    lastLogin: rbac.lastLogin,
    roleIds: rbac?.roles?.map(r => r?.roleId),
    orgId: rbac.orgId,
    picture: null,
    createdAt: null,
    updatedAt: null,
    editableIdentity: rbac.editableIdentity,
    organization: {
      auth0OrgId: null,
      orgDisplayName: rbac.organization?.orgDisplayName ?? ""
    }
  }
}

export function Compat_OrgTypes(rbac) {
  return rbac.orgType;
}

export function Compat_Organization(rbac) {
  return rbac.organization;
}

export function Compat_OrgRoles(rbac) {
  // not exist currently
  return rbac.systemRoles || [];
}

export default criticalSetup3;