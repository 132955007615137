/***
 *
 * Controller class for user.
 * @file SingleVenue.js
 * @description SingleVenue component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 */

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RoutesMaker, VeryFancyLoader } from "../../../components";
import { useSelector } from "react-redux";
import REDUX_WORKER from "../../../redux/workers";
import { activeVenueActions } from "../../../redux/slices";
import { useDispatch } from "react-redux";
import { NewoverviewWrapper } from "../NewOverview";
import AddVenue from "../AddVenue";
import Infrastructure from "../Infrastructure";
import Timeline from "../Timeline";
import Devices from "../Devices";
import Network from "../Network";
import PAdmin from "../PAdmin";
import FloorPlan2wrapper from "../FloorPlan2";
import Settings from "../Settings";
import Permissions from "../Permissions";
import IdentityPermissions from "../IdentityPermissions";
import { MANAGE, NONE } from "../../../utility/constants";
import { createContext } from "react";
import LayoutEditor from "../LayoutEditor/LayoutEditor";
import { make_custom_toast } from "../../../helpers/toasts";
import store from "../../../redux/store";
import DesignLayout from "../FloorPlan2/DesignLayout";
import VenueAdmin from "../../VenueAdmin";
import Proposals from "../Proposals";
export const VenueContext = createContext(null);


const ReDirector = ({ venuePermissions }) => {
  const orgPermissions = useSelector(store => store.rbac.permissions);
  const navigate = useNavigate();

  useEffect(() => {
    if (venuePermissions?.venue == null ? orgPermissions?.manageVenuestats?.view : venuePermissions?.venue > NONE)
      navigate("overview/", { replace: true })
    else if (venuePermissions?.scan == null ? orgPermissions?.manageScan?.view : venuePermissions?.scan > NONE)
      navigate("design/", { replace: true })
    else if (venuePermissions?.infra == null ? orgPermissions?.manageInfra?.view : venuePermissions?.infra > NONE)
      navigate("infrastructure/", { replace: true })
    else if (venuePermissions?.network == null ? orgPermissions?.manageNetwork?.view : venuePermissions?.network > NONE)
      navigate("network/", { replace: true })
    else if (venuePermissions?.venue == null ? orgPermissions?.manageVenue?.view : venuePermissions.venue > NONE)
      navigate("settings/", { replace: true })
  }, [orgPermissions, navigate, venuePermissions])
  return <>Redirecting...</>
}

const SingleVenue = () => {
  const { id } = useParams()
  const activeVenueId = useSelector(store => store.activeVenue.data.venueId);
  const activeVenueStatus = useSelector(store => store.activeVenue.status);
  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
  const orgPermissions = useSelector(store => store.rbac.permissions);
  const rbacVenues = useSelector(store => store.rbac.data.venues);


  const { venueId } = useParams();
  const dispatch = useDispatch();

  const [routes, setRoutes] = useState([]);
  const [venuePermissions, setPermissions] = useState([]);

  useEffect(() => {
    if (rbacVenues && Array.isArray(rbacVenues)) {
      setPermissions(rbacVenues.find(venue => venue.venueId == venueId)?.permissions ?? []);
    }
  }, [rbacVenues, venueId])

  useEffect(() => {
    if (orgPermissions?.manageVenue?.view) {
      setRoutes([
        {
          id: "venues/:id/location/:venueId/",
          path: "/",
          isProtected: true,
          isAuthorized: (venuePermissions?.venue > NONE || (venuePermissions?.venue == null && orgPermissions?.manageVenue?.view)),
          Component: <ReDirector venuePermissions={venuePermissions} />
        },
        {
          id: "venues/:id/location/:venueId/overview",
          path: "/overview/",
          isProtected: true,
          isAuthorized: (venuePermissions?.venue > NONE || (venuePermissions?.venue == null && orgPermissions?.manageVenue?.view && orgPermissions?.manageVenuestats?.view)),
          Component: <NewoverviewWrapper />
        },
        {
          id: "venues/:id/location/:venueId/add",
          path: "/add/",
          isProtected: true,
          isAuthorized: (venuePermissions?.venue == MANAGE || (venuePermissions?.venue == null && orgPermissions?.manageVenue?.view && orgPermissions?.manageVenue?.create)),
          Component: <AddVenue rootVenue={false} />
        },
        {
          id: "venues/:id/location/:venueId/infrastructure",
          path: "/infrastructure/",
          isProtected: true,
          isAuthorized: (venuePermissions?.infra > NONE || (venuePermissions?.infra == null && orgPermissions?.manageInfra?.view)),
          Component: <Infrastructure venuePermissions={venuePermissions} />
        },
        {
          id: "venues/:id/location/:venueId/timeline",
          path: "/timeline/",
          isProtected: true,
          isAuthorized: (venuePermissions?.venue > NONE || (venuePermissions?.venue == null && orgPermissions?.manageVenue?.view && orgPermissions?.manageVenuestats?.view)),
          Component: <Timeline />
        },
        {
          id: "venues/:id/location/:venueId/clients",
          path: "/clients/",
          isProtected: true,
          isAuthorized: (venuePermissions?.device > NONE || (venuePermissions?.device == null && orgPermissions?.manageVenuestats?.view && orgPermissions?.manageDevice?.view)),
          Component: <Devices venuePermissions={venuePermissions} />
        },
        {
          id: "venues/:id/location/:venueId/network",
          path: "/network/",
          isProtected: true,
          isAuthorized: (venuePermissions?.network > NONE || (venuePermissions?.network == null && orgPermissions?.manageVenuestats?.view && orgPermissions?.manageNetwork?.view)),
          Component: <Network venuePermissions={venuePermissions} />
        },
        {
          id: "venues/:id/location/:venueId/proposal",
          path: "/proposal/",
          isProtected: true,
          isAuthorized: (venuePermissions?.quote > NONE || (venuePermissions?.quote == null && orgPermissions?.manageQuote?.view)),
          Component: <Proposals venuePermissions={venuePermissions} />
        },
        {
          id: "venues/:id/location/:venueId/floorPlan/:layoutId",
          path: "/floorPlan/:layoutId/compose",
          isProtected: true,
          isAuthorized: true,
          Component: <LayoutEditor />
        },
        {
          id: "venues/:id/location/:venueId/floorPlan/:layoutId",
          path: "/floorPlan/:layoutId/compose",
          isProtected: true,
          isAuthorized: true,
          Component: <LayoutEditor />
        },
        {
          id: "venues/:id/location/:venueId/floorPlan",
          path: "/floorPlan/",
          isProtected: true,
          isAuthorized: (venuePermissions?.scan > NONE || (venuePermissions?.scan == null && orgPermissions?.manageVenuestats?.view && orgPermissions?.manageScan?.view)),
          Component: <FloorPlan2wrapper venuePermissions={venuePermissions} />
        },
        {
          id: "venues/:id/location/:venueId/design",
          path: "/design/",
          isProtected: true,
          isAuthorized: (venuePermissions?.scan > NONE || (venuePermissions?.scan == null && orgPermissions?.manageVenuestats?.view && orgPermissions?.manageScan?.view)),
          Component: <DesignLayout venuePermissions={venuePermissions} />
        },
        {
          id: "venues/:id/location/:venueId/padmin",
          path: "/padmin/",
          isProtected: true,
          isAuthorized: orgPermissions?.manageVenue?.view && orgPermissions?.manageFirmware?.create,
          Component: <PAdmin />
        },
        {
          id: "venues/:id/location/:venueId/settings",
          path: "/settings/",
          isProtected: true,
          isAuthorized: (venuePermissions?.venue > NONE || (venuePermissions?.venue == null && orgPermissions?.manageVenue?.view)),
          Component: <Settings venuePermissions={venuePermissions} />
        },
        // {
        //   id: "venues/:id/location/:venueId/permissions",
        //   path: "/permissions/",
        //   isProtected: true,
        //   isAuthorized: venuePermissions?.venue == null ? orgPermissions?.manageVenue?.create : venuePermissions?.venue == MANAGE,
        //   Component: <Permissions />
        // },
        {
          id: "venues/:id/location/:venueId/admin/:identityId",
          path: "/admin/:identityId",
          isProtected: true,
          isAuthorized: venuePermissions?.venue == null ? orgPermissions?.manageVenue?.create : venuePermissions?.venue == MANAGE,
          Component: <IdentityPermissions />
        },
        {
          id: "venues/:id/location/:venueId/admin",
          path: "/admin",
          isProtected: true,
          isAuthorized: (venuePermissions?.venue > NONE || (venuePermissions?.venue == null && orgPermissions?.manageVenue?.view)),
          Component: <VenueAdmin />
        }
      ])
    }
    else {
      setRoutes([
        {
          id: "venueUnauthorized",
          path: "/",
          isProtected: true,
          isAuthorized: false,
          Component: <></>
        }
      ])
    }
  }, [orgPermissions, venuePermissions])


  useEffect(() => {
    dispatch(activeVenueActions.setError(null));
    if (venueId && activeVenueId !== Number(venueId)) {
      const controller = REDUX_WORKER.getActiveVenue(Number(venueId), id);
      dispatch(activeVenueActions.resetInfra());

      return () => {
        controller.abort();
      };
    }
  }, [venueId, activeVenueId, activeOrgId, dispatch, id]);

  if (activeVenueStatus.loading) {
    return <div className="d-flex align-items-center justify-content-center" style={{ height: "50vh" }}>
      <VeryFancyLoader />
    </div>;
  }

  if (activeVenueStatus.error) {
    make_custom_toast('error', 'Venue', activeVenueStatus.error)
    store.dispatch(activeVenueActions.setError(null))

  }

  return (
    <VenueContext.Provider value={{ venueInfo: { permissions: venuePermissions } }}>
      <RoutesMaker routes={routes} />
    </VenueContext.Provider>
  );
};

SingleVenue.propTypes = {};

SingleVenue.defaultProps = {};

export default SingleVenue;
