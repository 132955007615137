import PropTypes from "prop-types";
import { Layer } from "react-konva";
import { EditableLayout } from "../../utils";
import { useEffect } from "react";
import RealAccessPoint from "./RealAccessPoint";

/**
 * Access Points Layer
 * @param {{
 *  scaleFactor: number,
 *  editableLayout: EditableLayout
 *  selectedAp: string,
 *  setSelectedAp: (uuid: string) => void
 * }} props 
 * @returns 
 */
const RealAccessPoints = (props) => {


    return (
        <Layer>
            {Object.keys(props.editableLayout.realInfraPositions).map(uuid => {
                if (props.editableLayout.realInfraPositions[uuid].infraCategory == 1)
                    return (
                        <RealAccessPoint
                            key={uuid}
                            scaleFactor={props.scaleFactor}
                            selectedAp={props.selectedAp}
                            setSelectedAp={props.setSelectedAp}
                            data={props.editableLayout.realInfraPositions[uuid]}
                            isSelected={props.selectedAp === uuid}
                            uuid={uuid}
                            moveAp={props.moveAp}
                            translateAp={props.translateAp}
                            stageReference={props.stageReference}
                            onClick={() => {
                                props.setSelectedSwitch(null)
                                if (props.moveAp && props.selectedAp !== uuid) {
                                    props.setSelectedAp(uuid)
                                }
                            }}
                            layout={props.layout}
                        />
                    );
            })}
        </Layer>
    );
};

RealAccessPoints.propTypes = {};
RealAccessPoints.defaultProps = {};

export default RealAccessPoints;