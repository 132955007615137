import PropTypes from "prop-types";
import { motion } from "framer-motion";
import { ReactComponent as MoveIcon } from "../../../Icons/MoveIcon.svg";

import APImage from "../../../../../../assets/images/icons/APColored.svg";
import SwitchImage from "../../../../../../assets/images/icons/SwitchColored.svg"

import "./SwitchTypeItem.scss";

/**
 * Access Point Type List Item
 * @param {{
 *  data: {
 *    infraTypeId: number,
 *    infraType: string,
 *    tipDeviceType: string,
 *    lanPort: number,
 *    ports: {
 *      LAN: Array<{
 *        portNumber: number,
 *        InterfaceName: string,
 *        Speed: number,
 *        InterfaceType: string
 *      }>,
 *      Uplink: Array<{
 *        portNumber: number,
 *        InterfaceName: string,
 *        Speed: number,
 *        InterfaceType: string
 *      }>,
 *      Console: Array<{
 *        portNumber: number,
 *        InterfaceName: string,
 *        Speed: number,
 *        InterfaceType: string
 *      }>
 *    },
 *    infraCategory: 1 | 2,
 *    images: Array<string>,
 *    bands: Array<string>,
 *    uplinkPort: number,
 *    usbPort: number,
 *    type: string
 *  },
 *  draggedItem: any
 * }} props 
 */
const SwitchTypeItem = (props) => {
    return (<>
        <motion.div
            draggable="true"
            onDragStart={e => {
                props.draggedItem.current = props.data.infraTypeId
            }}
            onDragStartCapture={(e) => {
                let flyImage = document.getElementById('ap-fly-image')
                e.dataTransfer.setDragImage(flyImage, 0, 0)
            }}

            initial={{ bottom: -10, opacity: 0 }}
            animate={{ bottom: 0, opacity: 1 }}
            transition={{ duration: 0.2, delay: 0.15 }}
            className="SwitchTypeItem text-primary bg-white rounded p-1 my-50 d-flex align-items-center  shadow-sm">
            <div className="d-flex align-items-center ">
                <img draggable={false} src={SwitchImage} alt="SWITCH" height="20px" width="20px" />
                <span title={props?.data?.infraType} className="infra-type-name pl-25">{props.data.infraType}</span>
            </div>
        </motion.div>
    </>
    );
};

SwitchTypeItem.propTypes = {
    data: PropTypes.object,
    draggedItem: PropTypes.any
};
SwitchTypeItem.defaultProps = {};

export default SwitchTypeItem;