import { useEffect, useRef, useState } from 'react';
import { Button, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import {
  makeStringFromArray,
  validateEmail,
} from '../../../../../utility/Utils';
import './EmailShareModal.scss';
import createRequest, { services } from '../../../../../services';

const EmailShareModal = (props) => {
  const { visible, setVisible, quoteId } = props;
  const inputRef = useRef(null);
  const initiatedFirst = useRef(true);
  const [emailAdded, setEmailAdded] = useState(null);
  const [currentValue, setCurrentValue] = useState('');
  const [recipients, setRecipients] = useState('');
  const [noCustomEmailAdded, setNoCustomEmailAdded] = useState(false);
  const [emailSending, setEmailSending] = useState(false);
  const [chipList, setChipList] = useState(
    recipients.length > 0 ? recipients.split(',') : []
  );

  useEffect(() => {
    inputRef?.current?.focus();
    setNoCustomEmailAdded(false);
  }, [currentValue, visible]);

  const sendEmail = () => {
    let tempList = recipients.length > 0 ? recipients.split(',') : [];
    let newRecipients = recipients;
    if (validateEmail(currentValue)) {
      tempList.push(currentValue);
      setChipList(tempList);
      newRecipients = makeStringFromArray(tempList);
      setRecipients(makeStringFromArray(tempList));
      setCurrentValue('');
    }
    if (currentValue.length > 1 && !validateEmail(currentValue)) {
      setNoCustomEmailAdded(true);
      return;
    } else if (recipients.length < 1 && !validateEmail(currentValue)) {
      setNoCustomEmailAdded(true);
      return;
    }
    setEmailSending(true);
    const { run } = createRequest(services.marketplace.SEND_EMAIL, [
      quoteId,
      newRecipients,
    ]);

    run()
      .then((res) => {
        setChipList([]);
        setRecipients('');
        setEmailSending(false);
        setVisible(false);
      })
      .catch((err) => {
        setEmailSending(false);
      });
  };

  const ChipInputBox = () => {
    return (
      <div className='EmailShareModal'>
        <div>
          Enter Emails (comma separated)<span className='text-danger'>*</span>
        </div>
        <div
          style={{
            border: '1px solid #cccccc',
            borderColor: noCustomEmailAdded ? 'red' : '#cccccc',
            marginTop: '7px',
            borderRadius: '5px',
            height: '140px',
            overflow: 'auto',
          }}
          onClick={() => {
            setNoCustomEmailAdded(false);
            if (inputRef.current) {
              inputRef?.current?.focus();
            }
          }}
        >
          <div
            className={`ticket-email-box ${
              noCustomEmailAdded ? 'red-border' : ''
            } `}
            onClick={() => {
              setNoCustomEmailAdded(false);
              if (inputRef.current) {
                inputRef?.current?.focus();
              }
            }}
          >
            {chipList.map((chip, index) => {
              return (
                <span className='chip-design d-flex align-items-center'>
                  <span>{chip}</span>
                  <span
                    class={'material-symbols-outlined ml-50 cursor-pointer'}
                    onClick={() => {
                      // if (orgPermissions?.manageOrganization?.update) {
                      let tempList = [...chipList];
                      tempList.splice(index, 1);
                      setChipList(tempList);
                      setRecipients(makeStringFromArray(tempList));
                      // }
                    }}
                  >
                    close
                  </span>
                </span>
              );
            })}
            <input
              type='text'
              ref={inputRef}
              id='chip-input-id'
              className={`chip-input ${
                chipList.length > 0 ? 'margin-bottom-input' : ''
              }`}
              value={currentValue}
              onKeyDown={(e) => {
                if (
                  e.code == 'Space' ||
                  e.code == 'Enter' ||
                  e.code == 'Comma'
                ) {
                  e.preventDefault();

                  let tempList = [...chipList];
                  if (validateEmail(currentValue)) {
                    if (
                      tempList.length > 0 &&
                      !tempList.includes(currentValue.toLowerCase())
                    ) {
                      tempList.push(currentValue.toLowerCase());
                      setChipList(tempList);
                    } else if (tempList.length == 0) {
                      tempList.push(currentValue.toLowerCase());
                      setChipList(tempList);
                    }
                    setRecipients(makeStringFromArray(tempList));
                    setCurrentValue('');
                  }
                }
              }}
              onChange={(e) => {
                setNoCustomEmailAdded(false);
                setCurrentValue(e.target.value);
              }}
            />
          </div>
        </div>

        {noCustomEmailAdded && (
          <span className='text-danger'>
            {currentValue.length > 0 ? 'Invalid Email' : 'Required'}
          </span>
        )}
      </div>
    );
  };

  return (
    <Modal
      centered
      isOpen={visible}
      toggle={() => setVisible(false)}
      style={{
        width: '100%',
        maxWidth: '60em',
        height: '100%',
        maxHeight: '70vh',
      }}
    >
      <ModalHeader className='bg-white pb-0'>
        <div style={{ fontWeight: 600, fontSize: '16px' }}>Share Proposal</div>
      </ModalHeader>
      <ModalBody>
        <ChipInputBox />
        <div className='d-flex align-items-center justify-content-end mt-1'>
          <Button
            className='mr-1'
            outline
            color='primary'
            onClick={() => setVisible(false)}
          >
            Cancel
          </Button>
          <Button color='primary' onClick={sendEmail}>
            {emailSending ? <Spinner size={'sm'} /> : 'Send Email'}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default EmailShareModal;
