import PropTypes from "prop-types";
import { Layer } from "react-konva";
import RealSwitch from "./RealSwitch";

/**
 * Access Points Layer
 * @param {{
 *  scaleFactor: number,
 *  editableLayout: EditableLayout
 *  selectedAp: string,
 *  setSelectedAp: (uuid: string) => void
 * }} props 
 * @returns 
 */
const RealSwitches = (props) => {
    return (
        <Layer>
            {Object.keys(props.editableLayout.realInfraPositions).map(uuid => {
                if (props.editableLayout.realInfraPositions[uuid].infraCategory == 2)
                    return (
                        <RealSwitch
                            key={uuid}
                            scaleFactor={props.scaleFactor}
                            selectedSwitch={props.selectedSwitch}
                            setSelectedSwitch={props.setSelectedSwitch}
                            setSelectedAp={props.setSelectedAp}
                            data={props.editableLayout.realInfraPositions[uuid]}
                            isSelected={props.selectedSwitch === uuid}
                            uuid={uuid}
                            moveSwitch={props.moveSwitch}
                            translateSwitch={props.translateSwitch}
                            stageReference={props.stageReference}
                            onClick={() => {
                                props.setSelectedAp(null)
                                if (props.moveSwitch && props.selectedSwitch !== uuid) {
                                    props.setSelectedSwitch(uuid)
                                }
                            }}
                        />
                    );
            })}
        </Layer>
    );
};

RealSwitches.propTypes = {};
RealSwitches.defaultProps = {};

export default RealSwitches;