import { Group, Image, Rect, Text } from "react-konva";
import useImage from "use-image";
// import Infra from "./infraIcon.svg";
// import InfraSelected from "./infraIconSelected.svg";
import { useSelector } from "react-redux";
import { Point } from "../../utils";
import { COLORS } from "../../constants";

function truncate(val, n) {
    if (val.length > n) {
        return val.slice(0, n);
    }
    return val;
}

/**
 * Access Point
 * @param {{
 *  data: { infra_type_id: number, x: number, y: number },
 *  isSelected: boolean,
 *  scaleFactor: number,
 *  onClick: () => void
 * }} props 
 */
const RealSwitch = (props) => {
    const infraTypes = useSelector(store => store.infraTypes.data);
    return (
        <Group
            x={props.data.location.x * props.scaleFactor}
            y={props.data.location.y * props.scaleFactor}
            draggable={props.moveSwitch && props.uuid == props.selectedSwitch}
            onDragEnd={
                (e) => {
                    const newPosition = new Point(
                        e.target.x() / props.scaleFactor,
                        e.target.y() / props.scaleFactor
                    );
                    props.translateSwitch(props.uuid, newPosition);
                }
            }
            onClick={props.onClick}
            onMouseEnter={(e) => {
                const container = e.target.getStage().container();
                if (props.moveSwitch)
                    container.style.cursor = "pointer";
            }}
            onMouseLeave={(e) => {
                const container = e.target.getStage().container();
                container.style.cursor = "default";
            }}

        >

            <Rect
                x={0}
                y={0}
                width={15}
                height={15}
                offsetX={4}
                offsetY={0}
                strokeWidth={1}
                opacity={!props.moveSwitch ? 0.35 : 1}
                cornerRadius={10}
                stroke={props.isSelected ? COLORS.PRIMARY : COLORS.AP_COLOR}
                fill={props.isSelected ? COLORS.AP_COLOR : COLORS.AP_COLOR}

                scaleX={1 / props.stageReference.current.scaleX()}
                scaleY={1 / props.stageReference.current.scaleY()}
            />
            {
                props.isSelected &&
                <Text
                    offsetX={-10}
                    offsetY={5}
                    fontSize={12}
                    fill={"#444"}
                    text={truncate(infraTypes.find(item => item.infraTypeId === props.data.infraTypeId)?.infraType ?? "Unknown", 7)}
                    scaleX={1 / props.stageReference.current.scaleX()}
                    scaleY={1 / props.stageReference.current.scaleY()}
                />
            }
        </Group>
        // {/* <Rect height={40} width={40} x={0} y={0} strokeWidth={1} dash={[2, 2]} stroke={props.isSelected ? "white" : "#5279CE"} cornerRadius={2} fill={props.isSelected ? "#5279CE" : "white"} /> */ }
        // {/* <Image image={props.isSelected ? imgrS : imgr} height={26} width={26} offsetX={-7} offsetY={0} /> */ }
    );
};

RealSwitch.propTypes = {};
RealSwitch.defaultProps = {};

export default RealSwitch;