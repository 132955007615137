import { Group, Image, Rect, Text } from "react-konva";
import useImage from "use-image";
// import Infra from "./infraIcon.svg";
import Infra from "../../../../../assets/images/icons/APColored.svg";
// import InfraSelected from "./infraIconSelected.svg";
import InfraSelected from "../../../../../assets/images/icons/APColoredSelected.svg";
import { useSelector } from "react-redux";
import { Point } from "../../utils";
import { COLORS } from "../../constants";
import { Html } from "react-konva-utils";
import { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import { getInfraByVenue } from "../../../../../redux/workers/activevenue.worker";
import createRequest, { services } from "../../../../../services";
import { make_custom_toast } from "../../../../../helpers/toasts";
import { CatchedWebError } from "../../../../../configs";

const PLANNED_DOT = require('../../../../../assets/images/drag/ap-fly.png')
const INSTALLED_DOT = require('../../../../../assets/images/drag/ap-installed-dot.png')

function truncate(val, n) {
    if (val.length > n) {
        return val.slice(0, n);
    }
    return val;
}

/**
 * Access Point
 * @param {{
 *  data: { infra_type_id: number, x: number, y: number },
 *  isSelected: boolean,
 *  scaleFactor: number,
 *  onClick: () => void
 * }} props 
 */
const RealAccessPoint = (props) => {
    const infraTypes = useSelector(store => store.infraTypes.data);
    const activeVenue = useSelector(store => store.activeVenue.data);

    const floorplanId = props?.layout?.id || 0
    const [imgr] = useImage(Infra);
    const [imgrS] = useImage(InfraSelected);

    const [loading, setLoading] = useState(false)
    const [convertingModal, setConvertingModal] = useState(false)
    const [infraList, setInfraList] = useState([])
    // const [selectedInfra, setSelectedInfra] = useState(null)
    const [openInfraModal, setOpenInfraModal] = useState(false)



    useEffect(() => {
        if (openInfraModal == true) {
            getInfraListForVenue(true)
        }
    }, [openInfraModal])

    const getInfraListForVenue = (loading = false) => {
        setLoading(true)
        const { run } = createRequest(services.telemetry.GET_VENUE_INFRA_FLAT_LIST, [activeVenue.venueId, 0, 100])
        run()
            .then(res => {
                setLoading(false)
                if (res.data.length > 0)
                    setInfraList(res.data)
            })
            .catch(err => {
                setLoading(false)

            })
    }

    const InfraListModal = () => {

        const convertToInstalled = (selectedInfra) => {
            setConvertingModal(true)

            const convertPayload = {
                planId: props.data.id,
                venueId: activeVenue.venueId,
                infraItemId: selectedInfra.infraItemId,
                macAddress: selectedInfra.macAddress,
                infraDisplayName: selectedInfra.infraName,
                infraTypeId: selectedInfra.infraTypeId,
                location: props.data.location
            }

            const { run } = createRequest(services.wre.CONVERT_PLANNED_PIN, [floorplanId], convertPayload)
            run()
                .then(res => {
                    setConvertingModal(false)
                })
                .catch(err => {
                    setConvertingModal(false)
                    make_custom_toast('error', 'Floorplan', (new CatchedWebError(err)).message)

                })

        }

        return (
            <Modal centered isOpen={openInfraModal} >
                <ModalHeader toggle={() => setOpenInfraModal(false)}>
                    Select Infrastructure
                </ModalHeader>
                <ModalBody>
                    <div>
                        {loading ? <div><Spinner color="primary" /></div> :
                            infraList.length > 0 ?
                                <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                                    {infraList.map((infra, index) =>
                                        <div className="d-flex align-items-center justify-content-between py-50">
                                            <div className="">
                                                <div>
                                                    {infra.infraName}
                                                </div>
                                                <div>
                                                    {infra.macAddress}
                                                </div>
                                                <div>
                                                    {infra.infraCategory == 1 ? "AP" : "Switch"}
                                                </div>
                                                <div>
                                                    {truncate(infraTypes.find(item => item.infraTypeId === infra.infraTypeId)?.infraType ?? "Unknown", 17)}
                                                </div>
                                            </div>

                                            <div>
                                                <Button color="primary"
                                                    onClick={() => {
                                                        convertToInstalled(infra)
                                                        setOpenInfraModal(false)
                                                    }}>
                                                    Select
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                : <div className="d-flex align-items-center justify-content-center">
                                    No Infrastructure Available
                                </div>}
                    </div>
                </ModalBody>
            </Modal>
        )
    }



    return (

        <Group
            x={props.data.location.x * props.scaleFactor}
            y={props.data.location.y * props.scaleFactor}
            draggable={props.moveAp && props.uuid == props.selectedAp}
            onDragEnd={
                (e) => {
                    const newPosition = new Point(
                        e.target.x() / props.scaleFactor,
                        e.target.y() / props.scaleFactor
                    );
                    props.translateAp(props.uuid, newPosition, true);
                }
            }
            onClick={props.onClick}
            onMouseEnter={(e) => {
                const container = e.target.getStage().container();
                if (props.moveAp)
                    container.style.cursor = "pointer";
            }}
            onMouseLeave={(e) => {
                const container = e.target.getStage().container();
                container.style.cursor = "default";
            }}

        >

            <Rect
                x={0}
                y={0}
                width={15}
                height={15}
                offsetX={4}
                offsetY={0}
                strokeWidth={1}
                cornerRadius={10}
                opacity={!props.moveAp ? 0.35 : 1}
                stroke={props.isSelected ? COLORS.PRIMARY : COLORS.AP_INSTALLED}
                fill={COLORS.AP_INSTALLED}

                scaleX={1 / props.stageReference.current.scaleX()}
                scaleY={1 / props.stageReference.current.scaleY()}
            />
            {
                props.isSelected &&
                // <Text
                //   offsetX={-10}
                //   offsetY={5}
                //   fontSize={12}
                //   fill={"#444"}
                //   text={truncate(infraTypes.find(item => item.infraTypeId === props.data.infraTypeId)?.infraType ?? "Unknown", 7)}
                //   scaleX={1 / props.stageReference.current.scaleX()}
                //   scaleY={1 / props.stageReference.current.scaleY()}
                // />
                <Html
                    divProps={{ style: {} }}
                    transformFunc={(attrs) => {
                        const newAttrs = { ...attrs }
                        newAttrs.scaleX = 2
                        newAttrs.scaleY = 2
                        return newAttrs
                    }}

                >
                    <div
                        onWheel={e => {

                        }}


                        style={{
                            zoom: 'reset',
                            width: '100px',
                            minHeight: '30px',
                            top: '0.8rem',
                            position: 'absolute',
                            padding: '0.2em 0.5em',
                            backgroundColor: 'white',
                            border: '1px solid #d4d4d4',
                            borderRadius: '3px'
                        }}
                    >

                        <div className="" style={{ fontSize: '0.5em' }}>
                            <div style={{ marginBottom: '4px' }}>
                                <img style={{ marginRight: '4px' }} src={INSTALLED_DOT} height="8px" width="8px" />
                                <span>{"Installed"} Position</span>
                            </div>

                            <div>
                                <div style={{ fontSize: '5px' }}>Model</div>
                                <span>
                                    {truncate(infraTypes.find(item => item.infraTypeId === props.data.infraTypeId)?.infraType ?? "Unknown", 14)}
                                </span>
                            </div>
                            {/* <div className="d-flex align-items-center justify-content-end">
                                {
                                    props.data.id &&
                                    <div className="add-infra-button" onClick={() => { setOpenInfraModal(true) }} >Add Infrastructure</div>
                                }
                            </div> */}
                        </div>
                    </div>
                    <InfraListModal />
                    {
                        convertingModal &&
                        <Modal
                            centered
                            isOpen={convertingModal} >
                            <ModalBody>
                                <div className="d-flex align-items-center justify-content-center">
                                    <span>
                                        Converting...
                                    </span>
                                    <Spinner color="primary" size={'sm'} />
                                </div>
                            </ModalBody>
                        </Modal>
                    }
                </Html>
            }
        </Group>
        // {/* <Rect height={40} width={40} x={0} y={0} strokeWidth={1} dash={[2, 2]} stroke={props.isSelected ? "white" : "#5279CE"} cornerRadius={2} fill={props.isSelected ? "#5279CE" : "white"} /> */ }
        // {/* <Image image={props.isSelected ? imgrS : imgr} height={26} width={26} offsetX={-7} offsetY={0} /> */ }
    );
};

RealAccessPoint.propTypes = {};
RealAccessPoint.defaultProps = {};

export default RealAccessPoint;