import { Formik } from 'formik';
import { Modal, ModalBody, Button, Form, ModalHeader } from 'reactstrap';
import * as Yup from 'yup';
import { TextInput } from '../../../../components';

const DeclineQuoteModal = (props) => {
  const { visible, setVisible, declineQuote } = props;
  return (
    <Modal centered isOpen={visible}>
      <ModalHeader
        className='bg-white'
        toggle={() => {
          setVisible(false);
        }}
      >
        <h4>Close Proposal</h4>
      </ModalHeader>
      <ModalBody>
        <Formik
          initialValues={{
            reason: '',
          }}
          validationSchema={Yup.object({
            reason: Yup.string()
              .min(5, 'Minimum 5 characters are required')
              .max(500, 'Maximum 500 characters are allowed')
              .required('Required'),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setVisible(false);
            declineQuote(values.reason);
          }}
        >
          {({ isSubmitting, setFieldValue, handleSubmit }) => (
            <Form
              onSubmit={(event) => {
                event.preventDefault();
                handleSubmit();
              }}
            >
              <TextInput
                label='Reason to decline'
                type='textarea'
                rows={8}
                name='reason'
                isrequired
              />
              {/* --- Button Row --- */}
              <div className='d-flex flex-row justify-content-end'>
                <Button
                  color='primary'
                  className='mr-2'
                  size='sm'
                  onClick={() => {
                    setVisible(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  color='danger'
                  outline
                  size='sm'
                  className=''
                  type='submit'
                >
                  Decline Proposal
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default DeclineQuoteModal;
