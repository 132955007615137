import PropTypes from "prop-types";
import { motion } from "framer-motion";
import { ReactComponent as MoveIcon } from "../../../Icons/MoveIcon.svg";
import wifi_6 from "../../../../../../assets/images/marketplace/icons/wifi_6.webp";
import wifi_6e from "../../../../../../assets/images/marketplace/icons/wifi_6e.webp";
import APImage from "../../../../../../assets/images/icons/APColored.svg";

import "./AccessPointTypeItem.scss";

/**
 * Access Point Type List Item
 * @param {{
 *  data: {
 *    infraTypeId: number,
 *    infraType: string,
 *    tipDeviceType: string,
 *    lanPort: number,
 *    ports: {
 *      LAN: Array<{
 *        portNumber: number,
 *        InterfaceName: string,
 *        Speed: number,
 *        InterfaceType: string
 *      }>,
 *      Uplink: Array<{
 *        portNumber: number,
 *        InterfaceName: string,
 *        Speed: number,
 *        InterfaceType: string
 *      }>,
 *      Console: Array<{
 *        portNumber: number,
 *        InterfaceName: string,
 *        Speed: number,
 *        InterfaceType: string
 *      }>
 *    },
 *    infraCategory: 1 | 2,
 *    images: Array<string>,
 *    bands: Array<string>,
 *    uplinkPort: number,
 *    usbPort: number,
 *    type: string
 *  },
 *  draggedItem: any
 * }} props 
 */
const AccessPointTypeItem = (props) => {
  return (<>
    <motion.div
      draggable="true"
      onDragStart={e => {
        props.draggedItem.current = props.data.infraTypeId
      }}
      onDragStartCapture={(e) => {
        let flyImage = document.getElementById('ap-fly-image')
        e.dataTransfer.setDragImage(flyImage, 0, 0)
      }}

      initial={{ bottom: -10, opacity: 0 }}
      animate={{ bottom: 0, opacity: 1 }}
      transition={{ duration: 0.2, delay: 0.15 }}
      className="AccessPointTypeItem text-primary bg-white rounded p-1 my-50 d-flex align-items-center shadow-sm">
      {/* <MoveIcon className="border rounded-circle p-25" height="25px" width="25px" /> */}
      <img draggable={false} src={props?.data?.bands?.includes('6G') ? wifi_6e : wifi_6} alt="WIFI_6" height="20px" width="22px" />
      <div className="d-flex align-items-center ml-50 w-100 pr-1">
        <img draggable={false} src={APImage} alt="AP" height="20px" width="20px" />
        <div title={props?.data?.infraType} className="infra-type-name pl-25">{props.data.infraType}</div>
      </div>
    </motion.div>
  </>
  );
};

AccessPointTypeItem.propTypes = {
  data: PropTypes.object,
  draggedItem: PropTypes.any
};
AccessPointTypeItem.defaultProps = {};

export default AccessPointTypeItem;