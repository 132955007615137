/***
 *
 * Controller class for user.
 * @file Proposals.js
 * @description Proposals component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React, { useEffect } from "react";
// import PropTypes from 'prop-types';
import "./Proposals.scss";
import QuoteList from "../../Marketplace/QuoteList";
import { useDispatch, useSelector } from "react-redux";
import REDUX_WORKER from "../../../redux/workers";
import { breadcrumbActions } from "../../../redux/slices";
import Header from "../_builder/Header";

const Proposals = () => {
  const activeVenue = useSelector(store => store.activeVenue.data);
  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
  const venuecrumb = useSelector(store => store.breadcrumb.venuecrumb);

  const dispatch = useDispatch();


  useEffect(() => {
    if (venuecrumb.venueId !== activeVenue.venueId)
      REDUX_WORKER.getVenueParent(activeVenue.venueId, activeOrgId);

    dispatch(breadcrumbActions.setData([...venuecrumb.parentPath,
    {
      path: `/organization/${activeOrgId}/venues/${activeVenue?.venueId}`,
      text: activeVenue?.venueName,
      active: false,
    },
    {
      text: "Proposal",
      active: true,
    }
    ]))

  }, [activeVenue, venuecrumb]);

  return (
    <div className="Proposals" data-testid="Proposals">
      <Header heading={activeVenue.venueName} />
      <QuoteList venueId={activeVenue.venueId} venue={activeVenue}/>
    </div>
  );
};

Proposals.propTypes = {};

Proposals.defaultProps = {};

export default Proposals;
