import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Input, InputGroup, Spinner } from "reactstrap";
import { SearchIconAddon } from "../../components";
import { CatchedWebError } from "../../configs";
import createRequest, { services } from "../../services";
import { ALERT_HIG, ALERT_LOW, ALERT_MED, AP_PROFILE, BUSINESS, BUSINESS_VIEW, BUS_STATE, CLIENT_EVENT_OPTIONS, CONNECT_FAILURE_REASONS, COUNTRIES, ENTERPRISE, MPSK, MSP_STATE, PASSPHRASE, PUBLIC, RF_PROFILE, SHASTA, THIRD_PARTY_TITLE, VENUE_STATE_MAP } from "../../utility/constants";

import "./filter.scss"


const CategoryFilter = (props) => {
  const [expanded, setExpanded] = useState(true);

  const handleChange = (category) => {
    props.setFilter((prevState) => {
      return { ...prevState, infraCategory: category }
    })
  }

  return (
    <div className="mb-1">
      <div className='d-flex align-items-center' onClick={() => { setExpanded(prevState => !prevState) }}>
        <span className='material-symbols-outlined cursor-pointer'>
          {expanded ? "expand_more" : "chevron_right"}
        </span>
        <span className='oneFilter'>Category</span>
      </div>
      {expanded && <div className="optionsDiv d-flex flex-column justify-content-between rounded">
        <div className='d-flex align-items-center'><input className="cursor-pointer mr-1" type="radio" checked={props?.filter?.infraCategory === "AP"} onClick={() => handleChange("AP")} />
          <span className="optionName">AP</span>
        </div>
        <div className='d-flex align-items-center'><input className="cursor-pointer mr-1" type="radio" checked={props?.filter?.infraCategory === "SWITCH"} onClick={() => handleChange("SWITCH")} />
          <span className="optionName">Switch</span>
        </div>
        <div className='d-flex align-items-center'><input className="cursor-pointer mr-1" type="radio" checked={!props?.filter?.infraCategory || props?.filter?.infraCategory === "ALL"} onClick={() => handleChange("ALL")} />
          <span className="optionName">All</span>
        </div>
      </div>}
    </div>);
}

const ConnectionTypeFilter = (props) => {
  const { connectionType, setConnectionType } = props
  const [expanded, setExpanded] = useState(true);

  const handleChange = (value) => {
    setConnectionType(value)
  }

  return (
    <div className="mb-1">
      <div className='d-flex align-items-center' onClick={() => { setExpanded(prevState => !prevState) }}>
        <span className='material-symbols-outlined cursor-pointer'>
          {expanded ? "expand_more" : "chevron_right"}
        </span>
        <span className='oneFilter'>Source</span>
      </div>
      {expanded && <div className="optionsDiv d-flex flex-column justify-content-between rounded">
        <div className='d-flex align-items-center'><input className="cursor-pointer mr-1" type="radio" checked={connectionType === "wireless"} onClick={() => handleChange("wireless")} />
          <span className="optionName">Wireless</span>
        </div>
        <div className='d-flex align-items-center'><input className="cursor-pointer mr-1" type="radio" checked={connectionType === "wired"} onClick={() => handleChange("wired")} />
          <span className="optionName">Wired</span>
        </div>
        <div className='d-flex align-items-center'><input className="cursor-pointer mr-1" type="radio" checked={connectionType === "all"} onClick={() => handleChange("all")} />
          <span className="optionName">All</span>
        </div>
      </div>}
    </div>);
}

const UpdateStatusFilter = (props) => {
  const [expanded, setExpanded] = useState(true);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setExpanded(!disabled);
  }, [disabled])

  useEffect(() => {
    (props?.filter?.timeOptions?.length > 0) ? setDisabled(true) : setDisabled(false);
  }, [props.filter])

  const handleChange = (status) => {
    if (props?.filter?.status) {
      if (props?.filter?.status?.includes(status)) {
        props?.setFilter((prevState) => {
          return { ...prevState, status: prevState.status.filter(st => st !== status) }
        })
      }
      else {
        props?.setFilter((prevState) => {
          return { ...prevState, status: [...prevState.status, status] }
        })
      }
    }
    else {
      props?.setFilter((prevState) => {
        return { ...prevState, status: [status] }
      })
    }
  }

  return (
    <div className="mb-1">
      <div className='d-flex align-items-center' onClick={() => { !disabled && setExpanded(prevState => !prevState) }}>
        <span className='material-symbols-outlined cursor-pointer'>
          {expanded ? "expand_more" : "chevron_right"}
        </span>
        <span className={disabled ? 'disabledFilter text-secondary' : 'oneFilter'}>Status</span>
      </div>
      {expanded &&
        <div className="optionsDiv d-flex flex-column justify-content-between rounded">
          <div className='d-flex align-items-center'><input className="cursor-pointer mr-1" type="checkbox" checked={props?.filter?.status?.includes("latest")} onChange={() => handleChange("latest")} />
            <span className="optionName">Up to Date</span>
          </div>
          <div className='d-flex align-items-center'><input className="cursor-pointer mr-1" type="checkbox" checked={props?.filter?.status?.includes("ongoing")} onChange={() => handleChange("ongoing")} />
            <span className="optionName">In Progress</span>
          </div>
          {/* <div className='d-flex align-items-center'><input className="cursor-pointer mr-1" type="checkbox" checked={props?.filter?.status?.includes("success")} onChange={() => handleChange("success")} />
            <span className="optionName">Success</span>
          </div> */}
          <div className='d-flex align-items-center'><input className="cursor-pointer mr-1" type="checkbox" checked={props?.filter?.status?.includes("failed")} onChange={() => handleChange("failed")} />
            <span className="optionName">Failed</span>
          </div>
          <div className='d-flex align-items-center'><input className="cursor-pointer mr-1" type="checkbox" checked={props?.filter?.status?.includes("scheduled")} onChange={() => handleChange("scheduled")} />
            <span className="optionName">Scheduled</span>
          </div>
          <div className='d-flex align-items-center'><input className="cursor-pointer mr-1" type="checkbox" checked={props?.filter?.status?.includes("offline")} onChange={() => handleChange("offline")} />
            <span className="optionName">Offline</span>
          </div>
          <div className='d-flex align-items-center'><input className="cursor-pointer mr-1" type="checkbox" checked={props?.filter?.status?.includes("outdated")} onChange={() => handleChange("outdated")} />
            <span className="optionName">Update Available</span>
          </div>
        </div>
      }
    </div>);
}
const TimeFilter = (props) => {
  const [expanded, setExpanded] = useState(true);

  //in case of multiple selections
  // const handleChange = (option) => {
  //   switch(option) {
  //     case 'today':
  //       isPresent('today')?
  //       props.setFilter((prevState)=> {
  //         let timeOptions = prevState.timeOptions??[];
  //         return {...prevState,timeOptions: timeOptions.filter((it)=> it!=='today')}
  //       })
  //       :
  //       props.setFilter((prevState)=> {
  //         let timeOptions = prevState.timeOptions??[];
  //         return {...prevState,timeOptions: [...(timeOptions.filter((it)=> it!=='thisWeek')),'today']}
  //       })
  //     break;
  //     case 'thisWeek':
  //       isPresent('thisWeek')?
  //       props.setFilter((prevState)=> {
  //         let timeOptions = prevState.timeOptions??[];
  //         return {...prevState,timeOptions: timeOptions.filter((it)=> it!=='thisWeek')}
  //       })
  //       :
  //       props.setFilter((prevState)=> {
  //         let timeOptions = prevState.timeOptions??[];
  //         return {...prevState,timeOptions: [...(timeOptions.filter((it)=> it!=='today')),'thisWeek']}
  //       })
  //     break;
  //     default:
  //       isPresent(option)?
  //       props.setFilter((prevState)=> {
  //         let timeOptions = prevState.timeOptions??[];
  //         return {...prevState,timeOptions: timeOptions.filter((it)=> it!==option)}
  //       })
  //       :
  //       props.setFilter((prevState)=> {
  //         let timeOptions = prevState.timeOptions??[];
  //         return {...prevState,timeOptions: [...timeOptions,option]}
  //       })
  //   }
  // };

  const handleChange = (option) => {
    if (option === 'none') props.setFilter((prevState) => { return { ...prevState, timeOptions: [] } })
    else
      props.setFilter((prevState) => { return { ...prevState, timeOptions: [option] } })
  }
  const isPresent = (option) => {
    if (option === 'none' && (!props?.filter?.timeOptions || props?.filter?.timeOptions?.length === 0)) return true;
    let timeOptions = props?.filter?.timeOptions ?? [];
    return timeOptions.includes(option) ? true : false;
  }


  return (
    <div className="mb-1">
      <div className='d-flex align-items-center' onClick={() => { setExpanded(prevState => !prevState) }}>
        <span className='material-symbols-outlined cursor-pointer'>
          {expanded ? "expand_more" : "chevron_right"}
        </span>
        <span className='d-flex justify-content-between align-items-center'>
          <span className='oneFilter'>Time Range</span>
          <span className='note'>&nbsp;(for upcoming updates)</span>
        </span>
      </div>
      {expanded &&
        <div className="optionsDiv d-flex flex-column justify-content-between rounded">
          <div className='d-flex align-items-center'><input className="cursor-pointer mr-1" type="radio" checked={isPresent("today")} onChange={() => handleChange("today")} />
            <span className="optionName">Today</span>
          </div>
          <div className='d-flex align-items-center'><input className="cursor-pointer mr-1" type="radio" checked={isPresent("thisWeek")} onChange={() => handleChange("thisWeek")} />
            <span className="optionName">This Week</span>
          </div>
          <div className='d-flex align-items-center'><input className="cursor-pointer mr-1" type="radio" checked={isPresent("nextWeek")} onChange={() => handleChange("nextWeek")} />
            <span className="optionName">Next Week</span>
          </div>
          <div className='d-flex align-items-center'><input className="cursor-pointer mr-1" type="radio" checked={isPresent(">2Weeks")} onChange={() => handleChange(">2Weeks")} />
            <span className="optionName">More than 2 Weeks</span>
          </div>
          <div className='d-flex align-items-center'><input className="cursor-pointer mr-1" type="radio" checked={isPresent("none")} onChange={() => handleChange("none")} />
            <span className="optionName">None</span>
          </div>
        </div>
      }
    </div>);
}

const ReleaseFilter = (props) => {
  const [expanded, setExpanded] = useState(true);
  const [versions, setVersions] = useState([]);

  useEffect(() => {
    const { run } = createRequest(services.fwupgrade.GET_FWUPGRADE_VERSION);
    run()
      .then(response => {
        if (Array.isArray(response.data)) {
          setVersions(
            response.data?.map((instance, key) => {
              return instance.shastarel_id
            }))
        }
      })
      .catch((error) => {
        // setError((new CatchedWebError(error)).message);
      })
  }, [])


  const handleChange = (version) => {
    if (props?.filter?.release) {
      if (props?.filter?.release?.includes(version)) {
        props?.setFilter((prevState) => {
          return { ...prevState, release: prevState?.release?.filter(ver => ver !== version) }
        })
      }
      else {
        props?.setFilter((prevState) => {
          return { ...prevState, release: [...prevState.release, version] }
        })
      }
    }
    else {
      props?.setFilter((prevState) => {
        return { ...prevState, release: [version] }
      })
    }
  }

  return (
    <div className="mb-1">
      <div className='d-flex align-items-center' onClick={() => { setExpanded(prevState => !prevState) }}>
        <span className='material-symbols-outlined cursor-pointer'>
          {expanded ? "expand_more" : "chevron_right"}
        </span>
        <span className='oneFilter'>Release</span>
      </div>
      {expanded &&
        <div className="overflowAuto optionsDiv d-flex flex-column justify-content-between rounded">
          {versions.map((version, key) =>
            <div key={key} className='d-flex align-items-center'>
              <input className="cursor-pointer mr-1" type="checkbox" checked={props?.filter?.release?.includes(version)} onChange={() => handleChange(version)} />
              <span className="optionName">{version}</span>
            </div>
          )}
        </div>
      }
    </div>);
}

const InfraTypeFilter = (props) => {
  const [expanded, setExpanded] = useState(true);
  const opns = useSelector(store => store.infraTypes.data);

  const handleChange = (infraType) => {
    if (!props.filter?.infraType) {
      props.setFilter((prevState) => {
        return { ...prevState, infraType: [infraType.infraTypeId] }
      })
    }
    else if (props.filter.infraType?.includes(infraType.infraTypeId)) {
      props.setFilter((prevState) => {
        return { ...prevState, infraType: prevState.infraType.filter((it) => it !== infraType.infraTypeId) }
      })
    }
    else {
      props.setFilter((prevState) => {
        return { ...prevState, infraType: [...prevState.infraType, infraType.infraTypeId] }
      })
    }
  }

  return (
    <div className="mb-1">
      <div className='d-flex align-items-center' onClick={() => { setExpanded(prevState => !prevState) }}>
        <span className='material-symbols-outlined cursor-pointer'>
          {expanded ? "expand_more" : "chevron_right"}
        </span>
        <span className='oneFilter'>Infra Type</span>
      </div>
      {expanded && <div className="optionsDiv d-flex flex-column justify-content-between rounded">
        {
          opns.map((opn, key) =>
            <div className='d-flex align-items-center' key={key}>
              <input className="cursor-pointer mr-1" type="checkbox" checked={props?.filter?.infraType?.includes(opn.infraTypeId)} onChange={() => handleChange(opn)} />
              <span className="optionName">{opn.infraType.replace(/_/g, " ")}</span>
            </div>
          )
        }
      </div>}

    </div>);
}

const InstalledFilter = (props) => {
  const [expanded, setExpanded] = useState(true);
  const opns = [{
    label: "Installed",
    value: "installed"
  }, {
    label: "Not Installed",
    value: "not installed"
  }]

  const handleChange = (task) => {
    if (!props.filter?.taskFilter) {
      props.setFilter((prevState) => {
        return { ...prevState, taskFilter: [task] }
      })
    }
    else if (props.filter.taskFilter?.includes(task)) {
      props.setFilter((prevState) => {
        return { ...prevState, taskFilter: prevState.taskFilter.filter((it) => it !== task) }
      })
    }
    else {
      props.setFilter((prevState) => {
        return { ...prevState, taskFilter: [...prevState.taskFilter, task] }
      })
    }
  }

  return (
    <div className="mb-1">
      <div className='d-flex align-items-center' onClick={() => { setExpanded(prevState => !prevState) }}>
        <span className='material-symbols-outlined cursor-pointer'>
          {expanded ? "expand_more" : "chevron_right"}
        </span>
        <span className='oneFilter'>State</span>
      </div>
      {expanded && <div className="optionsDiv d-flex flex-column justify-content-between rounded">
        {
          opns.map((opn, key) =>
            <div className='d-flex align-items-center' key={key}>
              <input className="cursor-pointer mr-1" type="checkbox" checked={props?.filter?.taskFilter?.includes(opn.value)} onChange={() => handleChange(opn.value)} />
              <span className="optionName">{opn.label}</span>
            </div>
          )
        }
      </div>}

    </div>);
}

const FWFilter = (props) => {
  const [expanded, setExpanded] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [versions, setVersions] = useState([]);

  useEffect(() => {
    const { run } = createRequest(services.fwupgrade.GET_FWUPGRADE_VERSION);

    run()
      .then((response) => {
        setVersions(response.data.map(option => option.shastarel_id))
      })
      .catch((err) => {
        setError((new CatchedWebError(err)).message);
      })
      .finally(() => {
        setLoading(false);
      })
  }, [])


  const handleChange = (version) => {
    if (!props.filter?.firmwareVersion) {
      props.setFilter((prevState) => {
        return { ...prevState, firmwareVersion: [version] }
      })
    }
    else if (props.filter.firmwareVersion?.includes(version)) {
      props.setFilter((prevState) => {
        return { ...prevState, firmwareVersion: prevState.firmwareVersion.filter((it) => it !== version) }
      })
    }
    else {
      props.setFilter((prevState) => {
        return { ...prevState, firmwareVersion: [...prevState.firmwareVersion, version] }
      })
    }
  }

  return (
    <div className="mb-1">
      <div className='d-flex align-items-center' onClick={() => { setExpanded(prevState => !prevState) }}>
        <span className='material-symbols-outlined cursor-pointer'>
          {expanded ? "expand_more" : "chevron_right"}
        </span>
        <span className='oneFilter'>Firmwares</span>
      </div>
      {expanded && <div className="optionsDiv d-flex flex-column justify-content-between rounded">
        {
          versions.map((version, key) =>
            <div className='d-flex align-items-center' key={key}>
              <input className="cursor-pointer mr-1" type="checkbox" checked={props?.filter?.firmwareVersion?.includes(version) ? true : false} onClick={() => handleChange(version)} />
              <span className="optionName">{version}</span>
            </div>
          )
        }
      </div>}

    </div>);
}

const OrgFilter = (props) => {
  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
  const [expanded, setExpanded] = useState(true);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [organizations, setOrganizations] = useState([]);

  let timer;

  const debounce = (callback) => {
    clearTimeout(timer);
    timer = setTimeout(callback, 1000);
  }

  useEffect(() => {
    const { run } = createRequest(services.telemetry.GET_ORG_LIST, [activeOrgId, 0, 5, search, "All", , , , , false, "Flat", props.filter?.orgs?.map(it => it.orgId)]);

    run()
      .then((response) => {
        setOrganizations([...response.data]);
      })
      .catch((err) => {
        setError((new CatchedWebError(err)).message);
      })
      .finally(() => {
        setLoading(false);
      })
  }, [activeOrgId, search])


  const handleChange = (org) => {
    if (!props.filter?.orgs) {
      props.setFilter((prevState) => {
        return { ...prevState, orgs: [org] }
      })
    }
    else if (props.filter.orgs?.map(o => o.orgId)?.includes(org.orgId)) {
      props.setFilter((prevState) => {
        return { ...prevState, orgs: prevState.orgs.filter(o => o.orgId != org.orgId) }
      })
    }
    else {
      props.setFilter((prevState) => {
        return { ...prevState, orgs: [...prevState.orgs, org] }
      })
    }
  }

  return (
    <div className="mb-1">
      <div className='d-flex align-items-center' onClick={() => { setExpanded(prevState => !prevState) }}>
        <span className='material-symbols-outlined cursor-pointer'>
          {expanded ? "expand_more" : "chevron_right"}
        </span>
        <span className='oneFilter'>Organization</span>
      </div>
      {expanded && <div className="optionsDiv d-flex flex-column justify-content-between rounded">
        <div className="filter-search-box">
          <InputGroup className="input-group-merge">
            <Input type="text" placeholder="Search" defaultValue={search}
              onChange={
                (e) => {
                  debounce(() => setSearch(e.target.value));
                }
              } />
            <SearchIconAddon />
          </InputGroup>
        </div>
        {
          organizations.map((org, key) =>
            <div className='d-flex align-items-center' key={key}>
              <input className="cursor-pointer mr-1" type="checkbox"
                checked={props?.filter?.orgs?.map(o => o.orgId)?.includes(org.orgId) ? true : false}
                onChange={() => handleChange(org)} />
              <span className="optionName">{org.orgName}</span>
            </div>
          )
        }
        {organizations.length > 4 && <div className="ml-2">...more</div>}
      </div>}

    </div>);
}

//for all infras in all orgs
const CumulativeInfrasFilter = (props) => {
  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
  const [expanded, setExpanded] = useState(true);
  // const [search, setSearch] = useState(props?.filter?.infras?.length === 1?props?.filter?.infras[0].venueName:'');
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [infras, setInfras] = useState([]);
  const view = useSelector(store => store.identity.meta.view);

  let timer;

  const debounce = (callback) => {
    clearTimeout(timer);
    timer = setTimeout(callback, 1000);
  }

  useEffect(() => {
    // (orgId, offset, limit, search, status, sort, filter, count = false, children = false, orderInfras)
    const { controller, run } = createRequest(services.telemetry.GET_INFRA_HIERARCHY_LIST,
      [
        activeOrgId,
        0,
        5,
        [],
        view,
        'All',
        null,
        search,
        {},
        "Flat",
        false,
        props?.filter?.infras?.length > 0
          ? props?.filter?.infras.map(item => item.infraItemId)
          : null
      ])
    run()
      .then((response) => {
        setInfras([...response.data]);
      })
      .catch((err) => {
        setError((new CatchedWebError(err)).message);
      })
      .finally(() => {
        setLoading(false);
      })
  }, [activeOrgId, search, view])

  const handleChange = (infra) => {
    if (!props.filter?.infras) {
      props.setFilter((prevState) => {
        return { ...prevState, infras: [{ infraItemId: infra.infraItemId, infraName: infra.infraName }] }
      })
    }
    else if (props.filter.infras?.map(item => Number(item.infraItemId))?.filter(item => !!item)?.includes(infra.infraItemId)) {
      props.setFilter((prevState) => {
        return { ...prevState, infras: prevState.infras.filter((it) => it.infraItemId != infra.infraItemId) }
      })
    }
    else {
      props.setFilter((prevState) => {
        return { ...prevState, infras: [...prevState.infras, { infraItemId: infra.infraItemId, infraName: infra.infraName }] }
      })
    }
  }

  if (loading) {
    return (<div className="text-center">
      <Spinner size="sm" color="primary" />
    </div>)
  }

  return (
    <div className="mb-1">
      <div
        className="d-flex align-items-center"
        onClick={() => {
          setExpanded((prevState) => !prevState);
        }}
      >
        <span className="material-symbols-outlined cursor-pointer">
          {expanded ? "expand_more" : "chevron_right"}
        </span>
        <span className="oneFilter">Infrastructure</span>
      </div>
      {expanded && (
        <div className="optionsDiv d-flex flex-column justify-content-between rounded">
          {/* Include Sub Venues <Toggle /> */}
          <div className="filter-search-box">
            <InputGroup className="input-group-merge">
              <Input
                type="text"
                placeholder="Search"
                defaultValue={search}
                onChange={(e) => {
                  debounce(() => setSearch(e.target.value));
                }}
              />
              <SearchIconAddon />
            </InputGroup>
          </div>
          {infras.map((infra) => (
            <div className="d-flex align-items-center" key={infra.infraItemId}>
              <input
                className="cursor-pointer mr-1"
                type="checkbox"
                checked={
                  (props?.filter?.infras?.map(item => Number(item.infraItemId)).filter(item => !!item).includes(Number(infra.infraItemId)) ? true : false)
                }
                onClick={() => handleChange(infra)}
              />
              <span className="optionName">{infra.infraName}</span>
            </div>
          ))}
          {infras.length > 4 && <div className="ml-2">...more</div>}
        </div>
      )}
    </div>
  );
}

const AggregateVenueFilter = (props) => {
  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
  const [expanded, setExpanded] = useState(true);
  // const [search, setSearch] = useState(props?.filter?.venues?.length === 1?props?.filter?.venues[0].venueName:'');
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [venues, setVenues] = useState([]);
  const view = useSelector(store => store.identity.meta.view);

  let timer;

  const debounce = (callback) => {
    clearTimeout(timer);
    timer = setTimeout(callback, 1000);
  }

  useEffect(() => {
    const { run } = createRequest(services.telemetry.GET_ORG_VENUE_LIST, [
      activeOrgId,
      0,
      5,
      search,
      'All',
      { orderBy: 'Name', order: 'ASC' },
      'Flat',
      {},
      [],
      BUSINESS_VIEW,
      false,
      props?.filter?.venues?.length > 0
        ? props?.filter?.venues.map(v => v.venueId)
        : null
    ]
    );
    run()
      .then((response) => {
        setVenues([...response.data]);
      })
      .catch((err) => {
        setError((new CatchedWebError(err)).message);
      })
      .finally(() => {
        setLoading(false);
      })
  }, [activeOrgId, search, view])

  const handleChange = (venue) => {
    if (!props.filter?.venues) {
      props.setFilter((prevState) => {
        return { ...prevState, venues: [{ venueId: venue.venueId, venueName: venue.venueName }] }
      })
    }
    else if (props.filter.venues?.map(v => v.venueId)?.includes(venue.venueId)) {
      props.setFilter((prevState) => {
        return { ...prevState, venues: prevState.venues.filter((it) => it.venueId !== venue.venueId) }
      })
    }
    else {
      props.setFilter((prevState) => {
        return { ...prevState, venues: [...prevState.venues, { venueId: venue.venueId, venueName: venue.venueName }] }
      })
    }
  }

  if (loading) {
    return (<div className="text-center">
      <Spinner size="sm" color="primary" />
    </div>)
  }

  return (
    <div className="mb-1">
      <div
        className="d-flex align-items-center"
        onClick={() => {
          setExpanded((prevState) => !prevState);
        }}
      >
        <span className="material-symbols-outlined cursor-pointer">
          {expanded ? "expand_more" : "chevron_right"}
        </span>
        <span className="oneFilter">Venues</span>
      </div>
      {expanded && (
        <div className="optionsDiv d-flex flex-column justify-content-between rounded">
          {/* Include Sub Venues <Toggle /> */}
          <div className="filter-search-box">
            <InputGroup className="input-group-merge">
              <Input
                type="text"
                placeholder="Search"
                defaultValue={search}
                onChange={(e) => {
                  debounce(() => setSearch(e.target.value));
                }}
              />
              <SearchIconAddon />
            </InputGroup>
          </div>
          {venues.map((venue) => (
            <div className="d-flex align-items-center" key={venue.venueId}>
              <input
                className="cursor-pointer mr-1"
                type="checkbox"
                checked={
                  props?.filter?.venues?.map(v => v.venueId).includes(venue.venueId) ? true : false
                }
                onClick={() => handleChange(venue)}
              />
              <span className="optionName">{venue.venueName}</span>
            </div>
          ))}
          {venues.length > 4 && <div className="ml-2">...more</div>}
        </div>
      )}
    </div>
  );
}

const VenueFilter = (props) => {
  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
  const [expanded, setExpanded] = useState(true);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [venues, setVenues] = useState([]);

  let timer;

  const debounce = (callback) => {
    clearTimeout(timer);
    timer = setTimeout(callback, 1000);
  }

  useEffect(() => {
    const { run } = createRequest(services.venue.LIST, [activeOrgId, 0, 5, search]);

    run()
      .then((response) => {
        setVenues([...response.data]);
      })
      .catch((err) => {
        setError((new CatchedWebError(err)).message);
      })
      .finally(() => {
        setLoading(false);
      })
  }, [activeOrgId, search])

  const handleChange = (venueId) => {
    if (!props.filter?.venues) {
      props.setFilter((prevState) => {
        return { ...prevState, venues: [venueId] }
      })
    }
    else if (props.filter.venues?.includes(venueId)) {
      props.setFilter((prevState) => {
        return { ...prevState, venues: prevState.venues.filter((it) => it !== venueId) }
      })
    }
    else {
      props.setFilter((prevState) => {
        return { ...prevState, venues: [...prevState.venues, venueId] }
      })
    }
  }

  if (loading) {
    return (<div className="text-center">
      <Spinner size="sm" color="primary" />
    </div>)
  }

  return (
    <div className="mb-1">
      <div
        className="d-flex align-items-center"
        onClick={() => {
          setExpanded((prevState) => !prevState);
        }}
      >
        <span className="material-symbols-outlined cursor-pointer">
          {expanded ? "expand_more" : "chevron_right"}
        </span>
        <span className="oneFilter">Venues</span>
      </div>
      {expanded && (
        <div className="optionsDiv d-flex flex-column justify-content-between rounded">
          <div className="filter-search-box">
            <InputGroup className="input-group-merge">
              <Input
                type="text"
                placeholder="Search"
                onChange={(e) => {
                  debounce(() => setSearch(e.target.value));
                }}
              />
              <SearchIconAddon />
            </InputGroup>
          </div>
          {venues.map((venue) => (
            <div className="d-flex align-items-center" key={venue.venueId}>
              <input
                className="cursor-pointer mr-1"
                type="checkbox"
                checked={
                  props?.filter?.venues?.includes(venue.venueId) ? true : false
                }
                onClick={() => handleChange(venue.venueId)}
              />
              <span className="optionName">{venue.venueName}</span>
            </div>
          ))}
          {venues.length > 4 && <div className="ml-2">...more</div>}
        </div>
      )}
    </div>
  );
}

const ProfileTypeFilter = (props) => {
  const [expandEnable, setExpandEnable] = useState(true);

  const handleChange = (value) => {
    if (value === AP_PROFILE)
      props?.setFilter((prevState) => { return { ...prevState, profileType: [AP_PROFILE] } })
    else if (value === RF_PROFILE)
      props?.setFilter((prevState) => { return { ...prevState, profileType: [RF_PROFILE] } })
    else props?.setFilter((prevState) => { return { ...prevState, profileType: [] } })
  }

  return (
    <div className="m-1">
      <div className='d-flex align-items-center mb-1' onClick={() => { setExpandEnable(!expandEnable) }}>
        <span className='material-symbols-outlined cursor-pointer'>
          {expandEnable ? "expand_more" : "chevron_right"}
        </span>
        <span className='oneFilter'>Profile Type</span>
      </div>
      {expandEnable &&
        <div className='optionsDiv p-1  d-flex flex-column justify-content-between rounded'>
          <div className='d-flex align-items-center'><input className='mr-1 cursor-pointer' checked={props?.filter?.profileType?.includes(AP_PROFILE)} type="radio" onChange={() => { handleChange(AP_PROFILE) }} /><span className='optionName'>AP Profile</span></div>
          <div className='d-flex align-items-center'><input className='mr-1 cursor-pointer' checked={props?.filter?.profileType?.includes(RF_PROFILE)} type="radio" onChange={() => { handleChange(RF_PROFILE) }} /><span className='optionName'>RF Profile</span></div>
          <div className='d-flex align-items-center'><input className='mr-1 cursor-pointer' checked={props?.filter?.profileType?.length == 0} type="radio" onChange={() => { handleChange(0) }} /><span className='optionName'>All Profiles</span></div>
        </div>
      }
    </div>
  );
};
const FilterEnable = ({ filterData, setFilterData }) => {
  const [expandEnable, setExpandEnable] = useState(true);

  const handleChange = (includeEnabled) => {
    setFilterData((prevState) => { return { ...prevState, isBlocked: includeEnabled } });
  }

  return (
    <div className="FilterEnable m-1" data-testid="FilterEnable">
      <div className='d-flex align-items-center mb-1' onClick={() => { setExpandEnable(!expandEnable) }}>
        <span className='material-symbols-outlined cursor-pointer'>
          {expandEnable ? "expand_more" : "chevron_right"}
        </span>
        <span className='oneFilter'>Status</span>
      </div>
      {expandEnable &&
        <div className='optionsDiv p-1  d-flex flex-column justify-content-between rounded'>
          <div className='d-flex align-items-center'><input className='mr-1 cursor-pointer' checked={filterData?.isBlocked === false} type="radio" onChange={() => { handleChange(false) }} /><span className='optionName'>Active Users</span></div>
          <div className='d-flex align-items-center'><input className='mr-1 cursor-pointer' checked={filterData?.isBlocked === true} type="radio" onChange={() => { handleChange(true) }} /><span className='optionName'>Inactive Users</span></div>
          <div className='d-flex align-items-center'><input className='mr-1 cursor-pointer' checked={filterData?.isBlocked == null} type="radio" onChange={() => { handleChange(null) }} /><span className='optionName'>All Users</span></div>
        </div>
      }
    </div>
  );
};

const FilterRoles = ({ filterData, setFilterData }) => {
  const [expandRoles, setExpandRoles] = useState(true);
  const roles = useSelector(store => store?.rbac?.data?.systemRoles);

  const handleRole = (roleId) => {
    if (!filterData.roles) {
      setFilterData((prevState) => { return { ...prevState, roles: [roleId] } })
    }
    else if (filterData.roles.includes(roleId))
      setFilterData((prevState) => { return { ...prevState, roles: prevState.roles.filter((it) => { return it !== roleId }) } })
    else
      setFilterData((prevState) => { return { ...prevState, roles: [...prevState.roles, roleId] } })
  }
  return (
    <div className="FilterRoles" data-testid="FilterRoles">
      <div className='d-flex align-items-center' onClick={() => { setExpandRoles(!expandRoles) }}>
        <span className='material-symbols-outlined cursor-pointer'>
          {expandRoles ? "expand_more" : "chevron_right"}
        </span>
        <span className='oneFilter'>Roles</span>
      </div>
      {expandRoles &&
        <div className='optionsDiv p-1  d-flex flex-column justify-content-between rounded'>
          {roles?.map((role) => {
            return (
              role?.role &&
              <div className='d-flex align-items-center'>
                <input className='mr-1 cursor-pointer'
                  checked={filterData?.roles?.includes(role?.roleId)} type="checkbox"
                  onChange={() => { handleRole(role?.roleId) }} />
                <span className='optionName'>{role?.role}</span>
              </div>
            )
          })}
        </div>}
    </div>
  );
};

const NetworkTypeFilter = (props) => {
  const [expanded, setExpanded] = useState(true);
  const opns = [
    { value: PUBLIC, label: "Public" },
    { value: PASSPHRASE, label: "Passphrase" },
    { value: MPSK, label: "MPSK AAA" },
    { value: ENTERPRISE, label: "Enterprise AAA" }
  ]

  const handleChange = (typeId) => {
    if (!props.filter?.networkType) {
      props.setFilter((prevState) => {
        return { ...prevState, networkType: [typeId] }
      })
    }
    else if (props.filter.networkType?.includes(typeId)) {
      props.setFilter((prevState) => {
        return { ...prevState, networkType: prevState.networkType.filter((it) => it !== typeId) }
      })
    }
    else {
      props.setFilter((prevState) => {
        return { ...prevState, networkType: [...prevState.networkType, typeId] }
      })
    }
  }

  return (
    <div className="mb-1">
      <div className='d-flex align-items-center' onClick={() => { setExpanded(prevState => !prevState) }}>
        <span className='material-symbols-outlined cursor-pointer'>
          {expanded ? "expand_more" : "chevron_right"}
        </span>
        <span className='oneFilter'>Network Type</span>
      </div>
      {expanded && <div className="optionsDiv d-flex flex-column justify-content-between rounded">
        {
          opns.map((opn) =>
            <div className='d-flex align-items-center'>
              <input className="cursor-pointer mr-1" type="checkbox" checked={props?.filter?.networkType?.includes(opn.value)} onChange={() => handleChange(opn.value)} />
              <span className="optionName">{opn.label}</span>
            </div>
          )
        }
      </div>}

    </div>);
}

const BandFilter = (props) => {
  const [expanded, setExpanded] = useState(true);
  const opns = [
    { value: "2G", label: "2G" },
    { value: "5G", label: "5G" },
    { value: "6G", label: "6G" },
  ]

  const handleChange = (band) => {
    if (!props.filter?.bands) {
      props.setFilter((prevState) => {
        return { ...prevState, bands: [band] }
      })
    }
    else if (props.filter.bands?.includes(band)) {
      props.setFilter((prevState) => {
        return { ...prevState, bands: prevState.bands.filter((it) => it !== band) }
      })
    }
    else {
      props.setFilter((prevState) => {
        return { ...prevState, bands: [...prevState.bands, band] }
      })
    }
  }

  return (
    <div className="mb-1">
      <div className='d-flex align-items-center' onClick={() => { setExpanded(prevState => !prevState) }}>
        <span className='material-symbols-outlined cursor-pointer'>
          {expanded ? "expand_more" : "chevron_right"}
        </span>
        <span className='oneFilter'>Bands</span>
      </div>
      {expanded && <div className="optionsDiv d-flex flex-column justify-content-between rounded">
        {
          opns.map((opn) =>
            <div className='d-flex align-items-center'>
              <input className="cursor-pointer mr-1" type="checkbox" checked={props?.filter?.bands?.includes(opn.value)} onClick={() => handleChange(opn.value)} />
              <span className="optionName">{opn.label}</span>
            </div>
          )
        }
      </div>}

    </div>);
}


const OrgsStatusFilter = (props) => {
  const [expanded, setExpanded] = useState(true);

  const handleChange = (status) => {

    const opns = ["Accepted", "Pending"];

    if (!props.filter?.status) {
      props.setFilter((prevState) => {
        return { ...prevState, status: status }
      })
    }
    else if (props.filter.status === "All") {
      props.setFilter((prevState) => {
        return { ...prevState, status: opns.filter((it) => it !== status)[0] }
      })
    }
    else if (props.filter.status === status) {
      props.setFilter((prevState) => {
        return { ...prevState, status: null }
      })
    }
    else {
      props.setFilter((prevState) => {
        return { ...prevState, status: "All" }
      })
    }
  }

  return (
    <div className="mb-1">
      <div className='d-flex align-items-center' onClick={() => { setExpanded(prevState => !prevState) }}>
        <span className='material-symbols-outlined cursor-pointer'>
          {expanded ? "expand_more" : "chevron_right"}
        </span>
        <span className='oneFilter'>Status</span>
      </div>
      {expanded && <div className="optionsDiv d-flex flex-column justify-content-between rounded">
        <div className='d-flex align-items-center'><input className="cursor-pointer mr-1" type="checkbox" checked={props?.filter?.status === "Accepted" || props?.filter?.status === "All"} onChange={() => handleChange("Accepted")} />
          <span className="optionName">Accepted</span>
        </div>
        <div className='d-flex align-items-center'><input className="cursor-pointer mr-1" type="checkbox" checked={props?.filter?.status === "Pending" || props?.filter?.status === "All"} onChange={() => handleChange("Pending")} />
          <span className="optionName">Pending</span>
        </div>
      </div>}
    </div>);
}

const OrgTypeFilter = (props) => {
  const [expanded, setExpanded] = useState(true);
  const opns = useSelector(store => store.orgtypes.data);

  const handleChange = (orgType) => {
    if (!props.filter?.orgType) {
      props.setFilter((prevState) => {
        return { ...prevState, orgType: [orgType.orgTypeId] }
      })
    }
    else if (props.filter.orgType?.includes(orgType.orgTypeId)) {
      props.setFilter((prevState) => {
        return { ...prevState, orgType: prevState.orgType.filter((it) => it !== orgType.orgTypeId) }
      })
    }
    else {
      props.setFilter((prevState) => {
        return { ...prevState, orgType: [...prevState.orgType, orgType.orgTypeId] }
      })
    }
  }

  return (
    <div className="mb-1">
      <div className='d-flex align-items-center' onClick={() => { setExpanded(prevState => !prevState) }}>
        <span className='material-symbols-outlined cursor-pointer'>
          {expanded ? "expand_more" : "chevron_right"}
        </span>
        <span className='oneFilter'>Type</span>
      </div>
      {expanded && <div className="optionsDiv d-flex flex-column justify-content-between rounded">
        {
          opns.map((opn) =>
            (opn.orgTypeId !== SHASTA) &&
            <div className='d-flex align-items-center'>
              <input className="cursor-pointer mr-1" type="checkbox" checked={props?.filter?.orgType?.includes(opn.orgTypeId)} onChange={() => handleChange(opn)} />
              <span className="optionName">{opn.orgType}</span>
            </div>
          )
        }
      </div>}

    </div>);
}

const NetworkFilter = (props) => {
  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
  const [expanded, setExpanded] = useState(true);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [networks, setNetworks] = useState([]);

  let timer;

  const debounce = (callback) => {
    clearTimeout(timer);
    timer = setTimeout(callback, 1000);
  }
  useEffect(() => {
    if (!expanded)
      setSearch("");
  }, [expanded])


  useEffect(() => {
    const { run } = !!props.venueId ?
      createRequest(services.networks.GET_BY_VENUE, [props.venueId])
      : !!props.infraId ?
        createRequest(services.networks.GET_BY_INFRA, [props.infraId])
        : createRequest(services.networks.GET_NET, [activeOrgId, 0, 5, search])

    run()
      .then((response) => {
        setNetworks([...response.data]);
      })
      .catch((err) => {
        setError((new CatchedWebError(err)).message);
      })
      .finally(() => {
        setLoading(false);
      })
  }, [activeOrgId, search])

  const handleChange = (networkId) => {
    if (!props.filter?.networks) {
      props.setFilter((prevState) => {
        return { ...prevState, networks: [networkId] }
      })
    }
    else if (props.filter.networks?.includes(networkId)) {
      props.setFilter((prevState) => {
        return { ...prevState, networks: prevState.networks.filter((it) => it !== networkId) }
      })
    }
    else {
      props.setFilter((prevState) => {
        return { ...prevState, networks: [...prevState.networks, networkId] }
      })
    }
  }
  if (loading) {
    return (<div className="text-center">
      <Spinner size="sm" color="primary" />
    </div>)
  }

  return (
    <div className="mb-1">
      <div
        className="d-flex align-items-center"
        onClick={() => {
          setExpanded((prevState) => !prevState);
        }}
      >
        <span className="material-symbols-outlined cursor-pointer">
          {expanded ? "expand_more" : "chevron_right"}
        </span>
        <span className="oneFilter">Network</span>
      </div>
      {expanded && (
        <div className="optionsDiv d-flex flex-column justify-content-between rounded">
          {(!props.venueId && !props.infraId) ?
            <div className="filter-search-box">
              <InputGroup className="input-group-merge">
                <Input
                  type="text"
                  placeholder="Search"
                  onChange={(e) => {
                    debounce(() => setSearch(e.target.value));
                  }}
                />
                <SearchIconAddon />
              </InputGroup>
            </div> : null}
          {networks?.length > 0 ? networks.map((network) => (
            <div className="d-flex align-items-center">
              <input
                className="cursor-pointer mr-1"
                type="checkbox"
                checked={props?.filter?.networks?.includes(network.networkName)}
                onClick={() => handleChange(network.networkName)}
              />
              <span className="optionName">{network.networkName}</span>
            </div>
          )) : <div className="d-flex justify-content-center text-font-small-size">No Networks Found</div>}
          {(networks.length > 4 && !props.venueId && !props.infraId) ? <div className="ml-2">...more</div> : null}
        </div>
      )}
    </div>
  );
}

const FailureReasonFilter = (props) => {
  const [expanded, setExpanded] = useState(true);
  const opns = Object.keys(CONNECT_FAILURE_REASONS).map(value => {
    return { label: CONNECT_FAILURE_REASONS[value], value: value }
  })

  const handleChange = (failureReason) => {
    if (!props.filter?.failureReason) {
      props.setFilter((prevState) => {
        return { ...prevState, failureReason: [failureReason] }
      })
    }
    else if (props.filter.failureReason?.includes(failureReason)) {
      props.setFilter((prevState) => {
        return { ...prevState, failureReason: prevState.failureReason.filter((it) => it !== failureReason) }
      })
    }
    else {
      props.setFilter((prevState) => {
        return { ...prevState, failureReason: [...prevState.failureReason, failureReason] }
      })
    }
  }

  return (
    <div className="mb-1">
      <div className='d-flex align-items-center' onClick={() => { setExpanded(prevState => !prevState) }}>
        <span className='material-symbols-outlined cursor-pointer'>
          {expanded ? "expand_more" : "chevron_right"}
        </span>
        <span className='oneFilter'>Failure Reason</span>
      </div>
      {expanded && <div className="optionsDiv d-flex flex-column justify-content-between rounded">
        {
          opns.map((opn) =>
            <div className='d-flex align-items-center'>
              <input className="cursor-pointer mr-1" type="checkbox" checked={props?.filter?.failureReason?.includes(opn.value)} onClick={() => handleChange(opn.value)} />
              <span className="optionName">{opn.label}</span>
            </div>
          )
        }
      </div>}

    </div>);
}

const EventFilter = (props) => {
  const [expanded, setExpanded] = useState(true);
  const opns = CLIENT_EVENT_OPTIONS

  const handleChange = (event) => {
    if (!props.filter?.event) {
      props.setFilter((prevState) => {
        return { ...prevState, event: [event] }
      })
    }
    else if (props.filter.event?.includes(event)) {
      props.setFilter((prevState) => {
        return { ...prevState, event: prevState.event.filter((it) => it !== event) }
      })
    }
    else {
      props.setFilter((prevState) => {
        return { ...prevState, event: [...prevState.event, event] }
      })
    }
  }

  return (
    <div className="mb-1">
      <div className='d-flex align-items-center' onClick={() => { setExpanded(prevState => !prevState) }}>
        <span className='material-symbols-outlined cursor-pointer'>
          {expanded ? "expand_more" : "chevron_right"}
        </span>
        <span className='oneFilter'>Event</span>
      </div>
      {expanded && <div className="optionsDiv d-flex flex-column justify-content-between rounded">
        {
          opns.map((opn) =>
            <div className='d-flex align-items-center'>
              <input className="cursor-pointer mr-1" type="checkbox" checked={props?.filter?.event?.includes(opn.value)} onChange={() => handleChange(opn.value)} />
              <span className="optionName">{opn.label}</span>
            </div>
          )
        }
      </div>}

    </div>);
}
const PriorityFilter = (props) => {
  const [expanded, setExpanded] = useState(true);
  const opns = [
    { value: "1", label: "Minor" },
    { value: "2", label: "Major" },
    { value: "3", label: "Critical" },
  ]

  const handleChange = (priority) => {
    if (!props.filter?.priorities) {
      props.setFilter((prevState) => {
        return { ...prevState, priorities: [priority] }
      })
    }
    else if (props.filter.priorities?.includes(priority)) {
      props.setFilter((prevState) => {
        return { ...prevState, priorities: prevState.priorities.filter((it) => it !== priority) }
      })
    }
    else {
      props.setFilter((prevState) => {
        return { ...prevState, priorities: [...prevState.priorities, priority] }
      })
    }
  }

  return (
    <div className="mb-1">
      <div className='d-flex align-items-center' onClick={() => { setExpanded(prevState => !prevState) }}>
        <span className='material-symbols-outlined cursor-pointer'>
          {expanded ? "expand_more" : "chevron_right"}
        </span>
        <span className='oneFilter'>Severity</span>
      </div>
      {expanded && <div className="optionsDiv d-flex flex-column justify-content-between rounded">
        {
          opns.map((opn) =>
            <div className='d-flex align-items-center'>
              <input className="cursor-pointer mr-1" type="checkbox" checked={props?.filter?.priorities?.includes(opn.value)} onChange={() => handleChange(opn.value)} />
              <span className="optionName">{opn.label}</span>
            </div>
          )
        }
      </div>}

    </div>);
}

const SeverityFilter = (props) => {
  const [expanded, setExpanded] = useState(true);
  const opns = [
    { value: "1", label: "Minor" },
    { value: "2", label: "Major" },
    { value: "3", label: "Critical" },
  ]

  const handleChange = (severity) => {
    if (!props.filter?.severities) {
      props.setFilter((prevState) => {
        return { ...prevState, severities: [severity] }
      })
    }
    else if (props.filter.severities?.includes(severity)) {
      props.setFilter((prevState) => {
        return { ...prevState, severities: prevState.severities.filter((it) => it !== severity) }
      })
    }
    else {
      props.setFilter((prevState) => {
        return { ...prevState, severities: [...prevState.severities, severity] }
      })
    }
  }

  return (
    <div className="mb-1">
      <div className='d-flex align-items-center' onClick={() => { setExpanded(prevState => !prevState) }}>
        <span className='material-symbols-outlined cursor-pointer'>
          {expanded ? "expand_more" : "chevron_right"}
        </span>
        <span className='oneFilter'>Severity</span>
      </div>
      {expanded && <div className="optionsDiv d-flex flex-column justify-content-between rounded">
        {
          opns.map((opn) =>
            <div className='d-flex align-items-center'>
              <input className="cursor-pointer mr-1" type="checkbox" checked={props?.filter?.priorities?.includes(opn.value)} onChange={() => handleChange(opn.value)} />
              <span className="optionName">{opn.label}</span>
            </div>
          )
        }
      </div>}

    </div>);
}
export const TicketCategoryFilter = (props) => {
  const [expanded, setExpanded] = useState(true);
  const categories = [
    { value: "Deployment", label: "Deployment" },
    // { value: "Hardware", label: "Hardware" },
    // { value: "Orders", label: "Orders" },
    { value: "Web UI", label: "Web UI" },
    { value: "Organization", label: "Organization" },
    { value: "Venue", label: "Venue" },
    { value: "Network", label: "Network" },
    { value: "Clients", label: "Clients" },
    { value: "Infrastructure", label: "Infrastructure" },
    { value: "Billing", label: "Billing" },
  ]

  const handleChange = (category) => {
    if (!props.filter?.categories) {
      props.setFilter((prevState) => {
        return { ...prevState, categories: [category] }
      })
    }
    else if (props.filter.categories?.includes(category)) {
      props.setFilter((prevState) => {
        return { ...prevState, categories: prevState.categories.filter((it) => it !== category) }
      })
    }
    else {
      props.setFilter((prevState) => {
        return { ...prevState, categories: [...prevState.categories, category] }
      })
    }
  }

  return (
    <div className="mb-1">
      <div className='d-flex align-items-center' onClick={() => { setExpanded(prevState => !prevState) }}>
        <span className='material-symbols-outlined cursor-pointer'>
          {expanded ? "expand_more" : "chevron_right"}
        </span>
        <span className='oneFilter'>Category</span>
      </div>
      {expanded && <div className="optionsDiv d-flex flex-column justify-content-between rounded">
        {
          categories.map((opn) =>
            <div className='d-flex align-items-center'>
              <input className="cursor-pointer mr-1" type="checkbox" checked={props?.filter?.categories?.includes(opn.value)} onChange={() => handleChange(opn.value)} />
              <span className="optionName">{opn.label}</span>
            </div>
          )
        }
      </div>}

    </div>);
}

export const TicketCategoryFilterNonDeployment = (props) => {
  const [expanded, setExpanded] = useState(true);
  const categories = [
    // { value: "Hardware", label: "Hardware" },
    // { value: "Orders", label: "Orders" },
    { value: "Web UI", label: "Web UI" },
    { value: "Organization", label: "Organization" },
    { value: "Venue", label: "Venue" },
    { value: "Network", label: "Network" },
    { value: "Clients", label: "Clients" },
    { value: "Infrastructure", label: "Infrastructure" },
    { value: "Billing", label: "Billing" },
  ]

  const handleChange = (category) => {
    if (!props.filter?.categories) {
      props.setFilter((prevState) => {
        return { ...prevState, categories: [category] }
      })
    }
    else if (props.filter.categories?.includes(category)) {
      props.setFilter((prevState) => {
        return { ...prevState, categories: prevState.categories.filter((it) => it !== category) }
      })
    }
    else {
      props.setFilter((prevState) => {
        return { ...prevState, categories: [...prevState.categories, category] }
      })
    }
  }

  return (
    <div className="mb-1">
      <div className='d-flex align-items-center' onClick={() => { setExpanded(prevState => !prevState) }}>
        <span className='material-symbols-outlined cursor-pointer'>
          {expanded ? "expand_more" : "chevron_right"}
        </span>
        <span className='oneFilter'>Category</span>
      </div>
      {expanded && <div className="optionsDiv d-flex flex-column justify-content-between rounded">
        {
          categories.map((opn) =>
            <div className='d-flex align-items-center'>
              <input className="cursor-pointer mr-1" type="checkbox" checked={props?.filter?.categories?.includes(opn.value)} onChange={() => handleChange(opn.value)} />
              <span className="optionName">{opn.label}</span>
            </div>
          )
        }
      </div>}

    </div>);
}

export const IdentityRolesFilter = (props) => {
  const [expanded, setExpanded] = useState(true);
  const opns = [
    { value: "1", label: "Minor" },
    { value: "2", label: "Major" },
    { value: "3", label: "Critical" },
  ]

  const handleChange = (priority) => {
    if (!props.filter?.priorities) {
      props.setFilter((prevState) => {
        return { ...prevState, priorities: [priority] }
      })
    }
    else if (props.filter.priorities?.includes(priority)) {
      props.setFilter((prevState) => {
        return { ...prevState, priorities: prevState.priorities.filter((it) => it !== priority) }
      })
    }
    else {
      props.setFilter((prevState) => {
        return { ...prevState, priorities: [...prevState.priorities, priority] }
      })
    }
  }

  return (
    <div className="mb-1">
      <div className='d-flex align-items-center' onClick={() => { setExpanded(prevState => !prevState) }}>
        <span className='material-symbols-outlined cursor-pointer'>
          {expanded ? "expand_more" : "chevron_right"}
        </span>
        <span className='oneFilter'>Severity</span>
      </div>
      {expanded && <div className="optionsDiv d-flex flex-column justify-content-between rounded">
        {
          opns.map((opn) =>
            <div className='d-flex align-items-center'>
              <input className="cursor-pointer mr-1" type="checkbox" checked={props?.filter?.priorities?.includes(opn.value)} onChange={() => handleChange(opn.value)} />
              <span className="optionName">{opn.label}</span>
            </div>
          )
        }
      </div>}

    </div>);
}
const IdentityStatusFilter = (props) => {
  const [expanded, setExpanded] = useState(true);
  const selected = props?.filter?.status

  const handleChange = (status) => {
    props.setFilter((prevState) => {
      return { ...prevState, status: status }
    })
  }

  return (
    <div className="mb-1">
      <div className='d-flex align-items-center' onClick={() => { setExpanded(prevState => !prevState) }}>
        <span className='material-symbols-outlined cursor-pointer'>
          {expanded ? "expand_more" : "chevron_right"}
        </span>
        <span className='oneFilter'>Status</span>
      </div>
      {expanded && <div className="optionsDiv d-flex flex-column justify-content-between rounded">

        <div className='d-flex align-items-center'>
          <input className="cursor-pointer mr-1" type="radio" checked={selected == 'all'} onChange={() => handleChange('all')} />
          <span className="optionName">All</span>
        </div>
        <div className='d-flex align-items-center'>
          <input className="cursor-pointer mr-1" type="radio" checked={selected == 'enabled'} onChange={() => handleChange('enabled')} />
          <span className="optionName">Enabled</span>
        </div>
        <div className='d-flex align-items-center'>
          <input className="cursor-pointer mr-1" type="radio" checked={selected == 'disabled'} onChange={() => handleChange('disabled')} />
          <span className="optionName">Disabled</span>
        </div>

      </div>}

    </div>);
}


const StatusFilter = (props) => {
  const [expanded, setExpanded] = useState(true);
  let opns = [
    { value: "2", label: "Open" },
    { value: "3", label: "Pending" },
    { value: "4", label: "Resolved" },
    { value: "5", label: "Closed" },
    { value: "6", label: "In Progress" },
    { value: "7", label: "Waiting on Customer" },
  ]
  if (props.removeClosedStatus) {
    opns.splice(3, 1)
  }
  const handleChange = (status) => {
    if (!props.filter?.statuses) {
      props.setFilter((prevState) => {
        return { ...prevState, statuses: [status] }
      })
    }
    else if (props.filter.statuses?.includes(status)) {
      props.setFilter((prevState) => {
        return { ...prevState, statuses: prevState.statuses.filter((it) => it !== status) }
      })
    }
    else {
      props.setFilter((prevState) => {
        return { ...prevState, statuses: [...prevState.statuses, status] }
      })
    }
  }

  return (
    <div className="mb-1">
      <div className='d-flex align-items-center' onClick={() => { setExpanded(prevState => !prevState) }}>
        <span className='material-symbols-outlined cursor-pointer'>
          {expanded ? "expand_more" : "chevron_right"}
        </span>
        <span className='oneFilter'>Status</span>
      </div>
      {expanded && <div className="optionsDiv d-flex flex-column justify-content-between rounded">
        {
          opns.map((opn) =>
            <div className='d-flex align-items-center'>
              <input className="cursor-pointer mr-1" type="checkbox" checked={props?.filter?.statuses?.includes(opn.value)} onChange={() => handleChange(opn.value)} />
              <span className="optionName">{opn.label}</span>
            </div>
          )
        }
      </div>}

    </div>);
}
export const TimelineEventFilter = (props) => {
  const [expanded, setExpanded] = useState(true);
  const [opns, setOpns] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const { run } = createRequest(services.telemetry.GET_EVENT_MAP, []);

    run().then(response => {
      let opnsTemp = Object.keys(response.data ?? {})?.map(opn => {
        return { value: response.data[opn]?.alert_codes, label: opn }
      })

      setOpns([...opnsTemp])
    })
      .finally(() => setLoading(false))
  }, [])

  const handleChange = (event) => {
    if (!props.filter?.events) {
      props.setFilter((prevState) => {
        return { ...prevState, events: [...event] }
      })
    }
    else if (event?.some(it => props.filter.events?.includes(it))) {
      props.setFilter((prevState) => {
        return { ...prevState, events: prevState.events.filter(it => !event.includes(it)) }
      })
    }
    else {
      props.setFilter((prevState) => {
        return { ...prevState, events: [...prevState.events, ...event] }
      })
    }
  }
  if (loading) {
    return (<div className="text-center">
      <Spinner size="sm" color="primary" />
    </div>)
  }

  return (
    <div className="mb-1">
      <div className='d-flex align-items-center' onClick={() => { setExpanded(prevState => !prevState) }}>
        <span className='material-symbols-outlined cursor-pointer'>
          {expanded ? "expand_more" : "chevron_right"}
        </span>
        <span className='oneFilter'>Event</span>
      </div>
      {expanded && <div className="optionsDiv d-flex flex-column justify-content-between rounded">
        {
          opns.map((opn) =>
            <div className='d-flex align-items-center'>
              <input className="cursor-pointer mr-1" type="checkbox" checked={(opn?.value?.some(it => props.filter.events?.includes(it)))} onChange={() => handleChange(opn.value)} />
              <span className="optionName">{opn.label}</span>
            </div>
          )
        }
      </div>}

    </div>);
}

const InfraFilter = (props) => {
  const { filter, setFilter, venueId } = props
  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
  const [expanded, setExpanded] = useState(true);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [infra, setInfra] = useState([]);

  let timer;

  const debounce = (callback) => {
    clearTimeout(timer);
    timer = setTimeout(callback, 1000);
  }
  useEffect(() => {
    if (!expanded)
      setSearch("");
  }, [expanded])


  useEffect(() => {
    const { run } =
      !!venueId
        ? createRequest(services.infra.GET_BY_VENUE, [
          venueId,
          0,
          5,
          search
        ])
        : createRequest(services.infra.GET_BY_ORGANIZATION, [
          activeOrgId,
          0,
          5,
          search
        ]);

    run()
      .then((response) => {
        setInfra([...response.data]);
      })
      .catch((err) => {
        setError((new CatchedWebError(err)).message);
      })
      .finally(() => {
        setLoading(false);
      })
  }, [activeOrgId, search])

  const handleChange = (infraId) => {
    if (!filter?.infra) {
      setFilter((prevState) => {
        return { ...prevState, infra: [infraId] }
      })
    }
    else if (props.filter.infra?.includes(infraId)) {
      setFilter((prevState) => {
        return { ...prevState, infra: prevState.infra.filter((it) => it !== infraId) }
      })
    }
    else {
      setFilter((prevState) => {
        return { ...prevState, infra: [...prevState.infra, infraId] }
      })
    }
  }
  if (loading) {
    return (<div className="text-center">
      <Spinner size="sm" color="primary" />
    </div>)
  }

  return (
    <div className="mb-1">
      <div className='d-flex align-items-center' onClick={() => { setExpanded(prevState => !prevState) }}>
        <span className='material-symbols-outlined cursor-pointer'>
          {expanded ? "expand_more" : "chevron_right"}
        </span>
        <span className='oneFilter'>Infrastructure</span>
      </div>
      {expanded && <div className="optionsDiv d-flex flex-column justify-content-between rounded">
        <div className="filter-search-box">
          <InputGroup className="input-group-merge">
            <Input type="text" placeholder="Search" onChange={
              (e) => {
                debounce(() => setSearch(e.target.value));
              }
            } />
            <SearchIconAddon />
          </InputGroup>
        </div>
        {
          infra.map((infraItem) =>
            <div className='d-flex align-items-center'>
              <input className="cursor-pointer mr-1" type="checkbox" checked={props?.filter?.infra?.includes(infraItem.infraItemId)} onClick={() => handleChange(infraItem.infraItemId)} />
              <span className="optionName">{infraItem.infraDisplayName}</span>
            </div>
          )
        }
        {infra.length > 4 && <div className="ml-2">...more</div>}
      </div>}

    </div>);
}

const ConnectedFilter = (props) => {
  const [expanded, setExpanded] = useState(true);
  const opns = [
    { value: "ONLINE", label: "Online" },
    { value: "OFFLINE", label: "Offline" },
    { value: "PENDING", label: "Pending" },
    { value: "DEMO", label: THIRD_PARTY_TITLE },
  ]

  const handleChange = (status) => {
    if (!props.filter?.status) {
      props.setFilter((prevState) => {
        return { ...prevState, status: [status] }
      })
    }
    else if (props.filter.status?.includes(status)) {
      props.setFilter((prevState) => {
        return { ...prevState, status: prevState.status.filter((it) => it !== status) }
      })
    }
    else {
      props.setFilter((prevState) => {
        return { ...prevState, status: [...prevState.status, status] }
      })
    }
  }

  return (
    <div className="mb-1">
      <div className='d-flex align-items-center' onClick={() => { setExpanded(prevState => !prevState) }}>
        <span className='material-symbols-outlined cursor-pointer'>
          {expanded ? "expand_more" : "chevron_right"}
        </span>
        <span className='oneFilter'>Status</span>
      </div>
      {expanded && <div className="optionsDiv d-flex flex-column justify-content-between rounded">
        {
          opns.map((opn) =>
            <div className='d-flex align-items-center'>
              <input className="cursor-pointer mr-1" type="checkbox" checked={props?.filter?.status?.includes(opn.value)} onChange={() => handleChange(opn.value)} />
              <span className="optionName">{opn.label}</span>
            </div>
          )
        }
      </div>}

    </div>);
}

const LocationFilter = (props) => {
  const [expanded, setExpanded] = useState(true);
  const [search, setSearch] = useState("")
  const [opns, setOpns] = useState([])
  const [visibleOptions, setVisibleOptions] = useState(opns.slice(0, 5))

  let timer;

  const debounce = (callback) => {
    clearTimeout(timer);
    timer = setTimeout(callback, 1000);
  }

  useEffect(() => {
    if (props?.filter?.location == null || props.filter.location.length == 0) {
      setOpns(COUNTRIES.map(country => {
        return { value: country.iso2, label: country.iso2 + ` (${country.country})` }
      }))
    }
    else {
      setOpns(prevState => {
        let newOpns = COUNTRIES.map(country => {
          return { value: country.iso2, label: country.iso2 + ` (${country.country})` }
        });

        let selectedOpns = newOpns.filter(it => props.filter.location.includes(it.value))
        newOpns = newOpns.filter(it => !props.filter.location.includes(it.value));

        return [...selectedOpns, ...newOpns]
      })
    }
  }, [props.filter?.location])

  useEffect(() => {
    if (search == "") {
      setVisibleOptions(opns.slice(0, 5))
    }
    else {
      let visOptions = []
      let searchLowerCase = search.toLowerCase()
      for (const country of COUNTRIES) {
        if (country.country.toLowerCase().includes(searchLowerCase) || country.iso2.toLowerCase().includes(searchLowerCase) || country.iso3.toLowerCase().includes(searchLowerCase)) {
          visOptions.push({ value: country.iso2, label: country.iso2 + ` (${country.country})` })
        }
        if (visOptions.length >= 5)
          break
      }
      // for(const opn of opns) {
      //   if(opn.value.toLowerCase().includes(search.toLowerCase()))
      //     visOptions.push(opn)
      //   if(visOptions.length >= 5)
      //     break
      // }
      setVisibleOptions(visOptions)
    }
  }, [search, opns])


  const handleChange = (location) => {
    if (!props.filter?.location) {
      props.setFilter((prevState) => {
        return { ...prevState, location: [location] }
      })
    }
    else if (props.filter.location?.includes(location)) {
      props.setFilter((prevState) => {
        return { ...prevState, location: prevState.location.filter((it) => it !== location) }
      })
    }
    else {
      props.setFilter((prevState) => {
        return { ...prevState, location: [...prevState.location, location] }
      })
    }
  }

  return (
    <div className="mb-1">
      <div className='d-flex align-items-center' onClick={() => { setExpanded(prevState => !prevState) }}>
        <span className='material-symbols-outlined cursor-pointer'>
          {expanded ? "expand_more" : "chevron_right"}
        </span>
        <span className='oneFilter'>Locations</span>
      </div>
      {expanded && <div className="optionsDiv d-flex flex-column justify-content-between rounded">
        <div className="filter-search-box">
          <InputGroup className="input-group-merge">
            <Input type="text" placeholder="Search" onChange={
              (e) => {
                debounce(() => setSearch(e.target.value));
              }
            } />
            <SearchIconAddon />
          </InputGroup>
        </div>
        {
          visibleOptions.map((opn) =>
            <div className='d-flex align-items-center'>
              <input className="cursor-pointer mr-1" type="checkbox" checked={props?.filter?.location?.includes(opn.value)} onChange={() => handleChange(opn.value)} />
              <span className="optionName d-flex align-items-center" >
                <div className="filter-flag-icon mr-50" style={{ backgroundImage: `url( https://flagcdn.com/${opn.value.toLowerCase()}.svg )` }}>&nbsp;</div>
                {opn.label}
              </span>
            </div>
          )
        }
        {(visibleOptions.length > 4 && expanded) ? <div className="ml-2">...more</div> : null}
      </div>}
    </div>);
}
const OrgStateFilter = (props) => {
  const [expanded, setExpanded] = useState(true);
  const opns = props.orgType == BUSINESS ? BUS_STATE : MSP_STATE

  const handleChange = (state) => {
    if (!props.filter?.state) {
      props.setFilter((prevState) => {
        return { ...prevState, state: [state] }
      })
    }
    else if (props.filter.state?.includes(state)) {
      props.setFilter((prevState) => {
        return { ...prevState, state: prevState.state.filter((it) => it !== state) }
      })
    }
    else {
      props.setFilter((prevState) => {
        return { ...prevState, state: [...prevState.state, state] }
      })
    }
  }

  return (
    <div className="mb-1">
      <div className='d-flex align-items-center' onClick={() => { setExpanded(prevState => !prevState) }}>
        <span className='material-symbols-outlined cursor-pointer'>
          {expanded ? "expand_more" : "chevron_right"}
        </span>
        <span className='oneFilter'>States</span>
      </div>
      {expanded && <div className="optionsDiv d-flex flex-column justify-content-between rounded">
        {
          opns.map((opn, key) =>
            <div className='d-flex align-items-center' key={key}>
              <input className="cursor-pointer mr-1" type="checkbox" checked={props?.filter?.state?.includes(opn.value)} onChange={() => handleChange(opn.value)} />
              <span className="optionName">{opn.label}</span>
            </div>
          )
        }
      </div>}

    </div>);
}
const VenueStateFilter = (props) => {
  const [expanded, setExpanded] = useState(true);
  const opns = VENUE_STATE_MAP
  // const opns = Object.keys(VENUE_STATUS).map(key => { return { value: key, label: VENUE_STATUS[key] } }
  // )

  const handleChange = (state) => {
    if (!props.filter?.state) {
      props.setFilter((prevState) => {
        return { ...prevState, state: [state] }
      })
    }
    else if (props.filter.state?.includes(state)) {
      props.setFilter((prevState) => {
        return { ...prevState, state: prevState.state.filter((it) => it !== state) }
      })
    }
    else {
      props.setFilter((prevState) => {
        return { ...prevState, state: [...prevState.state, state] }
      })
    }
  }

  return (
    <div className="mb-1">
      <div className='d-flex align-items-center' onClick={() => { setExpanded(prevState => !prevState) }}>
        <span className='material-symbols-outlined cursor-pointer'>
          {expanded ? "expand_more" : "chevron_right"}
        </span>
        <span className='oneFilter'>States</span>
      </div>
      {expanded && <div className="optionsDiv d-flex flex-column justify-content-between rounded">
        {
          opns.map((opn, key) =>
            <div className='d-flex align-items-center' key={key}>
              <input className="cursor-pointer mr-1" type="checkbox" checked={props?.filter?.state?.includes(opn.value)} onChange={() => handleChange(opn.value)} />
              <span className="optionName">{opn.label}</span>
            </div>
          )
        }
      </div>}

    </div>);
}
const OperationsFilter = (props) => {
  const [expanded, setExpanded] = useState(true);
  const opns = [
    { value: "ticket", label: "Ticket" },
    { value: "alarm", label: "Alarm" },
    { value: "firmware", label: "Firmware" }
  ]

  const handleChange = (operation) => {
    if (!props.filter?.operations) {
      props.setFilter((prevState) => {
        return { ...prevState, operations: [operation] }
      })
    }
    else if (props.filter.operations?.includes(operation)) {
      props.setFilter((prevState) => {
        return { ...prevState, operations: prevState.operations.filter((it) => it !== operation) }
      })
    }
    else {
      props.setFilter((prevState) => {
        return { ...prevState, operations: [...prevState.operations, operation] }
      })
    }
  }

  return (
    <div className="mb-1">
      <div className='d-flex align-items-center' onClick={() => { setExpanded(prevState => !prevState) }}>
        <span className='material-symbols-outlined cursor-pointer'>
          {expanded ? "expand_more" : "chevron_right"}
        </span>
        <span className='oneFilter'>Operations</span>
      </div>
      {expanded && <div className="optionsDiv d-flex flex-column justify-content-between rounded">
        {
          opns.map((opn, key) =>
            <div className='d-flex align-items-center' key={key}>
              <input className="cursor-pointer mr-1" type="checkbox" checked={props?.filter?.operations?.includes(opn.value)} onChange={() => handleChange(opn.value)} />
              <span className="optionName">{opn.label}</span>
            </div>
          )
        }
      </div>}

    </div>);
}

const NotificationTypeFilter = (props) => {
  const [expanded, setExpanded] = useState(true);
  const opns = [
    { value: "ticket", label: "Ticket" },
    { value: "notification", label: "Notification" },
    { value: "alarm", label: "Alarm" },
  ]

  const handleChange = (type) => {
    if (!props.filter?.alertType) {
      props.setFilter((prevState) => {
        return { ...prevState, alertType: [type] }
      })
    }
    else if (props.filter.alertType?.includes(type)) {
      props.setFilter((prevState) => {
        return { ...prevState, alertType: prevState.alertType.filter((it) => it !== type) }
      })
    }
    else {
      props.setFilter((prevState) => {
        return { ...prevState, alertType: [...prevState.alertType, type] }
      })
    }
  }

  return (
    <div className="mb-1">
      <div className='d-flex align-items-center' onClick={() => { setExpanded(prevState => !prevState) }}>
        <span className='material-symbols-outlined cursor-pointer'>
          {expanded ? "expand_more" : "chevron_right"}
        </span>
        <span className='oneFilter'>Notification Type</span>
      </div>
      {expanded && <div className="optionsDiv d-flex flex-column justify-content-between rounded">
        {
          opns.map((opn) =>
            <div className='d-flex align-items-center'>
              <input className="cursor-pointer mr-1" type="checkbox" checked={props?.filter?.alertType?.includes(opn.value)} onChange={() => handleChange(opn.value)} />
              <span className="optionName">{opn.label}</span>
            </div>
          )
        }
      </div>}

    </div>);
}

const NotificationImpactFilter = (props) => {
  const [expanded, setExpanded] = useState(true);
  const opns = [
    { value: ALERT_HIG, label: "Critical" },
    { value: ALERT_MED, label: "Major" },
    { value: ALERT_LOW, label: "Minor" },
  ]

  const handleChange = (impact) => {
    if (!props.filter?.alertImpact) {
      props.setFilter((prevState) => {
        return { ...prevState, alertImpact: [impact] }
      })
    }
    else if (props.filter.alertImpact?.includes(impact)) {
      props.setFilter((prevState) => {
        return { ...prevState, alertImpact: prevState.alertImpact.filter((it) => it !== impact) }
      })
    }
    else {
      props.setFilter((prevState) => {
        return { ...prevState, alertImpact: [...prevState.alertImpact, impact] }
      })
    }
  }

  return (
    <div className="mb-1">
      <div className='d-flex align-items-center' onClick={() => { setExpanded(prevState => !prevState) }}>
        <span className='material-symbols-outlined cursor-pointer'>
          {expanded ? "expand_more" : "chevron_right"}
        </span>
        <span className='oneFilter'>Notification Severity</span>
      </div>
      {expanded && <div className="optionsDiv d-flex flex-column justify-content-between rounded">
        {
          opns.map((opn) =>
            <div className='d-flex align-items-center'>
              <input className="cursor-pointer mr-1" type="checkbox" checked={props?.filter?.alertImpact?.includes(opn.value)} onChange={() => handleChange(opn.value)} />
              <span className="optionName">{opn.label}</span>
            </div>
          )
        }
      </div>}

    </div>);
}

const QuoteStatusFilter = (props) => {
  const [expanded, setExpanded] = useState(true);
  const opns = [
    { value: "open", label: "In Progress" },
    { value: "accepted", label: "Accepted" },
    { value: "declined", label: "Closed" },
    { value: "closed", label: "Expired" },
    { value: "invoiced", label: "Ordered" },
  ]

  const handleChange = (status) => {
    if (!props.filter?.statuses) {
      props.setFilter((prevState) => {
        return { ...prevState, statuses: [status] }
      })
    }
    else if (props.filter.statuses?.includes(status)) {
      props.setFilter((prevState) => {
        return { ...prevState, statuses: prevState.statuses.filter((it) => it !== status) }
      })
    }
    else {
      props.setFilter((prevState) => {
        return { ...prevState, statuses: [...prevState.statuses, status] }
      })
    }
  }

  return (
    <div className="mb-1">
      <div className='d-flex align-items-center' onClick={() => { setExpanded(prevState => !prevState) }}>
        <span className='material-symbols-outlined cursor-pointer'>
          {expanded ? "expand_more" : "chevron_right"}
        </span>
        <span className='oneFilter'>Status</span>
      </div>
      {expanded && <div className="optionsDiv d-flex flex-column justify-content-between rounded">
        {
          opns.map((opn) =>
            <div className='d-flex align-items-center'>
              <input className="cursor-pointer mr-1" type="checkbox" checked={props?.filter?.statuses?.includes(opn.value)} onChange={() => handleChange(opn.value)} />
              <span className="optionName">{opn.label}</span>
            </div>
          )
        }
      </div>}

    </div>);
}


export {
  ProfileTypeFilter, FWFilter, VenueStateFilter, OrgStateFilter, LocationFilter, OperationsFilter, NotificationImpactFilter, NotificationTypeFilter,
  ConnectedFilter, TimeFilter, CategoryFilter, UpdateStatusFilter, ReleaseFilter, InfraTypeFilter, OrgFilter, VenueFilter, FilterEnable, FilterRoles, NetworkTypeFilter,
  BandFilter, OrgsStatusFilter, OrgTypeFilter, EventFilter, PriorityFilter, SeverityFilter, StatusFilter, NetworkFilter, InfraFilter, QuoteStatusFilter, IdentityStatusFilter,
  ConnectionTypeFilter, AggregateVenueFilter, CumulativeInfrasFilter, FailureReasonFilter, InstalledFilter
}; 
