import PropTypes from "prop-types";
import "./SnappingEditor.scss";
import AvailableLayouts from "./AvailableLayouts";
import { motion } from "framer-motion";
import SnappingCanvas from "./SnappingCanvas";
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { CANVAS_FEATURES, EDITORS } from "../../constants";
import Canvas from "../../Canvas";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import SingleInfraSelector from "../../../../../components/SingleInfraSelector";

/**
 * Snapping Editor
 * @param {{
 *   layoutManager: object,
 *   layout: {
*     "venueId": number,
*     "name": string,
*     "layoutType": 1 | 2 | 3,
*     "layoutFile": string,
*     "infraPositions": Array<{
*       "infra_type_id": number,
*       "x": number,
*       "y": number
*     }>?,
*     "layoutJson": {
*       "dimensions": {
*         "length": number,
*         "width": number,
*         "height": number
*         "area": number
*       }?,
*       "walls": Array<{
*         "id": string,
*         "loc": Array<number>,
*         "material": string
*       }>,
*       "isActive": boolean,
*       "id": number,
*       "createdAt": string,
*       "updatedAt": string
*     }
*   }
 * }} props 
 * @returns 
 */
const SnappingEditor = (props) => {
  const [features, setFeatures] = useState([]);

  const onPlace = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => { console.log("Snapping--->", props); resolve() }, 5000);
    })
  }

  useEffect(() => {
    if (props.mode == EDITORS.SnappingEditor) {
      setFeatures([CANVAS_FEATURES.ZOOM_WIDGETS, CANVAS_FEATURES.LAYOUT_MANIPULATION_WIDGETS])
    }
    else if (props.mode == EDITORS.AccessPointsEditor) {
      setFeatures([CANVAS_FEATURES.AP_VIEW_AND_WIDGETS, CANVAS_FEATURES.ZOOM_WIDGETS, CANVAS_FEATURES.SHOW_LINES, CANVAS_FEATURES.SHOW_FOOTER, CANVAS_FEATURES.SHOW_CABLE_DROP])
    }
    // else if (props.mode == EDITORS.AccessPointsEditor) {
    //   setFeatures([CANVAS_FEATURES.AP_VIEW_AND_WIDGETS, CANVAS_FEATURES.SWITCH, CANVAS_FEATURES.ZOOM_WIDGETS, CANVAS_FEATURES.SHOW_LINES, CANVAS_FEATURES.SHOW_FOOTER, CANVAS_FEATURES.SHOW_CABLE_DROP])
    // }
    // else if (props.mode == EDITORS.SwitchEditor) {
    //   setFeatures([CANVAS_FEATURES.SWITCH, CANVAS_FEATURES.AP_VIEW_AND_WIDGETS, CANVAS_FEATURES.ZOOM_WIDGETS, CANVAS_FEATURES.SHOW_LINES, CANVAS_FEATURES.SHOW_FOOTER, CANVAS_FEATURES.SHOW_CABLE_DROP])
    // }
    else if (props.mode == EDITORS.SwitchEditor) {
      setFeatures([CANVAS_FEATURES.SWITCH, CANVAS_FEATURES.ZOOM_WIDGETS, CANVAS_FEATURES.SHOW_LINES, CANVAS_FEATURES.SHOW_FOOTER, CANVAS_FEATURES.SHOW_CABLE_DROP])
    }
    else if (props.mode == EDITORS.WallAnnotationsEditor) {
      setFeatures([CANVAS_FEATURES.ZOOM_WIDGETS, CANVAS_FEATURES.WALL_COLORS])
    }
    else if (props.mode == EDITORS.DrawLines) {
      setFeatures([CANVAS_FEATURES.DRAW_LINES, CANVAS_FEATURES.SHOW_LINES])
    }
    else if (props.mode == EDITORS.CableDrop) {
      setFeatures([CANVAS_FEATURES.ZOOM_WIDGETS, CANVAS_FEATURES.MOVE_CABLE_DROP, CANVAS_FEATURES.SHOW_CABLE_DROP, CANVAS_FEATURES.SHOW_AP, CANVAS_FEATURES.SHOW_FOOTER])
    }
    const { resetSelectedEntities } = props.layoutManager
    resetSelectedEntities()
  }, [props.mode])
  return (
    <div className="SnappingEditor">
      <motion.div
        className="map-area"
        initial={{ top: 10, opacity: 0 }}
        animate={{ top: 0, opacity: 1 }}
        transition={{ duration: 0.3, delay: 0.3 }}
      >
        <Canvas
          layoutManager={props.layoutManager}
          draggedLayoutItem={props.draggedLayoutRef}
          draggedApItem={props.draggedApRef}
          draggedSwitchItem={props.draggedSwitchRef}
          draggedCableDrop={props.draggedCableDropRef}
          features={features}
          events={[]}
          layouts={[props.layout] || []}
          onSave={() => { }}
          mode={props.mode}
          saveFloorplan={props.saveFloorplan}
        />
      </motion.div>

    </div>
  );
};

SnappingEditor.propTypes = {
  layoutManager: PropTypes.object,
  layout: PropTypes.object
};
SnappingEditor.defaultProps = {};

export default SnappingEditor;